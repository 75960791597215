import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, map, tap } from 'rxjs';
import { APIConstants } from '../constants/api-constants';
import { AppConstants } from '../constants/app-constants';
import { LocalStorageConstants } from '../constants/local-storage.constant';
import { AppNotification } from '../models/app-notification';
import { AQIIndex } from '../models/aqi-index/aqi-index';
import { DeviceType } from '../models/device-type/device-type';
import { LoginData } from '../models/login-data';
import { RequestData } from '../models/new-user-data';
import { Notifications } from '../models/notifications/notification';
import { Unit } from '../models/units/unit';
import { UpdatePasswordData } from '../models/update-password-data';
import { CommonUtil } from '../utils/common-utils';
import { BaseAPIService } from './base-service';
import { CommonService } from './common.service';
import { CookieService } from './cookie.service';
import { CustomMomentService } from './custom-moment.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseAPIService<AppNotification> {
  user!: LoginData;
  fetchNotification: any;

  private selectedDeviceType = new BehaviorSubject<number>(0);
  selectedDeviceType$ = this.selectedDeviceType.asObservable();

  private selectedDeviceTypeForUnits = new BehaviorSubject<number>(
    this.commonService.getUserDeviceTypes()?.[0]?.deviceTypeId
  );
  selectedDeviceTypeForUnits$ = this.selectedDeviceTypeForUnits.asObservable();

  private selectedAQI = new BehaviorSubject<AQIIndex | undefined>(
    this.getCurrentAQI()
  );
  selectedAQI$ = this.selectedAQI.asObservable();

  private refreshData = new BehaviorSubject<boolean>(false);
  refreshData$ = this.refreshData.asObservable();

  private unitsForAllParameters = new BehaviorSubject<any | undefined>(
    undefined
  );
  unitsForAllParameters$ = this.unitsForAllParameters.asObservable();

  private isUnitOrLimitUpdated = new BehaviorSubject<boolean>(false);
  isUnitOrLimitUpdated$ = this.isUnitOrLimitUpdated.asObservable();

  public logoUpdated = new BehaviorSubject<boolean>(false);
  logoUpdated$ = this.logoUpdated.asObservable();

  public userProfileUpdate = new Subject<boolean>();
  userProfileUpdate$ = this.userProfileUpdate.asObservable();

  public newNotificationSubject = new Subject<boolean>();

  public alertNotificationSnapshot = new Subject<any>();
  alertNotificationSnapshot$ = this.alertNotificationSnapshot.asObservable();

  constructor(
    http: HttpClient,
    cookieService: CookieService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private commonService: CommonService,
    private customMomentService: CustomMomentService
  ) {
    super(http, cookieService);
    this.newNotificationSubject.subscribe((newNotification: any) => {
      this.receivedNewNotification(newNotification);
    });
  }

  setupUserDetails(): void {
    this.user = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    );
  }

  getUserNotifications(): Observable<Array<AppNotification>> {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );
    let params: HttpParams = new HttpParams();
    const defaultStartDate = this.customMomentService
      ?.moment()
      ?.subtract(1, 'day')
      ?.startOf('day')
      .unix();
    params = params.append('gte', defaultStartDate);
    const defaultEndDate = this.customMomentService
      ?.moment()
      ?.endOf('day')
      .unix();
    params = params.append('lte', defaultEndDate);
    // });
    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);
    return this.get<Array<AppNotification>>(
      APIConstants.USER_NOTIFICATIONS.replace('{userId}', userId),
      headers,
      params
    ).pipe(
      map((res) => {
        return res;
      }),
      tap((data) => {
        this.fetchNotification = data;
      })
    );
  }

  fetchNotificationData(): void {
    if (this.fetchNotification) {
      return this.fetchNotification;
    }
  }

  receivedNewNotification(newNotification: any) {
    this.fetchNotification.unshift(newNotification);

    this.alertNotificationSnapshot.next(newNotification);
  }

  getLocaluser() {
    return this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    );
  }

  onChangeDeviceOrAQI(
    typeId: number | AQIIndex,
    isDeviceTypeId: boolean = true
  ) {
    if (isDeviceTypeId && typeof typeId === 'number') {
      this.selectedDeviceType.next(typeId);
      this.selectedAQI.next(this.getCurrentAQI(String(typeId)));
    } else if (typeof typeId === 'object') {
      this.selectedAQI.next(typeId);
    }
  }

  refreshUserData(value: boolean) {
    this.refreshData.next(value);
  }

  //to update the password when user clicks on Change Password button in User Module under Profile section
  updatePassword(payload: UpdatePasswordData) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.post<UpdatePasswordData>(
      APIConstants.UPDATE_PASSWORD,
      payload,
      headers
    );
  }

  //to update the profile info of user when user clicks on Edit button in User Module under Profile section
  updateProfile(payload: RequestData.UpdateUser) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.patch<RequestData.UpdateUser>(
      APIConstants.UPDATE_PROFILE(userId),
      payload,
      headers
    );
  }

  //to get list of subusers of organization
  getSubUsers() {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );
    const userId = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    ).userId;
    const orgId = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    ).org;

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    const params: HttpParams = new HttpParams().appendAll({
      need_moduleExpiry: true,
      need_devices: true,
    });

    return this.get<LoginData[]>(
      APIConstants.GET_SUB_USERS(userId, orgId),
      headers,
      params
    );
  }

  addSubUser(payload: RequestData.NewUser) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.post<string>(
      APIConstants.ADD_SUB_USER.replace('{userId}', userId),
      payload,
      headers
    );
  }

  updateSubUser(payload: RequestData.UpdateUser, targetUserId: number) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );
    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.patch<string>(
      APIConstants.UPDATE_SUB_USER_INFO(userId, targetUserId),
      payload,
      headers
    );
  }

  getSubUserDevices(userId: number) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );
    const orgId = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    ).org;

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.get<any>(APIConstants.GET_SUB_USER_DEVICES(userId), headers);
  }

  getCurrentAQI(deviceTypeId: string = '1001'): AQIIndex | undefined {
    return this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    )?.aqiIndex?.[deviceTypeId]
      ? this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
          ?.aqiIndex?.[deviceTypeId]
      : undefined;
  }

  updateAQI(payload: RequestData.UpdateUser) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.patch<RequestData.UpdateUser>(
      APIConstants.UPDATE_AQI(userId),
      payload,
      headers
    );
  }

  getUnitsForAllParameters(payload: Unit.Post, deviceTypes: DeviceType[]) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.post<Unit.Get>(
      APIConstants.GET_ALL_UNITS,
      payload,
      headers
    ).subscribe((res) => {
      const modifiedRes: Record<number, any> = {};
      deviceTypes.forEach((deviceType: DeviceType) => {
        modifiedRes[deviceType.deviceTypeId] = [];
        let temp: any = [];
        res.forEach((singleRes: Unit.Data) => {
          if (deviceType.deviceTypeId === singleRes.deviceTypeId) {
            temp.push(singleRes);
          }
        });
        modifiedRes[deviceType.deviceTypeId] = temp;
      });
      this.unitsForAllParameters.next(modifiedRes);
    });
  }

  onChangeDeviceTypeForUnits(deviceTypeId: number) {
    this.selectedDeviceTypeForUnits.next(deviceTypeId);
  }

  deleteSubUser(targetUserId: number) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);
    return this.delete(
      APIConstants.DELETE_SUB_USER.replace('{userId}', userId).replace(
        '{targetUserId}',
        String(targetUserId)
      ),
      headers
    );
  }

  getForgotPasswordToken(payload: any) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);
    return this.post<any>(APIConstants.SEND_EMAIL, payload, headers);
  }

  //to update units or limits of user from user module in units management
  updateUnitsAndLimits(payload: any) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.patch<any>(
      APIConstants.UPDATE_UNITS_LIMITS.replace('{userId}', userId).replace(
        '{targetUserId}',
        userId
      ),
      payload,
      headers
    );
  }

  afterUpdateUnitOrLimit() {
    this.isUnitOrLimitUpdated.next(true);
  }

  resetUnits(payload: RequestData.UpdateUser) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.patch<any>(
      APIConstants.UPDATE_UNITS_LIMITS.replace('{userId}', userId).replace(
        '{targetUserId}',
        userId
      ),
      payload,
      headers
    );
  }

  getUserProfile() {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.get<any>(
      APIConstants.UPDATE_USER_PROFILE.replace('{userId}', userId),
      headers
    ).pipe(
      map((res) => {
        let user = this.localStorageService.getParsedValue(
          LocalStorageConstants.OZ_USER
        );
        for (const key in res) {
          user[key] = res[key];
        }
        this.localStorageService.saveValue(
          LocalStorageConstants.OZ_USER,
          JSON.stringify(user)
        );

        //update users latest timezone in custom moment service
        this.customMomentService.timezone =
          res?.settings?.timezone?.name ?? CommonUtil.getDefaultTimezone();
        this.afterUpdateUnitOrLimit();

        //to notify when user profile is updated
        this.userProfileUpdate.next(true);
      })
    );
  }

  updateNotificationLastSeen(payload: Notifications.Put) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    const params = new HttpParams().append('userId', userId);

    return this.put<string>(
      APIConstants.NOTIFICATION_LAST_SEEN(userId),
      payload,
      headers,
      params
    );
  }

  getNotificationLastSeen(topics: string[]) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    const params = new HttpParams().appendAll({
      userId: userId,
      topics: topics,
    });

    return this.get<Notifications.Get>(
      APIConstants.NOTIFICATION_LAST_SEEN(userId),
      headers,
      params
    ).pipe(
      map((topics) => {
        let updatedTime: Record<string, number> = {};
        topics.forEach((res, index) => {
          let key = res.topic;
          if (!updatedTime[key]) {
            updatedTime[key] = topics[index].ts;
          }
        });

        let currentLastSeen = this.localStorageService.getParsedValue(
          LocalStorageConstants.OZ_ALL_LAST_SEEN
        );

        let updatedLastSeen = { ...currentLastSeen, ...updatedTime };

        this.localStorageService.saveValue(
          LocalStorageConstants.OZ_ALL_LAST_SEEN,
          JSON.stringify(updatedLastSeen)
        );
        return topics;
      })
    );
  }
}
