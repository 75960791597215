import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { DeviceField } from 'src/app/shared/models/device/device-field';

import * as Highcharts from 'highcharts';
import { debounceTime, filter, Subscription } from 'rxjs';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { ContentUnavailable } from 'src/app/shared/models/internal-use-front-end/content-unavailable';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { WidgetService } from '../../services/widget.service';
import { WidgetInfo } from '../../widget.component.interface';

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/offline-exporting')(Highcharts);

@Component({
  selector: 'app-noise-level-widget',
  templateUrl: './noise-level-widget.component.html',
  styleUrls: ['./noise-level-widget.component.scss'],
})
export class NoiseLevelWidgetComponent implements OnInit, OnChanges {
  private _widgetInfo!: WidgetInfo;
  public get widgetInfo(): WidgetInfo {
    return this._widgetInfo;
  }
  @Input() public set widgetInfo(v: WidgetInfo) {
    this.show = v.show;
    this._widgetInfo = v;

    this._cdr.detectChanges();

    this.setupConfig();
  }

  private _widgetData: any;
  public get widgetData(): any {
    return this._widgetData;
  }
  @Input() public set widgetData(v: any) {
    this.deviceInfo = { ...v.deviceInfo };
    this.deviceData = [...v.deviceData];
    this.show = Boolean(v.show);
    this._widgetData = v;

    this._cdr.detectChanges();

    this.setupConfig();
  }

  deviceInfo: DeviceDetails | undefined;

  private _deviceData: DeviceDetails[] = [];
  public get deviceData(): DeviceDetails[] {
    return this._deviceData;
  }
  public set deviceData(v: DeviceDetails[]) {
    this._deviceData = v;
    if (v) {
      this.setupConfig();
    }
  }

  public show: boolean = false;

  private _noNoiseData = true;
  @Output() graphDataEmpty: EventEmitter<Record<string, any>> =
    new EventEmitter<Record<string, any>>();

  get noNoiseData(): boolean {
    return this._noNoiseData;
  }
  set noNoiseData(value: boolean) {
    this.graphDataEmpty.emit({
      widgetId: this.widgetInfo.widgetId,
      disableStatus: value,
    });
    this._noNoiseData = value;
  }

  highcharts: typeof Highcharts = Highcharts;
  defaultColor: string = '#00c4b4';
  deviceFields: DeviceField[] = [];

  chartOptions: Highcharts.Options = {};

  public noData: ContentUnavailable = {
    majorText: 'No Data Found',
    svgImage: AppConstants.QUERIED_DATA_NOT_FOUND,
  };

  userTimeFormat: number =
    this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
      ?.settings?.time_format ?? 24;

  subscriptions: Subscription[] = [];

  constructor(
    private customMomentService: CustomMomentService,
    private localStorageService: LocalStorageService,
    private widgetService: WidgetService,
    private _cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.deviceInfo = this.widgetService.device;
    this.deviceData = this.widgetService.liveDeviceData;

    this.subscriptions.push(
      this.widgetService.widgetDataUpdated$.subscribe({
        next: (res) => {
          switch (res) {
            case 'device': {
              this.deviceInfo = this.widgetService.device;
              break;
            }
            case 'liveDeviceData': {
              this.deviceData = this.widgetService.liveDeviceData;
              break;
            }
            default: {
              // console.info('noise-level-widget -> key for changed value:', res);
            }
          }
          setTimeout(() => {
            this._cdr.detectChanges();
          });
        },
      })
    );

    this.subscriptions.push(
      this.widgetService.redrawWidget$
        .pipe(
          filter((widgetId: number) => this.widgetInfo.widgetId === widgetId),
          debounceTime(300)
        )
        .subscribe({
          next: (widgetId: number) => {
            if (this.widgetInfo.widgetId === widgetId) {
              this.show = false;
              setTimeout(() => {
                this.show = true;
              });
            }
          },
        })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setupConfig();
  }

  setupConfig() {
    if (this.deviceData.length) {
      this.setupDataPoints();
    }
  }

  setupDataPoints() {
    this.show = false;

    this.userTimeFormat =
      this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
        ?.settings?.time_format ?? 24;
    const that = this;

    let xAxisOptions: Highcharts.XAxisOptions = {
      type: 'datetime',
      labels: {
        formatter: function () {
          return that.customMomentService.formatTime({
            epochMS: parseInt('' + this.value),
            format: that.userTimeFormat === 12 ? 'hh:mm A' : 'HH:mm',
          });
        },
      },
    };
    let yAxisOptions: Highcharts.AxisOptions = {
      title: { text: null },
    };

    this.chartOptions.title = null!;
    this.chartOptions.subtitle = null!;
    this.chartOptions.credits = { enabled: false };
    this.chartOptions.legend = { enabled: false };

    this.chartOptions.exporting = {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
      filename: `${
        this.deviceInfo?.label ? `${this.deviceInfo?.label}_` : ''
      }noise_level_data`,
      chartOptions: {
        title: {
          text: `${
            this.deviceInfo?.label ? `${this.deviceInfo?.label} device's ` : ''
          }Noise level Data`,
        },
        legend: {
          enabled: true,
        } as Highcharts.LegendOptions,
        xAxis: {
          title: {
            text: 'Time',
          },
        },
      },
    };

    this.chartOptions.xAxis = xAxisOptions;
    this.chartOptions.yAxis = yAxisOptions;
    this.chartOptions.chart = { type: 'area' };

    this.chartOptions.tooltip = {
      formatter: function () {
        return (
          that.customMomentService.formatDatetime({
            epochMS: parseInt('' + this.x),
            format:
              that.userTimeFormat === 12
                ? 'dddd, MMM DD, hh:mm A'
                : 'dddd, MMM DD, HH:mm',
          }) +
          '</br>' +
          `<span style="color: ${this.point.color}">\u25CF</span>` +
          '<b>' +
          this.series.name +
          '</b>' +
          ' : ' +
          this.y
        );
      },
    };

    this.chartOptions.series = [];
    let dataPointsLmax = [];
    let dataPointsL10 = [];
    let dataPointsLeq = [];
    let dataPointsL90 = [];
    let dataPointsLmin = [];

    for (let data of this.deviceData) {
      if (
        data.payload.d.l10 &&
        data.payload.d.l90 &&
        data.payload.d.leq &&
        data.payload.d.lmax &&
        data.payload.d.lmin
      ) {
        dataPointsL10.push({
          x: +this.customMomentService.moment.unix(data.payload.d.t),
          y: parseFloat(data.payload.d.l10),
        });
        dataPointsL90.push({
          x: +this.customMomentService.moment.unix(data.payload.d.t),
          y: parseFloat(data.payload.d.l90),
        });
        dataPointsLmax.push({
          x: +this.customMomentService.moment.unix(data.payload.d.t),
          y: parseFloat(data.payload.d.lmax),
        });
        dataPointsLeq.push({
          x: +this.customMomentService.moment.unix(data.payload.d.t),
          y: parseFloat(data.payload.d.leq),
        });
        dataPointsLmin.push({
          x: +this.customMomentService.moment.unix(data.payload.d.t),
          y: parseFloat(data.payload.d.lmin),
        });
      } else if (
        data.payload.d.leq &&
        data.payload.d.lmax &&
        data.payload.d.lmin
      ) {
        dataPointsLeq.push({
          x: +this.customMomentService.moment.unix(data.payload.d.t),
          y: parseFloat(data.payload.d.leq),
        });
        dataPointsLmax.push({
          x: +this.customMomentService.moment.unix(data.payload.d.t),
          y: parseFloat(data.payload.d.lmax),
        });
        dataPointsLmin.push({
          x: +this.customMomentService.moment.unix(data.payload.d.t),
          y: parseFloat(data.payload.d.lmin),
        });
      }
    }

    let seriesLmin = {
      name: 'Lmin',
      data: dataPointsLmin,
      color: '#6ecc58',
      marker: {
        symbol: 'circle',
      },
    } as Highcharts.SeriesOptionsType;
    let seriesL90 = {
      name: 'L90',
      data: dataPointsL90,
      color: '#bbcf4c',
      marker: {
        symbol: 'circle',
      },
    } as Highcharts.SeriesOptionsType;
    let seriesLeq = {
      name: 'Leq',
      data: dataPointsLeq,
      color: '#eac736',
      marker: {
        symbol: 'circle',
      },
    } as Highcharts.SeriesOptionsType;
    let seriesL10 = {
      name: 'L10',
      data: dataPointsL10,
      color: '#ed9a2e',
      marker: {
        symbol: 'circle',
      },
    } as Highcharts.SeriesOptionsType;
    let seriesLmax = {
      name: 'Lmax',
      data: dataPointsLmax,
      color: '#E8633A',
      marker: {
        symbol: 'circle',
      },
    } as Highcharts.SeriesOptionsType;

    if (
      this.deviceData.some((d) => d.payload.d.l10) &&
      this.deviceData.some((d) => d.payload.d.l90) &&
      this.deviceData.some((d) => d.payload.d.leq) &&
      this.deviceData.some((d) => d.payload.d.lmax) &&
      this.deviceData.some((d) => d.payload.d.lmin)
    ) {
      this.chartOptions.series.push(
        seriesLmax,
        seriesL10,
        seriesLeq,
        seriesL90,
        seriesLmin
      );
    } else if (
      this.deviceData.some((d) => d.payload.d.leq) &&
      this.deviceData.some((d) => d.payload.d.lmax) &&
      this.deviceData.some((d) => d.payload.d.lmin)
    ) {
      this.chartOptions.series.push(seriesLmax, seriesLeq, seriesLmin);
    }

    if (dataPointsLeq.length >= 2) {
      setTimeout(() => {
        this.noNoiseData = false;
        this.show = true;
        this._cdr.detectChanges();
      });
    } else {
      this.noNoiseData = true;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      if (subscription && !subscription.closed) subscription.unsubscribe();
    });
  }
}
