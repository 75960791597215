import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { NbThemeService } from '@nebular/theme';
import {
  delay,
  filter,
  map,
  Subject,
  Subscription,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { AqiIndexColorArray } from 'src/app/shared/models/aqi-index/aqi-index-color-array';
import { DeviceType } from 'src/app/shared/models/device-type/device-type';
import { DeviceField } from 'src/app/shared/models/device/device-field';
import { FieldLimit } from 'src/app/shared/models/device/field-limit';
import { ContentUnavailable } from 'src/app/shared/models/internal-use-front-end/content-unavailable';
import { Unit } from 'src/app/shared/models/unit';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { GoogleMapsService } from 'src/app/shared/services/google-maps.service';
import { HeatmapService } from 'src/app/shared/services/heatmap.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { DeviceUtil } from 'src/app/shared/utils/device-utils';

import GMap = google.maps.Map;
import MapOptions = google.maps.MapOptions;

const GEOJSON_DATA_LAYER_STYLES = {
  fillColor: 'transparent',
  strokeColor: 'transparent',
  strokeWeight: 0.5,
};

interface HeatmapPlayer {
  isPlaying: boolean;
  currentIndex: number;
  speed: number;
  animationFrameId?: number;
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnDestroy {
  // public googleMap!: GoogleMap;
  // private readonly FRAME_INTERVAL = 400; // Increase to 2 seconds between frames

  private _googleMap!: GoogleMap;
  public get googleMap(): GoogleMap {
    return this._googleMap;
  }
  @ViewChild(GoogleMap) public set googleMap(v: GoogleMap) {
    this._googleMap = v;
    if (this._googleMap) {
      this.resizeObserver.observe(
        (this._googleMap as any)._elementRef.nativeElement
      );
    }
  }

  polygonFitBoundsPadding = { top: 300, bottom: 300, left: 300, right: 300 };

  currentMapType: string = 'roadmap';
  options: MapOptions = AppConstants.GOOGLE_MAPS_OPTIONS;

  heatmapProjectId: string = '';

  loading: number = 0;
  themeChange: number = 0;
  chartLoading: number = 0;

  selectedDateRange: any;
  boundingGeoJSON: any | undefined;
  boundingBox: google.maps.LatLngBounds | undefined;
  heatmapData: any[] | undefined;

  private heatmapOverlayLayer: any | undefined;

  selectedTimeStyles: any = {
    maxWidth: '70px',
    top: '100%',
    left: '0',
    zIndex: 9999999,
    fontSize: '12px',
    lineHeight: '1',
    borderColor: 'var(--border-basic-color-4) !important',
    boxShadow: 'var(--shadow)', //'2px 2px 5px var(--background-basic-color-2)',
    backgroundColor: 'var(--background-basic-color-1)',
    transform: 'translate(-50%, -50%)',
  };
  // private _currentHeatmapDataIndex: number = 0;
  public get currentHeatmapDataIndex(): number {
    return this.player.currentIndex;
  }
  public set currentHeatmapDataIndex(value: number) {
    this.player.currentIndex = value;
    this.updateHeatmap();
  }

  public noData: ContentUnavailable = {
    majorText: 'No Data Found',
    svgImage: AppConstants.QUERIED_DATA_NOT_FOUND,
    // minorText: 'Your device may be offline',
  };

  selectedPolygonLayer: google.maps.Data | undefined;
  selectedTime: string = '';
  shouldShowSelectedTime: boolean = false;
  removeSelectedTime: boolean = false;
  hiddingSelectedTime: NodeJS.Timeout | null = null;
  removingSelectedTime: NodeJS.Timeout | null = null;
  selectedParameter: Unit | undefined;
  parameters: Unit[] = [];

  readonly deviceTypeId: number = 1001;
  deviceData: any;
  device: any = {
    label: 'Heatmap',
  };

  readonly heatmapClickEvent = {
    name: 'click',
    callback: (eve: any) => {
      this.selectedLatLng = {
        lat: eve.latLng.lat(),
        lng: eve.latLng.lng(),
      };
      this.fetchHeatmapPolygon(eve.latLng.lat(), eve.latLng.lng());
    },
  };

  fields: DeviceField[] = [];
  limits: FieldLimit[] = [];

  selectedLatLng: { lat: number; lng: number } | undefined;

  sliderConfig: any = {
    min: 0,
    max: 0,
    disabled: true,
  };

  userTimeformat: number = 24;

  subscriptions: Subscription[] = [];
  mapInitialized: boolean = false;

  aqiIndexColor!: AqiIndexColorArray;

  fullScreenMode: string = 'zoom_out_map';

  width!: number;
  private destroy$: Subject<void> = new Subject<void>();

  private player: HeatmapPlayer = {
    isPlaying: false,
    currentIndex: 0,
    speed: 1,
  };

  private readonly BASE_FRAME_INTERVAL = 500; // Base interval between frames in ms

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    try {
      this.width = (event.target as Window).innerWidth;
    } catch (e) {}
  }

  resizeObserver: ResizeObserver = new ResizeObserver(() => {});
  updatePolygonFitBoundsPadding() {
    let { width: mapWidth, height: mapHeight } = (
      this.googleMap as any
    )?._elementRef?.nativeElement?.getBoundingClientRect?.() ?? {
      width: 10,
      height: 10,
    };

    this.polygonFitBoundsPadding = {
      left: Math.floor(mapWidth * 0.4),
      right: Math.floor(mapWidth * 0.4),
      top: Math.floor(mapHeight * 0.4),
      bottom: Math.floor(mapHeight * 0.4),
    };
  }

  constructor(
    public googleMapsService: GoogleMapsService,
    public customMomentService: CustomMomentService,
    private heatmapService: HeatmapService,
    private commonService: CommonService,
    private themeService: NbThemeService,
    private localStorageService: LocalStorageService,
    private deviceService: DeviceService
  ) {
    this.width = window.innerWidth;
  }

  ngOnInit(): void {
    let userInfo = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    );

    this.heatmapProjectId = userInfo?.info?.heatmap_project;
    if (!this.heatmapProjectId?.length) {
      return;
    }
    this.userTimeformat = userInfo?.settings?.time_format ?? 24;
    const units = this.commonService.getAllUnits();
    this.fields = this.deviceService.fetchFields(
      this.deviceTypeId,
      units,
      false
    );
    this.limits = this.deviceService.fetchLimits(units[this.deviceTypeId]);

    this.googleMapsService.isApiLoaded
      .pipe(
        filter(Boolean),
        delay(1),
        switchMap(() => this.googleMap!.idle),
        take(1),
        tap(() => this.mapReady(this.googleMap?.googleMap as unknown as GMap)),
        switchMap(() => this.googleMap!.zoomChanged)
      )
      .subscribe(() => {});

    this.heatmapService.dateRangeUpdated$.subscribe((res) => {
      this.selectedDateRange = res;
      if (this.heatmapProjectId.length && this.boundingGeoJSON) {
        console.log(
          "fetching heatmap layers please check if this is correct it's called after date range changed"
        );

        this.fetchHeatmapLayers(this.heatmapProjectId, res);
      }
    });
  }

  ngAfterViewInit(): void {
    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => {
        console.log('loading subtracted value before addition', this.loading);
        console.log('changing theme');
        this.loading++;
        this.themeChange++;
        this.chartLoading++;
        setTimeout(() => {
          console.log(
            'loading subtracted value before subtraction',
            this.loading
          );
          console.log('theme changed');
          this.loading--;
          this.themeChange--;
          this.chartLoading--;
        });
        let options = this.options;
        if (themeName == 'material-dark') {
          options.styles = [...AppConstants.DARK_GOOGLE_MAPS_STYLES];
        } else {
          options.styles = [...AppConstants.LIGHT_GOOGLE_MAPS_STYLES];
        }
        this.options = { ...options };

        // setTimeout is used because, fitBounds function should run after the map is re-rendered after the theme change.
        setTimeout(() => {
          if (this.googleMap?.googleMap && this.boundingBox) {
            this.googleMap.googleMap.fitBounds(this.boundingBox);
            this.initializeMapLayers(
              this.googleMap.googleMap,
              this.boundingGeoJSON
            );
          }
        }, 100);
      });

    if (this.heatmapProjectId?.length) {
      // Create a ResizeObserver instance
      this.resizeObserver = new ResizeObserver(() => {
        this.updatePolygonFitBoundsPadding();
      });

      // Observe the element
      if (this.googleMap) {
        this.resizeObserver.observe(
          (this.googleMap as any)._elementRef.nativeElement
        );
      }
    }
  }

  /**
   * @description set controls and update state after the map is ready
   * @param map Map object to set the controls
   * @functionsUsed `initializeData`
   * @variablesUsed `heatmapProjectId`, `boundingBox`, `googleMap`
   * @modifies `mapInitialized`
   */
  public mapReady(map: GMap): void {
    let that = this;
    this.ImageTransitionMapOverlay = class extends google.maps.OverlayView {
      private readonly TRANSITION_DURATION = that.BASE_FRAME_INTERVAL * 0.3; // Duration of transition in ms
      private readonly LAYER_OPACITY = 0.4; // Opacity of the overlay layer

      private image: HTMLImageElement;
      private div: HTMLDivElement | null = null;
      private currentImg: HTMLImageElement | null = null;
      private nextImg: HTMLImageElement | null = null;
      private bounds: google.maps.LatLngBounds;
      private isTransitioning: boolean = false;

      constructor(bounds: google.maps.LatLngBounds, image: HTMLImageElement) {
        super();
        this.bounds = bounds;
        this.image = image;
      }

      override onAdd() {
        this.div = document.createElement('div');
        this.div.style.borderStyle = 'none';
        this.div.style.borderWidth = '0px';
        this.div.style.position = 'absolute';

        // Create and set up the current image
        this.currentImg = document.createElement('img');
        this.currentImg.src = this.image.src;
        this.setupImageStyles(this.currentImg);
        this.currentImg.style.opacity = this.LAYER_OPACITY.toString();

        // Create and set up the next image (initially hidden)
        this.nextImg = document.createElement('img');
        this.setupImageStyles(this.nextImg);
        this.nextImg.style.opacity = '0';

        // Add both images to the container
        this.div.appendChild(this.currentImg);
        this.div.appendChild(this.nextImg);

        const panes = this.getPanes();
        panes?.overlayLayer.appendChild(this.div);
      }

      private setupImageStyles(img: HTMLImageElement) {
        img.style.width = '100%';
        img.style.height = '100%';
        img.style.position = 'absolute';
        img.style.left = '0';
        img.style.top = '0';
        img.style.transition = `opacity ${this.TRANSITION_DURATION}ms`;
      }

      override draw() {
        if (!this.div) return;

        const overlayProjection = this.getProjection();
        const sw = overlayProjection.fromLatLngToDivPixel(
          this.bounds.getSouthWest()
        );
        const ne = overlayProjection.fromLatLngToDivPixel(
          this.bounds.getNorthEast()
        );

        if (sw && ne) {
          this.div.style.left = sw.x + 'px';
          this.div.style.top = ne.y + 'px';
          this.div.style.width = ne.x - sw.x + 'px';
          this.div.style.height = sw.y - ne.y + 'px';
        }
      }

      override onRemove() {
        if (this.div) {
          this.div.parentNode?.removeChild(this.div);
          this.div = null;
          this.currentImg = null;
          this.nextImg = null;
        }
      }

      updateImage(newImage: HTMLImageElement) {
        if (
          !this.div ||
          !this.currentImg ||
          !this.nextImg ||
          this.isTransitioning
        )
          return;

        this.isTransitioning = true;

        // Set the new image to the next image element (currently hidden)
        this.nextImg.src = newImage.src;
        this.nextImg.style.transition = `opacity ${this.TRANSITION_DURATION}ms`;
        this.currentImg.style.transition = `opacity ${
          this.TRANSITION_DURATION * 1.2
        }ms`;

        // Start transition after a brief delay to ensure the new image is loaded
        setTimeout(() => {
          if (!this.currentImg || !this.nextImg) return;

          // Fade in the next image
          this.nextImg.style.opacity = this.LAYER_OPACITY.toString();
          // Fade out the current image
          this.currentImg.style.opacity = '0';

          // After transition completes, swap the images and reset
          setTimeout(() => {
            if (!this.currentImg || !this.nextImg) return;

            // Swap the references
            const temp = this.currentImg;
            this.currentImg = this.nextImg;
            this.nextImg = temp;

            // Reset the next image
            this.nextImg.style.opacity = '0';
            this.isTransitioning = false;
          }, this.TRANSITION_DURATION);
        }, 50);
      }
    };

    this.mapInitialized = true;

    if (this.heatmapProjectId.length && this.googleMap.googleMap) {
      this.initializeData(this.heatmapProjectId, this.googleMap.googleMap);
      if (this.boundingBox) {
        this.googleMap.googleMap.fitBounds(this.boundingBox);
      }
    }

    this.googleMap.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
      document.getElementById('zoom-control')!
    );
    this.googleMap.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
      document.getElementById('fullscreen-control')!
    );
    this.googleMap.controls[google.maps.ControlPosition.RIGHT_TOP].push(
      document.getElementById('map-types-control')!
    );
  }

  /**
   * @description Initializes heatmap data.
   * @param heatmapProjectId heatmap project id
   * @param map google maps map object
   * @dependsOn `commonService`
   * @functionsUsed `fetchHeatmapBoundingBox`, `commonService`.`getAllDeviceTypes`, `commonService`.`getAllAQI`, `commonService`.`getAllAQIs`, `DeviceUtil`.`aqiColorArray`
   * @variablesUsed `deviceTypeId`
   * @modifies `aqiIndexColor`
   */
  private initializeData(heatmapProjectId: string, map: GMap): void {
    this.fetchHeatmapBoundingBox(heatmapProjectId, map);

    let deviceTypes = this.commonService.getAllDeviceTypes();
    let allAqi = this.commonService.getAllAQI();
    let allAqis = this.commonService.getAllAQIs();

    this.aqiIndexColor = DeviceUtil.aqiColorArray(
      deviceTypes,
      allAqi,
      allAqis,
      1001,
      deviceTypes.find(
        (deviceType: DeviceType) =>
          deviceType.deviceTypeId === this.deviceTypeId
      )?.key
    );
  }

  private initializeMapLayers(map: GMap, geoJSON: any) {
    this.createAndSetGeoJSONDataLayer(map, geoJSON, GEOJSON_DATA_LAYER_STYLES, [
      this.heatmapClickEvent,
    ]);
    this.updateHeatmap();
    if (this.selectedPolygonLayer) {
      this.selectedPolygonLayer.setMap(map);
      this.selectedPolygonLayer.toGeoJson((geoJSON) => {
        map.fitBounds(
          this.getBoundingBoxFromGeoJSON(geoJSON),
          this.polygonFitBoundsPadding
        );
      });
    }

    this.googleMap.controls[google.maps.ControlPosition.RIGHT_BOTTOM].pop();
    this.googleMap.controls[google.maps.ControlPosition.RIGHT_BOTTOM].pop();
    this.googleMap.controls[google.maps.ControlPosition.RIGHT_TOP].pop();
    this.googleMap.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
      document.getElementById('zoom-control')!
    );
    this.googleMap.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
      document.getElementById('fullscreen-control')!
    );
    this.googleMap.controls[google.maps.ControlPosition.RIGHT_TOP].push(
      document.getElementById('map-types-control')!
    );
  }

  /**
   * @description Fetches the heatmap bounding geoJSON from backend.
   * @param heatmapProjectId heatmap project id
   * @param map google maps map object
   * @dependsOn `heatmapService`
   * @functionsUsed `heatmapService`.`getBoundingBox`, `getBoundingBox`, `fetchHeatmapLayers`, `generateHeatmapOverlays`, `selectParameter`, `updateSliderConfig`
   * @variablesUsed `heatmapData`, `boundingBox`, `parameters`, `selectedParameter`
   * @modifies `boundingGeoJSON`, `boundingBox`, `selectedLatLng`
   */
  private fetchHeatmapBoundingBox(heatmapProjectId: string, map: GMap) {
    console.log('loading subtracted value before addition', this.loading);
    console.log('fetching heatmap bounding box');
    this.loading++;
    this.heatmapService.getBoundingBox(heatmapProjectId).subscribe({
      next: (res) => {
        this.boundingGeoJSON = res;

        if (
          this.getGeoJSONLayerType(this.boundingGeoJSON)?.toLowerCase() ===
          'LineString'.toLowerCase()
        ) {
          this.boundingGeoJSON = this.convertLineStringToPolygon(
            this.boundingGeoJSON
          );
        }
        this.boundingBox = this.getBoundingBoxFromGeoJSON(this.boundingGeoJSON);

        let heatmapBoundaryLayer = this.createAndSetGeoJSONDataLayer(
          map,
          this.boundingGeoJSON,
          GEOJSON_DATA_LAYER_STYLES,
          [this.heatmapClickEvent]
        );

        if (!this.heatmapData) {
          console.log(
            "fetching heatmap layers please check if this is correct it's called after bounding box is fetched"
          );

          this.fetchHeatmapLayers(heatmapProjectId, this.selectedDateRange);
        } else if (this.parameters?.length) {
          this.heatmapData = this.generateHeatmapOverlays(
            this.heatmapData,
            this.boundingBox,
            this.parameters
          );
          if (this.selectedParameter) {
            this.selectParameter(this.selectedParameter);
          }
        }

        console.log(
          'loading subtracted value before subtraction',
          this.loading
        );
        console.log('fetched heatmap bounding box');
        this.loading--;

        this.updateSliderConfig();
        setTimeout(() => {
          if (this.googleMap?.googleMap && this.boundingBox) {
            this.googleMap.googleMap.fitBounds(this.boundingBox);
            this.initializeMapLayers(
              this.googleMap.googleMap,
              this.boundingGeoJSON
            );
          }
        }, 100);
      },
    });
  }

  private getGeoJSONLayerType(geoJSON: any): string | null {
    if (!geoJSON || !geoJSON.type) {
      throw new Error('Invalid GeoJSON object');
    }

    switch (geoJSON.type) {
      case 'FeatureCollection':
        // Check the type of the first feature's geometry
        if (geoJSON.features && geoJSON.features.length > 0) {
          return geoJSON.features[0].geometry.type;
        }
        break;
      case 'Feature':
        // Return the geometry type of the feature
        return geoJSON.geometry?.type || null;
      case 'GeometryCollection':
        // Return the type of the first geometry in the collection
        if (geoJSON.geometries && geoJSON.geometries.length > 0) {
          return geoJSON.geometries[0].type;
        }
        break;
      default:
        // For single geometry objects
        return geoJSON.type;
    }

    return null; // Return null if no valid geometry type is found
  }

  private flipCoordinates(geoJSON: any): any {
    // Helper function to flip a single coordinate pair
    const flipCoordinate = (coord: [number, number]): [number, number] => [
      coord[1],
      coord[0],
    ];

    // Recursive function to flip coordinates based on geometry type
    const processGeometry = (geometry: any) => {
      if (!geometry || !geometry.type) return;

      switch (geometry.type) {
        case 'Point':
          geometry.coordinates = flipCoordinate(geometry.coordinates);
          break;
        case 'LineString':
        case 'MultiPoint':
          geometry.coordinates = geometry.coordinates.map(flipCoordinate);
          break;
        case 'Polygon':
        case 'MultiLineString':
          geometry.coordinates = geometry.coordinates.map(
            (line: [number, number][]) => line.map(flipCoordinate)
          );
          break;
        case 'MultiPolygon':
          geometry.coordinates = geometry.coordinates.map(
            (polygon: [number, number][][]) =>
              polygon.map((line: [number, number][]) =>
                line.map(flipCoordinate)
              )
          );
          break;
        case 'GeometryCollection':
          geometry.geometries.forEach(processGeometry);
          break;
        default:
          throw new Error(`Unsupported geometry type: ${geometry.type}`);
      }
    };

    // Handle different GeoJSON object types
    if (geoJSON.type === 'FeatureCollection') {
      geoJSON.features.forEach((feature: any) => {
        if (feature.geometry) {
          processGeometry(feature.geometry);
        }
      });
    } else if (geoJSON.type === 'Feature') {
      if (geoJSON.geometry) {
        processGeometry(geoJSON.geometry);
      }
    } else {
      // For raw Geometry object (Point, LineString, Polygon, etc.)
      processGeometry(geoJSON);
    }

    return geoJSON;
  }

  /**
   * @description From GeoJSON object, extracts the lat, lng bounds, and returns bounding box object
   * @param geoJSON GeoJSON object, either a Feature or FeatureCollection
   * @returns {google.maps.LatLngBounds} Bounding box object for the GeoJSON
   */
  private getBoundingBoxFromGeoJSON(geoJSON: any): google.maps.LatLngBounds {
    let boundingBox = new google.maps.LatLngBounds();
    geoJSON = JSON.parse(JSON.stringify(geoJSON)); // Deep clone to avoid mutating original GeoJSON

    if (geoJSON.type === 'FeatureCollection' && geoJSON.features) {
      // If it's a FeatureCollection, process each feature
      geoJSON.features.forEach((feature: any) => {
        if (feature && feature.geometry) {
          this.processCoordinates(feature.geometry, boundingBox);
        }
      });
    } else if (geoJSON.type === 'Feature' && geoJSON.geometry) {
      // If it's a single Feature, process its geometry
      this.processCoordinates(geoJSON.geometry, boundingBox);
    } else if (geoJSON.type === 'GeometryCollection' && geoJSON.geometries) {
      // If it's a GeometryCollection, process each geometry in the collection
      geoJSON.geometries.forEach((geometry: any) => {
        this.processCoordinates(geometry, boundingBox);
      });
    } else {
      // If it's a standalone Geometry, process directly
      this.processCoordinates(geoJSON, boundingBox);
    }

    return boundingBox;
  }

  /**
   * @description Recursively processes coordinates and extends the bounding box
   * @param geometry GeoJSON geometry object
   * @param boundingBox google.maps.LatLngBounds object to extend with coordinates
   */
  private processCoordinates(
    geometry: any,
    boundingBox: google.maps.LatLngBounds
  ): void {
    switch (geometry.type) {
      case 'Point':
        boundingBox.extend(
          new google.maps.LatLng({
            lat: geometry.coordinates[1],
            lng: geometry.coordinates[0],
          })
        );
        break;

      case 'MultiPoint':
      case 'LineString':
        geometry.coordinates.forEach(([lng, lat]: [number, number]) => {
          boundingBox.extend(new google.maps.LatLng({ lat, lng }));
        });
        break;

      case 'Polygon':
      case 'MultiLineString':
        geometry.coordinates.forEach((line: [number, number][]) => {
          line.forEach(([lng, lat]: [number, number]) => {
            boundingBox.extend(new google.maps.LatLng({ lat, lng }));
          });
        });
        break;

      case 'MultiPolygon':
        geometry.coordinates.forEach((polygon: [number, number][][]) => {
          polygon.forEach((line: [number, number][]) => {
            line.forEach(([lng, lat]: [number, number]) => {
              boundingBox.extend(new google.maps.LatLng({ lat, lng }));
            });
          });
        });
        break;

      case 'GeometryCollection':
        geometry.geometries.forEach((geom: any) => {
          this.processCoordinates(geom, boundingBox);
        });
        break;

      default:
        throw new Error(`Unsupported geometry type: ${geometry.type}`);
    }
  }

  private convertLineStringToPolygon(geoJSON: any): any {
    // Helper function to close a LineString if it's not already closed
    const closeLineString = (coordinates: [number, number][]) => {
      const [first, last] = [
        coordinates[0],
        coordinates[coordinates.length - 1],
      ];
      if (first[0] !== last[0] || first[1] !== last[1]) {
        coordinates.push(first); // Close the LineString
      }
      return coordinates;
    };

    // Function to convert a single LineString geometry to Polygon
    const convertGeometryToPolygon = (geometry: any) => {
      if (geometry.type === 'LineString') {
        geometry.type = 'Polygon';
        geometry.coordinates = [closeLineString(geometry.coordinates)];
      }
    };

    // Convert based on the GeoJSON type
    if (geoJSON.type === 'FeatureCollection') {
      // Convert each LineString in the feature collection to a Polygon
      geoJSON.features.forEach((feature: any) => {
        if (feature.geometry) {
          convertGeometryToPolygon(feature.geometry);
        }
      });
    } else if (geoJSON.type === 'Feature') {
      // Convert LineString to Polygon for a single feature
      if (geoJSON.geometry) {
        convertGeometryToPolygon(geoJSON.geometry);
      }
    } else if (geoJSON.type === 'LineString') {
      // Convert a raw LineString geometry object
      geoJSON.type = 'Polygon';
      geoJSON.coordinates = [closeLineString(geoJSON.coordinates)];
    }

    return geoJSON;
  }

  /**
   * @description creates a data layer from the geoJSON, also sets the layer on a map, apply the styles if passed
   * @param map google map object to add the created data layer
   * @param geoJSON geoJSON for the layer to be added
   * @param layerStyles styles for the layer to be added, default *null*
   * @returns {google.maps.Data} data layer with the
   */
  private createAndSetGeoJSONDataLayer(
    map: GMap,
    geoJSON: any,
    layerStyles:
      | google.maps.Data.StylingFunction
      | google.maps.Data.StyleOptions
      | null = null,
    events: {
      name: string;
      callback: Function;
    }[]
  ) {
    let dataLayer = new google.maps.Data();

    dataLayer.addGeoJson(geoJSON);

    dataLayer.setStyle(layerStyles);

    events.forEach((event) => {
      dataLayer.addListener(event.name, event.callback);
    });

    dataLayer.setMap(map);

    return dataLayer;
  }

  private updateSliderConfig() {
    this.sliderConfig.min = 0;
    this.sliderConfig.max = (this.heatmapData?.length ?? 1) - 1;
    this.sliderConfig.disabled = !this.heatmapData?.length;
    this.sliderConfig = { ...this.sliderConfig };
  }

  /**
   * @description Gets the heatmap layers from backend, gets the `parameters`, generates the overlay layers if bounding box is fetched and, if no parameter is selected, selects one, updates slider config.
   * @param heatmapProjectId heatmap project id
   * @param dateRange date range selected by the user
   * @dependsOn `heatmapService`
   * @functionsUsed `heatmapService`.`getHeatMap`, `getParameters`, `generateHeatmapOverlays`, `selectParameter`, `updateSliderConfig`
   * @modifies `heatmapData`, `parameters`
   */
  private fetchHeatmapLayers(heatmapProjectId: string, dateRange: any) {
    console.log('loading subtracted value before addition', this.loading);
    console.log('fetching heatmap layers');
    this.loading++;

    this.heatmapService
      .getHeatMap(
        heatmapProjectId,
        dateRange.startDate.unix(),
        dateRange.endDate.unix()
      )
      .subscribe({
        next: (res) => {
          this.heatmapData = res;
          if (this.heatmapData?.length) {
            this.parameters = this.getParameters(this.heatmapData);
            if (this.boundingBox) {
              this.heatmapData = this.generateHeatmapOverlays(
                this.heatmapData,
                this.boundingBox,
                this.parameters
              );
            }
            this.selectParameter(this.selectedParameter ?? this.parameters[0]);
            this.updateSliderConfig();
          }
          console.log(
            'loading subtracted value before subtraction',
            this.loading
          );
          console.log('fetched heatmap layers');

          this.loading--;
          setTimeout(() => {
            if (this.googleMap?.googleMap && this.boundingBox) {
              this.googleMap.googleMap.fitBounds(this.boundingBox);
              this.initializeMapLayers(
                this.googleMap.googleMap,
                this.boundingGeoJSON
              );
            }
          }, 100);
        },
      });
  }

  /**
   * @description From the heatmap layers data, gets the units object for all the parameters
   * @param heatmapData heatmap layers data
   * @dependsOn `commonService`
   * @functionsUsed `commonService`.`getAllUnits`
   * @returns list of all the params for the heatmap
   */
  private getParameters(heatmapData: any[]): Unit[] {
    let params: string[] = (
      Array.from(
        heatmapData.reduce((prev: Set<string>, curr: any) => {
          for (let key of Object.keys(curr.payload.d)) {
            prev.add(key);
          }
          return prev;
        }, new Set<string>()) ?? []
      ) as string[]
    ).filter((p: string) => !['t'].includes(p));
    Object.keys(heatmapData[0].payload.d);
    const units = this.commonService.getAllUnits();

    return params.map((p) => {
      return units[this.deviceTypeId][p];
    });
  }

  /**
   * @description Uses raw heatmap data layers, converts them to google map overlay layers
   * @param heatmapData heatmap layers data
   * @param boundingBox google map LatLngBounds
   * @param parameters paramters for which the layers are to be generated
   * @functionsUsed `createHeatmapOverlay`
   * @returns any[] - array of heatmapLayerData which contains all the layers
   */
  private generateHeatmapOverlays(
    heatmapData: any[] = [],
    boundingBox: google.maps.LatLngBounds,
    parameters: Unit[]
  ): any[] {
    let modifiedHeatmapData: any[] = JSON.parse(JSON.stringify(heatmapData));

    for (let heatmapDataPoint of modifiedHeatmapData) {
      for (let parameter of parameters) {
        heatmapDataPoint.payload.d[`${parameter.key}_mapLayer`] =
          this.createHeatmapOverlay(
            heatmapDataPoint.payload.d[parameter.key],
            boundingBox
          );
      }
    }

    return modifiedHeatmapData;
  }

  /**
   * @description Using the image data url and LatLngBounds, creates a google map GroundOverlay layer.
   * @param map google map object, if it's defined we set the overlay layer on this map
   * @param dataUrl heatmap layer image data url
   * @param boundingBox google map LatLngBounds, we create layer for this bounding box
   * @returns google map GroundOverlay created using the passed parameters
   */
  private createHeatmapOverlay(
    dataUrl: string,
    boundingBox: google.maps.LatLngBounds
  ): any {
    let i = new Image();
    i.src = dataUrl;
    let overlay = new this.ImageTransitionMapOverlay(boundingBox, i);
    return overlay;
  }

  private fetchHeatmapPolygon(lat: number, lng: number) {
    this.chartLoading++;
    this.deviceData = undefined;
    if (this.heatmapProjectId.length) {
      this.heatmapService
        .getPolygonData(
          this.heatmapProjectId,
          lat,
          lng,
          this.selectedDateRange.startDate.unix(),
          this.selectedDateRange.endDate.unix()
        )
        .subscribe({
          next: (res) => {
            this.chartLoading--;
            this.deviceData = res;
            if (res.length) {
              if (this.googleMap?.googleMap) {
                if (this.selectedPolygonLayer) {
                  this.selectedPolygonLayer.setMap(null);
                }

                this.selectedPolygonLayer = this.createAndSetGeoJSONDataLayer(
                  this.googleMap.googleMap,
                  res[0].polygon,
                  {
                    fillColor: 'transparent',
                    strokeColor: '#00c4b4',
                    strokeWeight: 2,
                  },
                  []
                );
                let latlong = this.getBoundingBoxFromGeoJSON(res[0].polygon);

                setTimeout(() => {
                  if (this.googleMap?.googleMap) {
                    this.googleMap.googleMap.fitBounds(
                      latlong,
                      this.polygonFitBoundsPadding
                    );
                  }
                });
              }
            }
          },
        });
    }
  }

  public selectParameter(param: Unit): void {
    this.selectedParameter = param;
    if (this.isPlaying) {
      this.stopAnimation();
    }
    this.updateHeatmap();
  }

  get isPlaying(): boolean {
    return this.player.isPlaying;
  }

  /**
   * Handles play/pause functionality for the heatmap animation
   */
  public playPauseHeatMap(): void {
    this.player.isPlaying = !this.player.isPlaying;

    if (this.player.isPlaying) {
      this.startAnimation();
    } else {
      this.stopAnimation();
    }
  }

  private startAnimation(): void {
    // Cancel any existing animation
    this.stopAnimation();

    let lastFrameTime = performance.now();

    const animate = (currentTime: number) => {
      if (!this.player.isPlaying) return;

      const elapsed = currentTime - lastFrameTime;
      const frameInterval = this.BASE_FRAME_INTERVAL / this.player.speed;

      if (elapsed >= frameInterval) {
        lastFrameTime = currentTime;

        // Update frame
        this.player.currentIndex =
          (this.player.currentIndex + 1) % (this.sliderConfig.max + 1);
        this.updateHeatmap();
      }

      this.player.animationFrameId = requestAnimationFrame(animate);
    };

    this.player.animationFrameId = requestAnimationFrame(animate);
  }

  private stopAnimation(): void {
    if (this.player.animationFrameId) {
      cancelAnimationFrame(this.player.animationFrameId);
      this.player.animationFrameId = undefined;
    }
  }

  /**
   * Updates the heatmap display with current frame data
   */
  public async updateHeatmap(): Promise<void> {
    if (!this.selectedParameter || !this.heatmapData?.length) return;

    const currentFrame = this.heatmapData[this.player.currentIndex];
    if (!currentFrame) return;

    this.setSelectedTime(currentFrame.payload.d['t']);

    const imageData = currentFrame.payload.d[this.selectedParameter.key];
    if (!imageData) return;

    try {
      await this.updateOverlayWithTransition(`${imageData}`);
    } catch (error) {
      console.error('Error updating heatmap:', error);
    }
  }

  private async loadImage(dataUrl: string): Promise<HTMLImageElement> {
    dataUrl = await this.heatmapService.getMaskedImage(
      this.boundingGeoJSON,
      dataUrl
    );
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = dataUrl;
    });
  }

  // Usage in your component
  private async updateOverlayWithTransition(url: string) {
    const image = await this.loadImage(url);
    if (this.heatmapOverlayLayer) {
      this.heatmapOverlayLayer.updateImage(image);
    } else {
      this.heatmapOverlayLayer = new this.ImageTransitionMapOverlay(
        this.boundingBox!,
        image
      );
      if (this.googleMap?.googleMap) {
        // Set the overlay on the map
        this.heatmapOverlayLayer.setMap(this.googleMap.googleMap);
      }
    }
  }

  private setSelectedTime(epoch: number) {
    this.selectedTime = this.customMomentService.formatDatetime({
      epoch,
      format:
        this.userTimeformat === 12
          ? 'DD/MM/YY<br/>hh:mm A'
          : 'DD/MM/YY<br/>HH:mm',
    });

    this.selectedTimeStyles.left = `${
      (this.currentHeatmapDataIndex / this.sliderConfig.max) * 100
    }%`;
  }

  public changeMapType(): void {
    if (this.currentMapType === 'roadmap') {
      this.currentMapType = 'hybrid';
    } else {
      this.currentMapType = 'roadmap';
    }

    this.options = {
      ...this.options,
      mapTypeId: this.currentMapType,
      center: this.googleMap.getCenter(),
      zoom: this.googleMap.getZoom(),
    };
  }

  public zoomIn(): void {
    this.options = {
      ...this.options,
      center: this.googleMap.getCenter(),
      zoom:
        this.googleMap.getZoom() &&
        this.googleMap.getZoom()! + 1 <= this.options.maxZoom!
          ? this.googleMap.getZoom()! + 1
          : this.options.maxZoom,
    };
  }

  public zoomOut(): void {
    this.options = {
      ...this.options,
      center: this.googleMap.getCenter(),
      zoom:
        this.googleMap.getZoom() &&
        this.googleMap.getZoom()! - 1 >= this.options.minZoom!
          ? this.googleMap.getZoom()! - 1
          : this.options.minZoom,
    };
  }

  public switchToFullScreen(): void {
    const map = this.googleMap.googleMap?.getDiv().firstChild as HTMLElement;
    if (this.isFullscreen(map)) {
      this.exitFullscreen();
    } else {
      this.requestFullscreen(map);
    }
  }

  public isFullscreen(element: HTMLElement): boolean {
    return document.fullscreenElement == element;
  }

  public requestFullscreen(element: HTMLElement): void {
    if (element.requestFullscreen) {
      this.fullScreenMode = 'zoom_in_map';
      element.requestFullscreen();
    }
  }

  public exitFullscreen(): void {
    if (document.exitFullscreen) {
      this.fullScreenMode = 'zoom_out_map';
      document.exitFullscreen();
    }
  }

  public showSelectedTime() {
    if (this.hiddingSelectedTime) {
      clearTimeout(this.hiddingSelectedTime);
      if (this.removingSelectedTime) {
        clearTimeout(this.removingSelectedTime);
        this.removingSelectedTime = null;
      }
      this.hiddingSelectedTime = null;
    }
    this.shouldShowSelectedTime = true;
    this.removeSelectedTime = false;
  }

  public hideSelectedTime() {
    if (this.hiddingSelectedTime) {
      clearTimeout(this.hiddingSelectedTime);
      this.hiddingSelectedTime = null;
    }
    this.shouldShowSelectedTime = true;
    this.hiddingSelectedTime = setTimeout(() => {
      this.shouldShowSelectedTime = false;

      this.removeSelectedTime = false;
      if (this.removingSelectedTime) {
        clearTimeout(this.removingSelectedTime);
        this.removingSelectedTime = null;
      }
      this.removingSelectedTime = setTimeout(() => {
        this.removeSelectedTime = true;

        this.removingSelectedTime = null;
      }, 1000);

      this.hiddingSelectedTime = null;
    }, 500);
  }

  public closeChartsView() {
    if (this.selectedPolygonLayer) {
      this.selectedPolygonLayer.setMap(null);
    }
    this.selectedPolygonLayer = undefined;
    if (this.googleMap.googleMap && this.boundingBox) {
      this.googleMap.googleMap.fitBounds(this.boundingBox);
    }
    this.deviceData = undefined;
  }

  ngOnDestroy() {
    this.stopAnimation();
    this.subscriptions.forEach((subscription: Subscription) => {
      if (!subscription.closed) subscription.unsubscribe();
    });
    this.resizeObserver.disconnect();

    if (this.heatmapOverlayLayer) {
      this.heatmapOverlayLayer.setMap(null);
    }
  }
  private ImageTransitionMapOverlay: any = null;
}
