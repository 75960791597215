import { Component, Inject } from '@angular/core';
import { MTX_DRAWER_DATA } from '@ng-matero/extensions/drawer';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { SensorExpiryDetails } from 'src/app/shared/models/device/sensor-expiry-details';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { FormsService } from 'src/app/shared/services/forms.service';

@Component({
  selector: 'app-sensor-info',
  templateUrl: './sensor-info.component.html',
  styleUrls: ['./sensor-info.component.scss'],
})
export class SensorInfoComponent {
  loadTable: boolean = false;
  displayedColumns: string[] = ['key', 'sensorId', 'updated_on', 'expiry'];
  dataSource!: SensorExpiryDetails[];
  todayDate!: number;

  constructor(
    private formsService: FormsService,
    private deviceService: DeviceService,
    private customMomentService: CustomMomentService,
    @Inject(MTX_DRAWER_DATA) public data: DeviceDetails
  ) {}

  ngOnInit(): void {
    this.deviceService
      .getSensorExpiryDetails(this.data.deviceId)
      .subscribe((result: SensorExpiryDetails[]) => {
        this.dataSource = result;
        this.updateDataSource();
        this.loadTable = true;
      });

    this.todayDate = this.customMomentService.moment().unix();
  }

  //updating the data to convert epoch time to 'DD/MM/YYYY' format
  updateDataSource() {
    // let fields = this.deviceService.fields;
    this.dataSource.forEach((result) => {
      result.expiryInEpoch = Number(result.expiry);
      result.expiry = this.customMomentService.formatDate({
        epoch: Number(result.expiry),
      });
      result.updated_on = this.customMomentService.formatDate({
        epoch: Number(result.updated_on),
      });
      // result.key = DeviceUtil.getFieldName(result.key, fields);
    });
  }

  closeForm(data: boolean = false) {
    this.formsService.closeForm(data);
  }
}
