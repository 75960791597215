<app-terminal-page-layout [menu]="menu" class="ui-tour-dashboard">
  <app-terminal-page-header
    [menu]="menu"
    [selectedSubMenu]="selectedMenuTab"
    (tabChange)="onTabChange($event)"
  >
    <div tourAnchor="dashboard-tour-0" class="w-0 h-0"></div>
    <div
      [formGroup]="headerForm"
      class="row h-100 align-items-center gx-3 gy-3 gy-lg-0 mx-0"
    >
      <mat-form-field class="col-12 col-lg-3">
        <mat-label>Device</mat-label>
        <input
          type="text"
          [placeholder]="'Device'"
          matInput
          formControlName="device"
          [matAutocomplete]="deviceName"
        />
        <mat-autocomplete
          #deviceName="matAutocomplete"
          [displayWith]="getDeviceName"
        >
          <mat-optgroup
            *ngFor="let deviceType of deviceTypes"
            [label]="deviceType.label"
          >
            <mat-option
              *ngFor="
                let device of deviceService.registeredDevices ?? []
                  | deviceType : deviceType.key
                  | search : headerForm.get('device')?.value
              "
              [value]="device"
            >
              {{ device.label }}
            </mat-option>
          </mat-optgroup>
        </mat-autocomplete>
        <button mat-icon-button matSuffix tabindex="-1">
          <mat-icon class="material-symbols-outlined">arrow_drop_down</mat-icon>
        </button>
      </mat-form-field>
      <ng-container *ngIf="selectedMenuTab?.url === 'widget'">
        <div
          class="col-12 col-lg-9 d-flex justify-content-end align-items-center gap-2"
        >
          <ng-container *ngIf="editMode">
            <button
              mat-mini-fab
              [color]="'primary'"
              type="button"
              matTooltip="Reset widgets placement to default."
              (click)="resetWidgetsView()"
            >
              <mat-icon matSuffix class="material-symbols-outlined">
                restart_alt
              </mat-icon>
            </button>
            <button
              tourAnchor="dashboard-tour-5"
              mat-mini-fab
              [color]="'primary'"
              type="button"
              matTooltip="Save widgets placement."
              (click)="saveWidgetsView()"
            >
              <mat-icon matSuffix class="material-symbols-outlined">
                save
              </mat-icon>
            </button>
            <button
              mat-mini-fab
              [color]="'primary'"
              type="button"
              matTooltip="Cancel"
              (click)="cancelWidgetsViewEdits()"
            >
              <mat-icon matSuffix class="material-symbols-outlined">
                Cancel
              </mat-icon>
            </button>
          </ng-container>
          <ng-container *ngIf="!editMode">
            <button
              tourAnchor="dashboard-tour-1"
              mat-mini-fab
              [color]="'primary'"
              type="button"
              matTooltip="Edit widget placement."
              (click)="editWidgetsView()"
            >
              <mat-icon matSuffix class="material-symbols-outlined">
                edit
              </mat-icon>
            </button>
            <button
              mat-mini-fab
              [color]="'primary'"
              type="button"
              matTooltip="Add widgets"
              (click)="addWidget()"
            >
              <mat-icon matSuffix class="material-symbols-outlined">
                add
              </mat-icon>
            </button>
          </ng-container>
        </div>
      </ng-container>
      <mat-form-field
        class="col-12 col-lg-3"
        *ngIf="selectedMenuTab?.url === 'table'"
      >
        <mat-label>Select Dates</mat-label>
        <mat-date-range-input
          [rangePicker]="picker" [max]="maxDate" [min]="minDate"
        >
          <input
            matStartDate
            formControlName="startDate"
            [placeholder]="'Start Date'"
          />
          <input
            matEndDate
            formControlName="endDate"
            [placeholder]="'End Date'"
          />
        </mat-date-range-input>
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <mat-error
          *ngIf="
            headerForm?.controls?.startDate?.hasError('matStartDateInvalid')
          "
        >
          Invalid start date
        </mat-error>
        <mat-error
          *ngIf="headerForm?.controls?.endDate?.hasError('matEndDateInvalid')"
        >
          Invalid end date
        </mat-error>
      </mat-form-field>
      <mat-form-field
        class="col-12 col-lg-2"
        *ngIf="selectedMenuTab?.url === 'table'"
      >
        <mat-label>Data Average</mat-label>
        <mat-select formControlName="average" [placeholder]="'Data Average'">
          <ng-container *ngFor="let average of averageHours">
            <mat-option [value]="average" class="p-2">
              {{ average?.label }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
      <div
        class="col col-lg-auto col-12 col-lg-2s"
        *ngIf="selectedMenuTab?.url === 'table'"
      >
        <button
          (click)="tableFormChange()"
          mat-raised-button
          color="primary"
          matTooltip="Apply"
        >
          Apply
        </button>
      </div>
    </div>
  </app-terminal-page-header>
  <app-terminal-page-body>
    <div class="h-100 overflow-auto">
      <router-outlet></router-outlet>
    </div>
  </app-terminal-page-body>
</app-terminal-page-layout>
<ng-container *ngIf="isTourMode">
  <div class="transparent-overlay"></div>
</ng-container>
