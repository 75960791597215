import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Subscription } from 'rxjs';
import { DeviceType } from '../../models/device-type/device-type';
import { DeviceDetails } from '../../models/device/device-details';
import { DeviceService } from '../../services/device.service';

@Component({
  selector: 'app-tag-and-devicetype-dropwdown',
  templateUrl: './tag-and-devicetype-dropwdown.component.html',
  styleUrls: ['./tag-and-devicetype-dropwdown.component.scss'],
})
export class TagAndDevicetypeDropwdownComponent {
  @Input() deviceTypes!: DeviceType[]; // List of all available device types
  @Input() devices!: DeviceDetails[]; // List of all devices
  @Input() patchDevice!: DeviceDetails | undefined; // Device to be patched in the form
  @Input() tags!: string[]; // List of all available tags
  @Input() isMultipleTag: boolean = true; // Indicates if multiple tag selection is allowed
  @Input() infoMessage: string = '';
  @Output() selectedDevice: EventEmitter<DeviceDetails> = new EventEmitter(); // Emits the selected device
  @Output() filteredDeviceList: EventEmitter<DeviceDetails[]> =
    new EventEmitter(); // Emits filtered list of devices based on tag selection

  public inputForm!: FormGroup;
  public copyOfDevices!: DeviceDetails[];
  public subscriptions: Subscription[] = [];

  constructor(
    private deviceService: DeviceService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.copyOfDevices = this.devices;
    this.buildForm();
  }

  //if user click direcly on the device pins on the map then listen to the changes
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['patchDevice']) {
      if (changes['patchDevice'].currentValue) {
        const device = this.devices.find(
          (device) =>
            device.deviceId === changes['patchDevice'].currentValue.deviceId
        );
        if (device) {
          this.inputForm.patchValue({
            deviceSearch: device,
          });
        }
      }
      //means user has deselected the device
      else {
        this.inputForm.patchValue({
          deviceSearch: '',
        });
      }
    }
  }

  buildForm() {
    this.inputForm = this.formBuilder.group({
      deviceSearch: '',
      tag: '',
    });
    this.addSubscriptions();
  }

  addSubscriptions() {
    const tagSubs = this.inputForm.get('tag')?.valueChanges.subscribe((res) => {
      this.devices = this.getDevicesWithTag(res);
      //emit the devices which contains the selected tags
      this.filteredDeviceList.next(this.devices);
    });

    const deviceSubs = this.inputForm
      .get('deviceSearch')
      ?.valueChanges.subscribe((device) => {
        //when user selects device from the dropdown
        this.selectedDevice.next(device);
      });

    if (tagSubs) this.subscriptions.push(tagSubs);
    if (deviceSubs) this.subscriptions.push(deviceSubs);
  }

  getDevicesWithTag(tags: string[]): DeviceDetails[] {
    if (!tags.length) {
      return this.copyOfDevices; //if no tag selected than return all devices
    }
    return this.copyOfDevices.filter((device) =>
      device?.options?.deviceTags?.some((tag) => tags.includes(tag))
    );
  }

  clearDeviceName(): void {
    this.inputForm.controls.deviceSearch.setValue(undefined);
    this.deviceService.removeDeviceGlobally();
  }

  selectionMade(event: Event, trigger: MatAutocompleteTrigger) {
    event.stopPropagation();
    if (trigger.panelOpen) {
      trigger.closePanel();
    } else {
      trigger.openPanel();
    }
  }

  getDeviceName(device: DeviceDetails): string {
    return device ? device.label.trim() : '';
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
