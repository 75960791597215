<mat-form-field class="w-100" appearance="outline">
  <mat-label>Enter Tags</mat-label>
  <mat-chip-grid #tagChipGrid>
    <mat-chip-row
      *ngFor="let tag of selectedTags; let i = index"
      (removed)="remove(tag, i)"
    >
      <button matChipRemove matTooltip="Cancel">
        <mat-icon class="material-symbols-outlined">cancel</mat-icon>
      </button>
      {{ tag }}
    </mat-chip-row>
    <input
      placeholder="Add Device Tag..."
      [matChipInputFor]="tagChipGrid"
      [matChipInputSeparatorKeyCodes]="seperatorKeysCodes"
      [matChipInputAddOnBlur]="addOnBlur"
      (matChipInputTokenEnd)="add($event)"
      [matAutocomplete]="auto"
      [formControl]="tagInputFormControl"
      #trigger="matAutocompleteTrigger"
    />
    <!-- <button
      (click)="selectionMade($event, trigger)"
      mat-icon-button
      matSuffix
      tabindex="-1"
    >
      <mat-icon class="material-symbols-outlined">arrow_drop_down</mat-icon>
    </button> -->
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="onOptionSelected($event)"
    >
      <mat-option *ngFor="let tag of filteredTags" [value]="tag">
        {{ tag }}
      </mat-option>
    </mat-autocomplete>
  </mat-chip-grid>
</mat-form-field>
