import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { APIConstants } from '../constants/api-constants';
import { AppConstants } from '../constants/app-constants';
import { LocalStorageConstants } from '../constants/local-storage.constant';
import { ReportData } from '../models/reports/report-data';
import { BaseAPIService } from './base-service';
import { CommonService } from './common.service';
import { CookieService } from './cookie.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ReportsService extends BaseAPIService<ReportData.Get> {
  public reportList!: ReportData.Get[];

  private isReportListAvailable = new BehaviorSubject<boolean>(false);
  public isReportListAvailable$ = this.isReportListAvailable.asObservable();

  private currentDeviceType = new BehaviorSubject<number>(0);
  public currentDeviceType$ = this.currentDeviceType.asObservable();

  httpClient: HttpClient;
  constructor(
    http: HttpClient,
    cookieService: CookieService,
    private localStorageService: LocalStorageService,
    private commonService: CommonService
  ) {
    super(http, cookieService);
    this.httpClient = http;
  }

  getReportDetails() {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );
    const userId = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    ).userId;

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.get<Array<ReportData.Get>>(
      APIConstants.GET_REPORTS(userId),
      headers
    ).subscribe((res) => {
      if (res) {
        this.reportList = res;
        this.isReportListAvailable.next(true);
      }
    });
  }

  downloadPDF(reportDetails: ReportData.DownloadQuickReport): any {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    let param: Record<string, any> = {
      deviceId: reportDetails.devices?.[0].deviceId!,
      userId: reportDetails.userId,
      org: reportDetails.org,
      gte: reportDetails.gte,
      lte: reportDetails.lte!,
      label: reportDetails.devices?.[0].label ?? '',
      loc: reportDetails.devices?.[0].loc ?? '',
      isTwelveHourFormat: reportDetails.isTwelveHourFormat,
      timezone: reportDetails.timezone,
      processType: 'avg',
      avg: reportDetails.avg,
    };

    if (reportDetails.logo_url) {
      param.logo_url = reportDetails.logo_url;
    }

    const params: HttpParams = new HttpParams().appendAll(param);

    return this.get(
      APIConstants.GET_QUICK_REPORT().replace(
        '{deviceId}',
        reportDetails.devices?.[0].deviceId!
      ),
      headers,
      params,
      'blob'
    );
  }

  downloadSLAReport(reportDetails: ReportData.SLAReport): Observable<Blob> {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    const params: HttpParams = new HttpParams().appendAll(reportDetails);
    return this.get(APIConstants.GET_SLA_REPORT, headers, params, 'blob');
  }

  downloadCSV(payload: any): any {
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );

    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    let param: Record<string, any> = {
      deviceId: payload.deviceId,
      'keys[]': payload.params,
      lte: payload.lte,
      gte: payload.gte,
      processType: payload.processType,
      userId: userId,
    };

    if (payload.average) {
      param['avg'] = payload.average;
    }

    const params: HttpParams = new HttpParams().appendAll(param);

    return this.get(
      APIConstants.DOWNLOAD_CSV_FILE.replace('{deviceId}', payload.deviceId),
      headers,
      params,
      'blob'
    );
  }

  getTimeFormat = () => {
    let userDetails = JSON.parse(
      this.localStorageService.getValue(LocalStorageConstants.OZ_USER)
    );
    if (userDetails && userDetails.settings) {
      if (userDetails.settings.time_format === 12) {
        return 12;
      } else if (userDetails.settings.time_format === 24) {
        return 24;
      }
    }
    return undefined;
  };

  submitAutomatedReport(reportDetails: ReportData.Add) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.post<string>(
      APIConstants.ADD_AUTOMATED_REPORT(userId),
      reportDetails,
      headers
    );
  }

  updateAutomatedReport(reportDetails: ReportData.Update, reportId: number) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );
    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    ['deviceId'].forEach(
      (e) =>
        delete (reportDetails.update as Partial<ReportData.Get>)[
          e as keyof ReportData.Get
        ]
    );
    return this.patch<string>(
      APIConstants.UPDATE_OR_DELETE_REPORT(userId, reportId),
      reportDetails,
      headers
    );
  }

  emailPdf(payload: ReportData.EmailQuickReport) {
    
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.post<ReportData.EmailQuickReport>(
      APIConstants.EMAIL_QUICK_REPORT_PDF,
      payload,
      headers
    );
  }

  emailCsv(payload: ReportData.EmailQuickReport) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);
      let csv = true;
      let pdf = false
    return this.post<ReportData.EmailQuickReport>(
      APIConstants.EMAIL_QUICK_REPORT_CSV(csv,pdf),
      payload,
      headers
    );
  }

  triggerCurrentDeviceType(deviceType: number) {
    this.currentDeviceType.next(deviceType);
  }

  deleteReport(payload: ReportData.Get) {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.delete<any>(
      APIConstants.UPDATE_OR_DELETE_REPORT(userId, payload.reportId),
      headers
    );
  }

  //to migrate existing reports from terminal into Envizom
  migrateTerminalReports(payload: ReportData.Migrate) {
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );
    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.post<string>(
      APIConstants.MIGRATE_REPORT.replace('{userId}', userId),
      payload,
      headers
    );
  }
}
