import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MTX_DRAWER_DATA } from '@ng-matero/extensions/drawer';
import {
  Observable,
  Subscription,
  catchError,
  forkJoin,
  map,
  of,
  startWith,
} from 'rxjs';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { UserMobileNumbers } from 'src/app/shared/models/internal-use-front-end/country-wise-ph-code';
import { Language } from 'src/app/shared/models/internal-use-front-end/languages';
import { LoginData } from 'src/app/shared/models/login-data';
import { RequestData } from 'src/app/shared/models/new-user-data';
import { Project } from 'src/app/shared/models/project/project';
import { Settings } from 'src/app/shared/models/settings/settings';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { LoadrService } from 'src/app/shared/services/loadr.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ProjectsService } from 'src/app/shared/services/projects.service';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { UserService } from 'src/app/shared/services/user.service';
@Component({
  selector: 'app-profile-edit-form',
  templateUrl: './profile-edit-form.component.html',
  styleUrls: ['./profile-edit-form.component.scss'],
})
export class ProfileEditFormComponent implements OnInit {
  filteredOptions!: Observable<any>;
  public user!: LoginData;
  public form!: FormGroup;
  public timeFormats = AppConstants.TIME_FORMATS;
  public timezonesWithOffsets!: {
    name: string;
    offSet: string;
    nameOffSet: string;
  }[];
  public enableUpdateButton: boolean = false;
  public readonly specialCharError: string =
    'Special characters are not allowed';
  public subscriptions: Subscription[] = [];
  public languages: Language[] = AppConstants.LANGUAGES;
  public darkModeLogoFile!: File | null;
  public defaultLogofile!: File | null;
  public updateImage: any;
  public darkModeUrl!: any;
  public defaultModeUrl!: any;
  public logoForm!: FormGroup;
  public subsriptions: Subscription[] = [];
  public isEditLogo: boolean = false;
  public originalValuesProfileForm: any;
  public hasValuesChangedProfileForm: boolean = false;
  public hasValuesChangedLogoForm: boolean = false;
  public existingMobileNumbers: string[] = [];
  public isAdminUser!: boolean;

  constructor(
    private formsService: FormsService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private localStorageService: LocalStorageService,
    private loadrService: LoadrService,
    private customMomentService: CustomMomentService,
    private commonService: CommonService,
    private translationService: TranslationService,
    private deviceService: DeviceService,
    private notificationService: NotificationService,
    private projectsService: ProjectsService,
    @Inject(MTX_DRAWER_DATA) public data: LoginData | undefined
  ) {}

  private get languagesMap() {
    return Object.fromEntries(
      this.languages.map(({ key, label }) => [key, { key, label }])
    );
  }

  ngOnInit(): void {
    this.getTimeZones();
    this.buildForm();
    this.onChangeForm();
    this.onChangeTimeZone();

    this.isAdminUser = this.commonService.getUserRole() === 1;
    if (this.data?.contact) this.existingMobileNumbers.push(this.data.contact);
    let user: LoginData = this.commonService.getUser();
    let info: Project.Info = user.info;
    this.darkModeUrl = Boolean(info.dark_mode_logo)
      ? info.dark_mode_logo
      : undefined;
    this.defaultModeUrl = Boolean(info.logo_url) ? info.logo_url : undefined;
  }

  //builds the form for profile edit
  buildForm() {
    this.logoForm = this.formBuilder.group({
      darkLogo: [''],
      defaultLogo: [''],
    });

    this.form = this.formBuilder.group({
      name: [
        this.data?.name,
        [Validators.required, Validators.pattern(AppConstants.USERNAME_REGEX)],
      ],
      company: [
        this.data?.company,
        [Validators.required, Validators.pattern(AppConstants.NAME_REGEX)],
      ],
      email: [{ value: this.data?.email, disabled: true }, Validators.required],
      org: [{ value: this.data?.org, disabled: true }, Validators.required],
      contact: [this.data?.contact, Validators.required],
      location: [
        this.data?.location,
        [
          Validators.required,
          Validators.pattern(AppConstants.LOCATION_ADDRESS_REGEX),
        ],
      ],
      city: [
        this.data?.city,
        [Validators.required, Validators.pattern(AppConstants.NAME_REGEX)],
      ],
      state: [
        this.data?.state,
        [Validators.required, Validators.pattern(AppConstants.NAME_REGEX)],
      ],
      country: [
        this.data?.country,
        [Validators.required, Validators.pattern(AppConstants.NAME_REGEX)],
      ],
      timeZone: ['', Validators.required],
      timeFormat: ['', Validators.required],
      language: ['', Validators.required],
    });

    let timeZone = this.timezonesWithOffsets.find(
      (obj: any) => obj.name === this.data?.settings?.timezone?.name
    );

    let timeFormat = this.timeFormats.find((obj: any) => {
      return (
        obj.value === this.data?.settings?.time_format ||
        obj.value === this.commonService.getTimeFormat()
      );
    });

    let selectedLanguage =
      this.languagesMap[this.data?.settings?.language ?? 'en'];

    this.form.patchValue({
      timeZone: timeZone?.nameOffSet || '',
      timeFormat: timeFormat?.value,
      language: selectedLanguage,
    });

    this.originalValuesProfileForm = this.form.value;
  }

  //it is called to close the form when user clicks on cancel or cross button
  closeForm() {
    this.formsService.closeForm();
  }

  //executes when user click on update button to update the profile information
  submitForm() {
    this.loadrService.showLoader();
    if (this.hasValuesChangedProfileForm) {
      this.submitProfile();
    }
    this.submitLogo();
  }

  submitProfile() {
    this.userService.updateProfile(this.generatePayload()).subscribe({
      next: (res) => {
        if (res) {
          this.userService.getUserProfile().subscribe(() => {
            // this.userService.refreshUserData(true);
            this.formsService.closeForm();
            this.loadrService.removeLoader();
            this.notificationService.showSnackBar(
              'Profile updated successfully',
              'success'
            );
            this.updateLanguage();
          });
        }
      },
      error: (err) => {
        console.info('Error:', err);
        this.loadrService.removeLoader();
      },
    });
  }

  getLanguageLabel(language: Language): string {
    return language?.label ?? '';
  }

  updateLanguage() {
    if (this.form.value.language?.key) {
      this.translationService.switchLanguage(
        this.form.value.language.key
        // this.form.value.language.label
      );
    }
  }

  //use to trigger dropdown open and close in timezone dropdown
  selectionMade(event: Event, trigger: MatAutocompleteTrigger) {
    event.stopPropagation();
    if (trigger.panelOpen) {
      trigger.closePanel();
    } else {
      trigger.openPanel();
    }
  }

  //this function is used to generate payload
  generatePayload(): RequestData.UpdateUser {
    this.user = JSON.parse(JSON.stringify(this.data));

    let settings: Settings = this.commonService.getUserSettings();

    let additionlSettings: {
      [key: string]: string | { [key: string]: string };
    } = {
      time_format: this.form.value.timeFormat,
      timezone: {
        name: this.form.value.timeZone.split(' ')[1],
        gmtOffset: this.form.value.timeZone
          .split(' ')[0]
          .replace('(UTC', '')
          .replace(')', ''),
      },
      language: this.form.value.language.key,
    };

    settings = { ...settings, ...additionlSettings };

    let payload: RequestData.UpdateUser = {
      update: {
        name: this.form.value.name,
        company: this.form.value.company,
        contact: this.form.value.contact,
        location: this.form.value.location,
        city: this.form.value.city,
        state: this.form.value.state,
        country: this.form.value.country,
        settings: settings,
      },
    };
    return payload;
  }

  //this function is used to get all the timezones in the world using moment js
  getTimeZones() {
    const timezones = this.customMomentService.moment.tz.names();
    this.timezonesWithOffsets = timezones.map((zone) => {
      const offset = this.customMomentService.moment.tz(zone).format('Z');
      return {
        name: zone,
        offSet: offset,
        nameOffSet: '(UTC' + offset + ')' + ' ' + zone,
      };
    });
    this.timezonesWithOffsets.sort((a: any, b: any) => {
      return this.parseOffset(a.offSet) - this.parseOffset(b.offSet);
    });
  }

  //this function is used to sort all the timezones in the ascending order based on their offset
  parseOffset(offset: string): number {
    const sign = offset[0] === '+' ? 1 : -1;
    const [hours, minutes] = offset.substring(1).split(':').map(Number);
    return sign * (hours * 60 + minutes);
  }

  //this function is used to handle the update button functionality
  onChangeForm() {
    let form = this.form.valueChanges.subscribe((res) => {
      if (res)
        //check if user has made any changes in the form or not
        this.hasValuesChangedProfileForm =
          JSON.stringify(res) !==
          JSON.stringify(this.originalValuesProfileForm);
      this.enableUpdateButton =
        this.hasValuesChangedProfileForm && this.form.valid;
    });

    const user: LoginData = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    );

    let info: Project.Info = user.info!;

    let logoForm = this.logoForm.valueChanges.subscribe((res) => {
      let darkModeUrl = Boolean(info.dark_mode_logo)
        ? info.dark_mode_logo
        : undefined;
      let defaultModeUrl = Boolean(info.logo_url) ? info.logo_url : undefined;

      if (
        this.darkModeUrl !== darkModeUrl ||
        this.defaultModeUrl !== defaultModeUrl
      ) {
        this.hasValuesChangedLogoForm = true;
      } else {
        this.hasValuesChangedLogoForm = false;
      }
    });

    this.subscriptions.push(form);
    this.subscriptions.push(logoForm);
  }

  //this function is used to handle the search event
  onChangeTimeZone() {
    this.filteredOptions = this.form.get('timeZone')?.valueChanges.pipe(
      startWith(''),
      map((value) => this.filter(value || ''))
    )!;
  }

  //it is used to filter out the timezones which matches with the user's search
  filter(value: string) {
    const filterValue = value.toLowerCase();
    return this.timezonesWithOffsets.filter((option: any) =>
      option.nameOffSet.toLowerCase().includes(filterValue)
    );
  }

  //executes when user wants to remove the selected image from the form while updating the form
  removeFile(mode: 'default' | 'dark') {
    if (mode === 'default') {
      this.defaultLogofile = null;
      this.defaultModeUrl = undefined;
      this.logoForm.controls.defaultLogo.patchValue(undefined);
    } else if (mode === 'dark') {
      this.darkModeLogoFile = null;
      this.darkModeUrl = undefined;
      this.logoForm.controls.darkLogo.patchValue(undefined);
    }
  }

  //generates payload for updating logo url
  generatePayloadForLogo(res: any) {
    const user: LoginData = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    );

    let info: Project.Info = user.info!;

    info.logo_url = res.default_mode;
    info.dark_mode_logo = res.dark_mode;

    let duplicateInfo = JSON.parse(JSON.stringify(info));

    delete duplicateInfo.max_api_calls;
    delete duplicateInfo.max_seats;
    delete duplicateInfo.max_sms_count;

    const payload: Project.Update = {
      update: {
        info: duplicateInfo,
      },
      updateUser: {},
      targetUserId: user.userId,
    };

    return payload;
  }

  //when user updates or adds logo
  submitLogo() {
    let user: LoginData = this.commonService.getUser();
    let info: Project.Info = user.info;

    let logoArray = [];
    let urlObject: Record<string, string> = {};

    //if url exists in user object than assign
    if (info.logo_url) urlObject['default_mode'] = info.logo_url;

    //if url exists in user object than assign
    if (info.dark_mode_logo) urlObject['dark_mode'] = info.dark_mode_logo;

    if (this.defaultLogofile) {
      logoArray.push(this.defaultLogofile);
      //reset the 'default_mode' key in urlObject to an empty string,
      // indicating that a new URL will be provided once the upload is complete.
      urlObject['default_mode'] = '';
    }

    if (this.darkModeLogoFile) {
      //reset the 'dark_mode' key in urlObject to an empty string,
      // indicating that a new URL will be provided once the upload is complete.
      logoArray.push(this.darkModeLogoFile);
      urlObject['dark_mode'] = '';
    }

    if (!logoArray.length) {
      let defaultModeUrl = Boolean(info.logo_url) ? info.logo_url : undefined;
      let darkModeUrl = Boolean(info.dark_mode_logo)
        ? info.dark_mode_logo
        : undefined;

      //if no url in this.defaultModeUrl but url present in info object means user want to delete logo
      if (this.defaultModeUrl !== defaultModeUrl) {
        urlObject['default_mode'] = '';
      }

      //if no url in this.darkModeUrl but url present in info object means user want to delete logo
      if (this.darkModeUrl !== darkModeUrl) {
        urlObject['dark_mode'] = '';
      }

      //if any of the condition is met than user has made changes related to logo, so calling api
      if (
        this.defaultModeUrl !== defaultModeUrl ||
        this.darkModeUrl !== darkModeUrl
      ) {
        this.updateOrganization(this.generatePayloadForLogo(urlObject));
      } else {
        setTimeout(() => {
          this.loadrService.removeLoader();
          this.formsService.closeForm();
        }, 1000);
      }

      return;
    }

    const apiCalls = logoArray.map((file) =>
      this.deviceService.generateLogoURL(file).pipe(
        catchError((err) => {
          console.info('Error:', err);
          this.loadrService.removeLoader();
          return of(null); // Return a null observable on error
        })
      )
    );

    forkJoin(apiCalls).subscribe({
      next: (responses: any) => {
        //if user has added both logo
        if (responses.length === 2) {
          urlObject.default_mode = responses[0].url;
          urlObject.dark_mode = responses[1].url;
        }

        //if user has added any one logo
        else if (responses.length === 1) {
          if (
            Object.keys(urlObject).includes('default_mode') &&
            urlObject['default_mode'] === ''
          ) {
            urlObject.default_mode = responses[0].url;
          } else if (
            Object.keys(urlObject).includes('dark_mode') &&
            urlObject['dark_mode'] === ''
          ) {
            urlObject.dark_mode = responses[0].url;
          }
        }

        //add the logo url in user
        this.updateOrganization(this.generatePayloadForLogo(urlObject));
      },
      error: (err) => {
        console.info('Error in forkJoin:', err);
        this.loadrService.removeLoader();
      },
    });
  }

  //update the organization info
  updateOrganization(payload: any) {
    const user: LoginData = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    );
    return this.projectsService
      .updateProject(user.userId, user.org, payload)
      .subscribe({
        next: (res: any) => {
          this.formsService.closeForm();
          this.notificationService.showSnackBar(
            'Profile updated successfully',
            'success'
          );
          this.getUpdatedOrganization();
        },
        error: (err) => {
          console.info('Error:', err);
          this.loadrService.removeLoader();
          this.notificationService.showSnackBar(err, 'error');
        },
      });
  }

  //get the updated organization info
  getUpdatedOrganization() {
    const user: LoginData = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    );
    this.projectsService.getMasterOrgInfo(user.userId).subscribe({
      next: (res) => {
        let updatedUser: LoginData = JSON.parse(JSON.stringify(user));
        // This assignment is necessary because other parts of the code directly access the 'info' object
        // instead of accessing it through the 'organization' object.
        updatedUser.info = res.info;
        updatedUser.organization.info = res.info;

        this.localStorageService.saveValue(
          LocalStorageConstants.OZ_USER,
          JSON.stringify(updatedUser)
        );
        this.userService.userProfileUpdate.next(true);
        this.loadrService.removeLoader();
      },
      error: (err) => {
        this.loadrService.removeLoader();
        console.info('Error:', err);
      },
    });
  }

  //to show the preview when user selects image
  onUpdateImg(image: any, mode: 'dark' | 'default') {
    var reader = new FileReader();
    try {
      if (mode === 'default') {
        this.updateImage = null;
        this.updateImage = <Array<File>>image;
        reader.readAsDataURL(this.updateImage);
        reader.onload = (e) => {
          // this.isEditLogo = false;
          this.defaultModeUrl = reader.result;
          this.logoForm.controls.defaultLogo.patchValue('change in file');
        };
      } else if (mode === 'dark') {
        this.updateImage = null;
        this.updateImage = <Array<File>>image;
        reader.readAsDataURL(this.updateImage);
        reader.onload = (e) => {
          // this.isEditLogo = false;
          this.darkModeUrl = reader.result;
          this.logoForm.controls.darkLogo.patchValue('change in file');
        };
      }
    } catch (e) {
      console.info(e);
    }
  }

  handleImageSelected(files: FileList | File, mode: 'dark' | 'default'): void {
    if (files instanceof File) {
      if (mode === 'default') {
        this.defaultLogofile = files;
        this.logoForm.controls.defaultLogo.patchValue(files.name);
      } else if (mode === 'dark') {
        this.darkModeLogoFile = files;
        this.logoForm.controls.darkLogo.patchValue(files.name);
      }
      this.onUpdateImg(files, mode);
    }
  }

  onNumberAdd(numbers: UserMobileNumbers) {
    if (numbers.validNumbers.length) {
      this.form.get('contact')?.setValue(numbers.validNumbers[0]);
    } else {
      this.form.get('contact')?.setValue(null);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
