<app-form-container
  [tableTitle]="isEditMode ? 'Edit Automation' : 'Automation setup'"
  [submitBtn]="isEditMode ? 'Update' : 'Submit'"
  (cancelled)="closeForm()"
  (close)="closeForm()"
  (submit)="submitUpdateForm()"
  [infoMsg]="
    'Automates external third-party device control with threshold-based settings, to trigger two channel relay switches based on the any two parameter thresholds. For e.g. It can activate or deactivate a dust suppression mist cannon based on PM2.5 and PM10 levels.'
  "
  [isValid]="automationForm.valid && isIntervalValid"
>
  <form class="row gy-4" [formGroup]="automationForm">
    <div class="col-12">
      <mat-form-field class="w-100">
        <mat-label>Device Name</mat-label>
        <mat-select formControlName="deviceName">
          <mat-optgroup
            *ngFor="let deviceType of deviceTypes"
            [label]="deviceType.label"
          >
            <mat-option
              *ngFor="let device of devices | deviceType : deviceType.key"
              [value]="device.deviceId"
            >
              {{ device.label }}
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-12">
      <b><mat-label>Output</mat-label></b>
      <mat-radio-group
        formControlName="channel"
        class="d-flex justify-content-between"
      >
        <mat-radio-button [value]="0" [color]="'primary'"
          >Output 1</mat-radio-button
        >
        <mat-radio-button [value]="1" [color]="'primary'"
          >Output 2</mat-radio-button
        >
        <mat-radio-button [disabled]="!isAQBOT" [value]="2" [color]="'primary'"
          >Buzzer</mat-radio-button
        >
        <!-- <mat-radio-button [value]="3" [color]="'primary'">Email</mat-radio-button> -->
      </mat-radio-group>
    </div>
    <div class="col-12">
      <mat-form-field class="w-100">
        <mat-label>Based On</mat-label>
        <mat-select formControlName="key">
          <ng-container *ngIf="this.selectedDevice.length > 0">
            <mat-option
              *ngFor="let field of selectedDevice[0]?.payload?.d | gasLevel"
              [value]="field.name"
            >
              {{ field.label }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-5 d-flex justify-content-between">
      <b><mat-label class="testClass">Default Status</mat-label></b>
      <mat-slide-toggle
        color="primary"
        formControlName="default"
        matTooltip="{{
          automationForm.get('default')?.value == true ? 'Enabled' : 'Disabled'
        }}"
      ></mat-slide-toggle>
    </div>
    <div class="col-12">
      <mat-form-field class="w-100">
        <mat-label>
          Interval (seconds)
          <mat-icon
            class="material-symbols-outlined small-icon"
            matTooltip="Enter the interval within which you want the device to check for parameter thresholds."
          >
            info
          </mat-icon>
        </mat-label>
        <input
          type="number"
          [placeholder]="'Interval (seconds)'"
          matInput
          formControlName="interval"
        />
      </mat-form-field>
      <mat-hint
        *ngIf="
          (automationForm?.get('interval'))!.value !== null &&
          (automationForm?.get('interval'))!.value !== '' &&
          isIntervalValid
        "
        >Interval must be Greater than {{ intervalLimit }}</mat-hint
      >
    </div>
    <!-- <div class="col-12 d-flex justify-content-end">
      <mat-slide-toggle
        color="primary"
        formControlName="lowerStatus"
      ></mat-slide-toggle>
    </div> -->
    <div
      class="w-100 d-flex flex-row align-items-center justify-content-between"
    >
      <div class="col-5">
        <mat-form-field class="w-100">
          <mat-label>{{ placeHolder }} Lower than Value</mat-label>
          <input
            type="number"
            [placeholder]="placeHolder + ' Lower than Value'"
            matInput
            formControlName="lowerThreshold"
          />
        </mat-form-field>
      </div>
      <mat-icon class="material-symbols-outlined arrow-icon">
        arrow_right_alt
      </mat-icon>
      <div class="col-5">
        <mat-form-field class="w-100">
          <mat-label>Relay Status</mat-label>
          <mat-select formControlName="lowerStatus">
            <ng-container>
              <mat-option
                *ngFor="let status of lower_status"
                [value]="status.value"
              >
                {{ status.label }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <!-- <div class="col-12 d-flex justify-content-end">
      <mat-slide-toggle
        color="primary"
        formControlName="upperStatus"
      ></mat-slide-toggle>
    </div> -->
    <div
      class="w-100 d-flex flex-row align-items-center justify-content-between"
    >
      <div class="col-5">
        <mat-form-field class="w-100">
          <mat-label>{{ placeHolder }} Greater than Value</mat-label>
          <input
            type="number"
            [placeholder]="placeHolder + ' Greater than Value'"
            matInput
            formControlName="upperThreshold"
          />
        </mat-form-field>
      </div>
      <mat-icon class="material-symbols-outlined arrow-icon">
        arrow_right_alt
      </mat-icon>
      <div class="col-5">
        <mat-form-field class="w-100">
          <mat-label>Relay Status</mat-label>
          <mat-select formControlName="upperStatus">
            <ng-container>
              <mat-option
                *ngFor="let status of upper_status"
                [value]="status.value"
              >
                {{ status.label }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>
</app-form-container>
