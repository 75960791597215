<div *ngIf="loadTable | async" class="d-flex flex-column h-100">
  <ng-container *ngIf="displays; let filteredDisplays">
    <app-data-table
      [tableTitle]="displayTitle"
      [data]="filteredDisplays"
      [columns]="defaultColumns"
      [displayedColumns]="displayedColumns"
      (onDownload)="exportTableDataToCSV(filteredDisplays)"
      [showFilterInHeader]="false"
      [noDataViewConfig]="noData"
    >
    </app-data-table>
  </ng-container>
</div>
<div class="py-5" *ngIf="(loadTable | async) == false">
  <mat-spinner class="mx-auto" [diameter]="50" color="primary"></mat-spinner>
</div>
