import { AppConstants } from '../constants/app-constants';
import { DeviceConstants } from '../constants/device-constants';
import { AQIIndex } from '../models/aqi-index/aqi-index';
import { AqiIndexColorArray } from '../models/aqi-index/aqi-index-color-array';
import { BreakPointRange } from '../models/aqi-index/breakpoint-range';
import { DeviceType } from '../models/device-type/device-type';
import { DeviceDetails } from '../models/device/device-details';
import { DeviceField } from '../models/device/device-field';
import { DevicePayload } from '../models/device/device-payload';
import { FieldLimit } from '../models/device/field-limit';
import { Unit } from '../models/unit';
import { CommonUtil } from './common-utils';

export class DeviceUtil {
  static flagCategories = [
    { label: 'Corrected', value: -1, color: '#6ecc58' },
    { label: 'Failure', value: -2, color: '#eac736' },
    { label: 'Invalid', value: -3, color: '#ed9a2e' },
    { label: 'Maintenance', value: -4, color: '#d63636' },
    { label: 'Out Of Range', value: -5, color: '#8f3f97' },
    { label: 'Calibration', value: -6, color: '#7e0023' },
  ];

  public static findDeviceType(
    deviceType: string | number,
    types: Array<DeviceType>
  ): any {
    try {
      return typeof deviceType === 'number'
        ? types.find((type) => type.deviceTypeId === deviceType) || {}
        : types.find((type) => type.key === deviceType) || {};
    } catch (exception) {
      console.warn('Failed to fetch device types.', exception);
      return {};
    }
  }

  public static getDeviceTypeKeyByDeviceTypeId(
    deviceTypes: Array<DeviceType>,
    deviceTypeId: number
  ): string {
    const deviceType = deviceTypes?.find(
      (deviceType: DeviceType) => deviceType.deviceTypeId === deviceTypeId
    );

    return deviceType?.key || '';
  }

  public static hasValidIndexForDeviceType(
    deviceDetail: DeviceDetails,
    deviceTypes: Array<DeviceType>,
    deviceTypeId?: string | number
  ): boolean {
    if (!deviceTypeId && deviceDetail) {
      const typeId = this.getDeviceTypeId(deviceDetail.deviceType, deviceTypes);
      return (
        deviceTypes?.find((type) => type.deviceTypeId === typeId)?.index ||
        false
      );
    } else {
      return (
        deviceTypes?.find((type) => type.deviceTypeId === deviceTypeId)
          ?.index || false
      );
    }
  }

  public static getIndexForDeviceType(
    deviceType: string | number,
    deviceTypes: Array<DeviceType>,
    allAqis: Record<string, AQIIndex>
  ): number {
    const typeId = this.getDeviceTypeId(deviceType, deviceTypes) ?? 1001;
    return allAqis[typeId]?.id ?? allAqis[typeId]?.aqiIndexId ?? 1001;
  }

  public static getDeviceTypeId(
    deviceType: string | number,
    deviceTypes: Array<DeviceType>
  ): number | undefined {
    if (typeof deviceType !== 'string') {
      return deviceType;
    }
    return (
      deviceTypes?.find((type) => type?.key === deviceType)?.deviceTypeId ||
      undefined
    );
  }

  public static getDeviceTypeByKeyOrId(
    deviceType: string | number,
    deviceTypes: Array<DeviceType>
  ) {
    if (typeof deviceType !== 'string') {
      return deviceTypes?.find((type) => type.deviceTypeId === deviceType);
    }
    return deviceTypes?.find((type) => type.key === deviceType);
  }

  static getBreakpoints(
    aqiIndex: any,
    deviceType: string | number | undefined,
    deviceTypes: Array<DeviceType>,
    allAqi: any,
    allAqis: any,
    aqi: any
  ): any {
    if (deviceType !== undefined && typeof deviceType === 'string') {
      deviceType = this.getDeviceTypeId(deviceType, deviceTypes);
      if (deviceType === null || typeof deviceType !== 'number') {
        deviceType = undefined;
      }
    }

    if (deviceType) {
      if (aqiIndex) {
        try {
          if (allAqi && allAqi[deviceType] && allAqi[deviceType][aqiIndex]) {
            return allAqi[deviceType][aqiIndex]?.breakpoints || undefined;
          } else {
            console.info('ALL AQI data not found.');
            this.getBreakpoints(
              undefined,
              deviceType,
              deviceTypes,
              allAqi,
              allAqis,
              aqi
            );
          }
        } catch (exception) {
          console.info('Error fetching breakpoints.', exception);
          this.getBreakpoints(
            undefined,
            deviceType,
            deviceTypes,
            allAqi,
            allAqis,
            aqi
          );
        }
      } else {
        try {
          if (allAqis && allAqis[deviceType]) {
            return allAqis[deviceType]?.breakpoints || undefined;
          }
        } catch (exception) {
          console.info('Error fetching breakpoints.', exception);
        }
      }
    } else {
      try {
        if (aqi) {
          return aqi.breakpoints || undefined;
        }
      } catch (exception) {
        console.info('Error fetching breakpoints.', exception);
      }
    }
  }

  static calculateAQIForSingleParameter(
    key: string,
    value: number,
    breakpoints: any
  ) {
    let result = 0,
      t = '';
    try {
      if (value < 0) {
        result = 0;
        throw new Error(`Value can't be less than 0 for ${key}.`);
      }
      if (breakpoints.ranges.length === 0) {
        result = 0;
        throw new Error(`No ranges found for ${key}.`);
      }
      let convertedValue = value * breakpoints.c_factore;
      if (
        convertedValue >
        breakpoints.ranges[breakpoints.ranges.length - 1].breakPoint.hig
      ) {
        result = breakpoints.ranges[breakpoints.ranges.length - 1].index.hig;
        throw new Error(`Value is greater than highest range for ${key}.`);
      }
      if (convertedValue < breakpoints.ranges[0].breakPoint.low) {
        result = breakpoints.ranges[0].index.low;
        throw new Error(`Value is less than lowest range for ${key}.`);
      }
      let range = breakpoints.ranges.find((range: BreakPointRange) => {
        return (
          convertedValue >= range.breakPoint.low &&
          convertedValue <= range.breakPoint.hig
        );
      });

      if (!range) {
        result = 0;
        throw new Error(`Range not found for ${key}.`);
      }

      result = Math.round(
        ((range.index.hig - range.index.low) /
          (range.breakPoint.hig - range.breakPoint.low)) *
          (convertedValue - range.breakPoint.low) +
          range.index.low
      );
      t = `((${range.index.hig} - ${range.index.low}) / (${range.breakPoint.hig} - ${range.breakPoint.low})) * (${convertedValue} - ${range.breakPoint.low}) + ${range.index.low}`;
    } catch (error) {
      console.log('error ->', error);
    }

    return result;
  }

  static calculateAQI(payload: DevicePayload, breakpoints: any) {
    let aqi: { aqi: number | null; aqiKey: string; aqiMessage: string } = {
      aqi: 0,
      aqiKey: '',
      aqiMessage: '',
    };
    let dirty = false;

    if (breakpoints && breakpoints.hasOwnProperty('eq')) {
      if (breakpoints['eq'] === 'aqhi') {
        dirty = true;
        let g5 = 0,
          g3 = 0,
          p1 = 0;

        if (payload.d.hasOwnProperty('g5')) {
          g5 = Math.exp(0.000537 * payload.d['g5']) - 1;
        }
        if (payload.d.hasOwnProperty('g3')) {
          g3 = Math.exp(0.000871 * payload.d['g3']) - 1;
        }
        if (payload.d.hasOwnProperty('p1')) {
          p1 = Math.exp(0.000487 * payload.d['p1']) - 1;
        }

        aqi.aqi = parseFloat(((1000 / 10.4) * (g5 + g3 + p1)).toFixed(2));

        const filteredRange = breakpoints.ranges.filter(
          (range: BreakPointRange) => {
            return (
              aqi.aqi! <= range.breakPoint.hig &&
              aqi.aqi! >= range.breakPoint.low
            );
          }
        );

        const finalRange =
          filteredRange.length > 0
            ? filteredRange[0]
            : breakpoints.ranges[breakpoints.ranges.length - 1];
        aqi.aqiMessage = finalRange.message;
      }
    } else {
      if (breakpoints) {
        let relevantParamsForAQI = Object.keys(payload.d).filter((param) =>
          Object.keys(breakpoints).includes(param)
        );

        relevantParamsForAQI.forEach((param) => {
          payload.d[`${param}aqi`] = this.calculateAQIForSingleParameter(
            param,
            parseFloat(payload.d[param]),
            breakpoints[param]
          );
        });

        if (
          !(
            relevantParamsForAQI.length >= 3 &&
            ['p1', 'p2'].some((param) =>
              relevantParamsForAQI.includes(param)
            ) &&
            relevantParamsForAQI.filter((param) => !/^p.*$/.test(param))
          )
        ) {
          return { aqi: null, aqiKey: '', aqiMessage: '' } as any;
        }
      }

      for (let key in breakpoints) {
        if (payload?.d && payload?.d[key]) {
          dirty = true;
          const ranges = breakpoints[key].ranges;
          let concentration = parseFloat(
            (payload.d[key] * breakpoints[key].c_factore).toFixed(2)
          );

          if (!['temp', 't1'].includes(key) && concentration < 0) {
            concentration = 0;
          }

          const filteredRange = ranges.filter((range: BreakPointRange) => {
            return (
              concentration <= range.breakPoint.hig &&
              concentration >= range.breakPoint.low
            );
          });
          const finalRange =
            filteredRange.length > 0
              ? filteredRange[0]
              : ranges[ranges.length - 1];
          const currentAqi =
            ((finalRange.index.hig - finalRange.index.low) /
              (finalRange.breakPoint.hig - finalRange.breakPoint.low)) *
              (concentration - finalRange.breakPoint.low) +
            finalRange.index.low;

          if (aqi.aqi! < currentAqi) {
            aqi.aqi = parseInt(currentAqi);
            aqi.aqiKey = key;
            aqi.aqiMessage = finalRange.message;
          }
        }
      }
    }
    if (dirty === false) {
      aqi = { aqi: null, aqiKey: '', aqiMessage: '' };
    }
    return aqi;
  }

  static convertUnits(
    payload: DevicePayload,
    units: any,
    calibration?: boolean,
    roundValues: boolean = true
  ): any {
    calibration === undefined ? (calibration = false) : calibration;

    for (const data in payload.d) {
      if (units && units[data]) {
        if (data !== 'aqiMessage') {
          if (units[data].c_factore == -1) {
            if (roundValues) {
              payload.d[data] =
                Math.round((payload.d[data] * 1.8 + 32) * 1e2) / 1e2;
            } else {
              payload.d[data] = ((payload.d[data] * 1.8 + 32) * 1e2) / 1e2;
            }
          } else {
            if (roundValues) {
              payload.d[data] =
                Math.round(payload.d[data] * units[data].c_factore * 1e2) / 1e2;
            } else {
              payload.d[data] =
                (payload.d[data] * units[data].c_factore * 1e2) / 1e2;
            }
          }
        }
        if (
          !calibration &&
          !DeviceConstants.KEYS_TO_IGNORE.includes(data) &&
          payload.d[data] < 0
        ) {
          payload.d[data] = 0;
        }
      }
    }
    return payload;
  }

  static getAllParams(
    registeredDevices: any[] | undefined,
    deviceTypes: Array<DeviceType>
  ): any {
    if (
      registeredDevices === undefined ||
      registeredDevices === null ||
      registeredDevices.length === 0
    ) {
      return;
    }

    const params: Record<any, any> = {};
    registeredDevices.forEach((registeredDevice) => {
      if (registeredDevice.payload) {
        let deviceTypeId = this.getDeviceTypeId(
          registeredDevice.deviceType,
          deviceTypes
        )!;
        if (!params[deviceTypeId]) {
          params[deviceTypeId] = [];
        }
        Object.keys(registeredDevice.payload.d).forEach((field: string) => {
          if (!params[deviceTypeId].includes(field)) {
            params[deviceTypeId].push(field);
          }
        });
      }
    });

    return params;
  }

  static getDeviceLabel(devices: DeviceDetails[], deviceId: string): string {
    return devices.find((device) => device.deviceId === deviceId)?.label || '';
  }

  static getFieldsByDeviceType(
    deviceType: number | string,
    allUnits: any,
    deviceTypes?: Array<DeviceType> | undefined
  ): DeviceField[] {
    if (deviceType !== undefined && typeof deviceType === 'string') {
      if (deviceTypes?.length) {
        deviceType = this.getDeviceTypeId(deviceType, deviceTypes) ?? -1;
      } else {
        deviceType = -1;
      }
    }
    if (deviceType === -1) {
      return [];
    } else {
      return (Object.values(allUnits[deviceType]) as Array<Unit>).map(
        (unit) => {
          return {
            fkey: unit.key,
            label: unit.flabel,
            unit: unit.label,
            cFactore: unit.c_factore,
            isVisible: unit.isVisible,
          };
        }
      );
    }
  }

  static getFieldName(
    ref: string,
    fields: DeviceField[],
    units?: any,
    gasData?: any
  ): string {
    if (units && !CommonUtil.isEmpty(units)) {
      if (gasData?.length > 0) {
        fields.forEach((field) => {
          field.isVisible = gasData?.includes(field.fkey) || false;
        });
      }

      const unit = units[ref];

      return unit && unit.isVisible ? unit.label : ref.toUpperCase();
    } else {
      if (!fields) {
        return 'N/A';
      }
      if (gasData?.length > 0) {
        fields.forEach((field) => {
          field.isVisible = gasData?.includes(field.fkey) || false;
        });
      }
      return (
        fields?.find(
          (field: DeviceField) => field.isVisible && field.fkey === ref
        )?.label || ref.toUpperCase()
      );
    }
  }

  static getFieldUnit(
    ref: string,
    fields?: DeviceField[],
    units?: any
  ): string {
    if (units) {
      const unit = units[ref];
      return unit && unit.isVisible ? unit.label : ref.toUpperCase();
    } else {
      if (!fields) {
        return 'N/A';
      }
      return (
        fields?.find(
          (field: DeviceField) => field.isVisible && field.fkey === ref
        )?.unit || ref.toUpperCase()
      );
    }
  }

  static getCFactoreData(
    ref: string,
    val: string,
    fields: DeviceField[]
  ): string {
    if (!fields) {
      return 'N/A';
    }
    const field = fields?.find(
      (field: DeviceField) => field.isVisible && field.fkey === ref
    );
    if (!field) {
      return 'N/A';
    }
    if (field.cFactore == -1) {
      return (parseInt(val, 10) * 1.8 + 32).toFixed(2).toString();
    } else {
      return (parseInt(val, 10) * field.cFactore).toFixed(2).toString();
    }
  }

  static getColorForPin(
    device: DeviceDetails,
    deviceType: DeviceType,
    showAQI: boolean,
    mqttDocs: any,
    currentTimeStamp: number,
    showAqiGauge: boolean,
    ozMapPinColor: string,
    isMapPinFixed: boolean,
    deviceTypes: DeviceType[],
    allAqi: Record<string, Record<number, AQIIndex>>,
    allAqis: Record<string, AQIIndex>,
    ozAqi: AQIIndex,
    pinSelected: boolean = false
  ): void {
    if (pinSelected) {
      if (AppConstants.OZ_MAP_PIN_SELECTED.get(deviceType.deviceTypeId)) {
        deviceType.icon = AppConstants.OZ_MAP_PIN_SELECTED.get(
          deviceType.deviceTypeId
        );
      } else {
        deviceType.icon = AppConstants.DEFAULT_DEVICE_ICON_SELECTED;
      }

      if (!deviceType.icon) {
        deviceType.icon = AppConstants.DEFAULT_DEVICE_ICON_SELECTED;
      }
    } else {
      if (AppConstants.OZ_MAP_PIN.get(deviceType.deviceTypeId)) {
        deviceType.icon = AppConstants.OZ_MAP_PIN.get(deviceType.deviceTypeId);
      } else {
        deviceType.icon = AppConstants.DEFAULT_DEVICE_ICON;
      }

      if (!deviceType.icon) {
        deviceType.icon = AppConstants.DEFAULT_DEVICE_ICON;
      }
    }

    const iconTemplate = [deviceType.icon].join('\n');
    let color = '#808080';
    let statusColor = '';
    let status = DeviceConstants.OFFLINE_STATUS;
    let icon = '';
    let index = -1;

    if (showAQI === true && showAqiGauge === true) {
      const aqi = mqttDocs[device.deviceId].aqi;
      let index: number = -1;
      if (aqi !== undefined) {
        const aqiColorArray = this.aqiColorArray(
          deviceTypes,
          allAqi,
          allAqis,
          undefined,
          deviceType.deviceTypeId
        );
        for (index = 0; index < aqiColorArray.limits.length; index++) {
          if (aqi === null) {
            break;
          }
          if (Math.min(aqi, aqiColorArray.limits[index]) === aqi) {
            break;
          }
        }
      }

      index--;

      if (aqi === 0) {
        index = 0;
      }

      if (aqi === null) {
        if (device.payload?.d?.t < new Date().getTime() / 1000 - 3600) {
          index = -1;
        } else {
          if (device.payload?.d?.t) {
            index = -1;
          } else {
            index = 0;
          }
        }
      }

      try {
        switch (index) {
          case index:
            try {
              const aqiIndexColor = this.getAQIDetails(
                undefined,
                device.deviceType,
                deviceTypes,
                allAqi,
                allAqis,
                ozAqi
              )?.colors[index].split('#');
              color = '#' + aqiIndexColor ? aqiIndexColor![1] : '808080';
            } catch (error) {
              color = '#808080';
            }
            break;
          case -1:
            color = '#808080';
            break;
          default:
            color = '#808080';
            break;
        }
      } catch (error) {
        console.info('Error fetching pin', error);
      }
    } else {
      if (device.payload?.d?.t) {
        if (device.payload.d.t > currentTimeStamp - 60 * 60) {
          color = '#3DB79C';
        } else if (device.payload.d.t > currentTimeStamp - 24 * 60 * 60) {
          color = '#fcdc01';
        } else {
          color = '#d3d3d3';
        }
      } else {
        color = '#d3d3d3';
      }
    }

    if (isMapPinFixed) {
      color = ozMapPinColor;
    }

    if (device.payload?.d?.t) {
      if (device.payload.d.t > currentTimeStamp - 60 * 60) {
        statusColor = '#4CAF50';
        status = DeviceConstants.ONLINE_STATUS;
        icon = 'done';
        index = 1;
      } else if (device.payload.d.t > currentTimeStamp - 24 * 60 * 60) {
        statusColor = '#fcdc01';
        status = DeviceConstants.NOT_CONNECTED_STATUS;
        icon = 'exclamation';
        index = 2;
      } else {
        statusColor = '#D32F2F';
        status = DeviceConstants.OFFLINE_STATUS;
        icon = 'exclamation';
        index = 2;
      }
    } else {
      statusColor = '#D32F2F';
      status = DeviceConstants.OFFLINE_STATUS;
      icon = 'exclamation';
      index = 2;
    }

    device.status = {
      color: statusColor,
      msg: status,
      icon: icon,
      index: index,
    };

    let svg = iconTemplate.replaceAll('{{oz_color}}', color);

    // if (device.deviceType === DeviceConstants.CPCB_DEVICE_TYPE) {
    //   device.iconUrlModal =
    //     DeviceConstants.THIRD_PARTY_DEVICE_PIN_ASSET_URL_MODAL;
    //   device.iconUrl = DeviceConstants.THIRD_PARTY_DEVICE_PIN_ASSET_URL;
    // } else {
    // }
    device.iconUrl = AppConstants.SVG_PREFIX + encodeURIComponent(svg);

    let devAqi: string;

    if (device.aqi) {
      devAqi = device.aqi.toString();
    } else {
      devAqi = ' ';
    }

    device.labelOptions = {
      color: '#FFFFF',
      fontFamily: 'monospace',
      fontSize: '13px',
      fontWeight: 'bolder',
      text: devAqi,
    };
  }

  static getColorForPinCluster(
    device: DeviceDetails,
    deviceType: DeviceType,
    showAQI: boolean,
    mqttDocs: any,
    currentTimeStamp: number,
    showAqiGauge: boolean,
    ozMapPinColor: string,
    isMapPinFixed: boolean,
    deviceTypes: DeviceType[],
    allAqi: Record<string, Record<number, AQIIndex>>,
    allAqis: Record<string, AQIIndex>,
    ozAqi: AQIIndex,
    pinSelected: boolean = false
  ): void {
    if (pinSelected) {
      if (
        AppConstants.OZ_CLUSTER_MAP_PIN_SELECTED.get(deviceType.deviceTypeId)
      ) {
        deviceType.icon = AppConstants.OZ_CLUSTER_MAP_PIN_SELECTED.get(
          deviceType.deviceTypeId
        );
      } else {
        deviceType.icon = AppConstants.DEFAULT_CLUSTER_DEVICE_ICON_SELECTED;
      }

      if (!deviceType.icon) {
        deviceType.icon = AppConstants.DEFAULT_CLUSTER_DEVICE_ICON_SELECTED;
      }
    } else {
      if (AppConstants.OZ_CLUSTER_MAP_PIN.get(deviceType.deviceTypeId)) {
        deviceType.icon = AppConstants.OZ_CLUSTER_MAP_PIN.get(
          deviceType.deviceTypeId
        );
      } else {
        deviceType.icon = AppConstants.DEFAULT_CLUSTER_DEVICE_ICON;
      }

      if (!deviceType.icon) {
        deviceType.icon = AppConstants.DEFAULT_CLUSTER_DEVICE_ICON;
      }
    }

    const iconTemplate = [deviceType.icon].join('\n');
    let color = '#808080';
    let statusColor = '';
    let status = DeviceConstants.OFFLINE_STATUS;
    let icon = '';
    let index = -1;

    if (showAQI === true && showAqiGauge === true) {
      const aqi = mqttDocs[device.deviceId].aqi;
      let index: number = -1;
      if (aqi !== undefined) {
        const aqiColorArray = this.aqiColorArray(
          deviceTypes,
          allAqi,
          allAqis,
          undefined,
          deviceType.deviceTypeId
        );
        for (index = 0; index < aqiColorArray.limits.length; index++) {
          if (aqi === null) {
            break;
          }
          if (Math.min(aqi, aqiColorArray.limits[index]) === aqi) {
            break;
          }
        }
      }

      index--;

      if (aqi === 0) {
        index = 0;
      }

      if (aqi === null) {
        if (device.payload?.d?.t < new Date().getTime() / 1000 - 3600) {
          index = -1;
        } else {
          if (device.payload?.d?.t) {
            index = -1;
          } else {
            index = 0;
          }
        }
      }

      try {
        switch (index) {
          case index:
            try {
              const aqiIndexColor = this.getAQIDetails(
                undefined,
                device.deviceType,
                deviceTypes,
                allAqi,
                allAqis,
                ozAqi
              )?.colors[index].split('#');
              color = '#' + aqiIndexColor ? aqiIndexColor![1] : '808080';
            } catch (error) {
              color = '#808080';
            }
            break;
          case -1:
            color = '#808080';
            break;
          default:
            color = '#808080';
            break;
        }
      } catch (error) {
        console.info('Error fetching pin', error);
      }
    } else {
      if (device.payload?.d?.t) {
        if (device.payload.d.t > currentTimeStamp - 60 * 60) {
          color = '#3DB79C';
        } else if (device.payload.d.t > currentTimeStamp - 24 * 60 * 60) {
          color = '#fcdc01';
        } else {
          color = '#d3d3d3';
        }
      } else {
        color = '#d3d3d3';
      }
    }

    if (isMapPinFixed) {
      color = ozMapPinColor;
    }

    if (device.payload?.d?.t) {
      if (device.payload.d.t > currentTimeStamp - 60 * 60) {
        statusColor = '#4CAF50';
        status = DeviceConstants.ONLINE_STATUS;
        icon = 'done';
        index = 1;
      } else if (device.payload.d.t > currentTimeStamp - 24 * 60 * 60) {
        statusColor = '#fcdc01';
        status = DeviceConstants.NOT_CONNECTED_STATUS;
        icon = 'exclamation';
        index = 2;
      } else {
        statusColor = '#D32F2F';
        status = DeviceConstants.OFFLINE_STATUS;
        icon = 'exclamation';
        index = 2;
      }
    } else {
      statusColor = '#D32F2F';
      status = DeviceConstants.OFFLINE_STATUS;
      icon = 'exclamation';
      index = 2;
    }

    device.status = {
      color: statusColor,
      msg: status,
      icon: icon,
      index: index,
    };

    let svg = iconTemplate.replaceAll('{{oz_color}}', color);

    // if (device.deviceType === DeviceConstants.CPCB_DEVICE_TYPE) {
    //   device.iconUrlModal =
    //     DeviceConstants.THIRD_PARTY_DEVICE_PIN_ASSET_URL_MODAL;
    //   device.iconUrl = DeviceConstants.THIRD_PARTY_DEVICE_PIN_ASSET_URL;
    // } else {
    // }
    device.iconUrl = AppConstants.SVG_PREFIX + encodeURIComponent(svg);

    let devAqi: string;

    if (device.aqi) {
      devAqi = device.aqi.toString();
    } else {
      devAqi = ' ';
    }

    device.labelOptions = {
      color: '#FFFFF',
      fontFamily: 'monospace',
      fontSize: '10px',
      fontWeight: 'bolder',
      text: devAqi,
    };
  }

  static aqiColorArray(
    deviceTypes: DeviceType[],
    allAqi: Record<string, Record<number, AQIIndex>>,
    allAqis: Record<string, AQIIndex>,
    aqiIndex?: number | undefined,
    deviceType?: any,
    type?: string
  ): AqiIndexColorArray {
    if (deviceType !== undefined && typeof deviceType === 'string') {
      deviceType = DeviceUtil.getDeviceTypeId(deviceType, deviceTypes!);
      if (deviceType === null || typeof deviceType !== 'number') {
        deviceType = undefined;
      }
    }

    if (deviceType && !aqiIndex) {
      aqiIndex = this.getIndexForDeviceType(deviceType, deviceTypes, allAqis);
    }

    let aqiColorIndex: AqiIndexColorArray = {
      limits: [],
      color: [],
      outOfRangeColor: '',
      labels: [],
      outOfRangeMessage: '',
    };

    if (
      deviceType &&
      aqiIndex &&
      allAqi![deviceType] &&
      allAqi![deviceType][aqiIndex].index
    ) {
      if (CommonUtil.isEmpty(allAqi![deviceType][aqiIndex].index)) {
        aqiColorIndex = {
          limits: [],
          color: [],
          outOfRangeColor: '',
          labels: [],
          outOfRangeMessage: '',
        };
      } else {
        try {
          const aqiData = allAqi![deviceType][aqiIndex];
          if (aqiData) {
            aqiColorIndex.limits =
              aqiData?.index?.range !== undefined
                ? aqiData?.index?.range
                : type == 'Cluster'
                ? []
                : DeviceConstants.AQI_DEFAULT_LIMTIS;
            aqiColorIndex.color =
              aqiData?.index?.colors !== undefined &&
              aqiData?.index?.colors?.length > 0
                ? aqiData?.index?.colors
                : type == 'Cluster'
                ? []
                : DeviceConstants.AQI_DEFAULT_COLORS;
            aqiColorIndex.labels =
              aqiData?.index?.names !== undefined &&
              aqiData?.index?.names?.length > 0
                ? aqiData?.index?.names
                : type == 'Cluster'
                ? []
                : DeviceConstants.AQI_DEFAULT_LABELS;
            aqiColorIndex.outOfRangeColor =
              aqiData?.index?.colors !== undefined &&
              aqiData?.index?.colors?.length > 0
                ? aqiData?.index?.colors[aqiData?.index?.colors?.length - 1]
                : type == 'Cluster'
                ? ''
                : DeviceConstants.AQI_DEFAULT_OUT_OF_RANGE_COLOR;
            aqiColorIndex.outOfRangeMessage =
              aqiData?.index?.names !== undefined &&
              aqiData?.index?.names?.length > 0
                ? aqiData?.index?.names[aqiData?.index?.names?.length - 1]
                : type == 'Cluster'
                ? ''
                : DeviceConstants.AQI_DEFAULT_OUT_OF_RANGE_MESSAGE;
          }
        } catch (error) {
          aqiColorIndex = {
            limits: type == 'Cluster' ? [] : DeviceConstants.AQI_DEFAULT_LIMTIS,
            color: type == 'Cluster' ? [] : DeviceConstants.AQI_DEFAULT_COLORS,
            outOfRangeColor:
              type == 'Cluster'
                ? ''
                : DeviceConstants.AQI_DEFAULT_OUT_OF_RANGE_COLOR,
            labels: type == 'Cluster' ? [] : DeviceConstants.AQI_DEFAULT_LABELS,
            outOfRangeMessage:
              type == 'Cluster'
                ? ''
                : DeviceConstants.AQI_DEFAULT_OUT_OF_RANGE_MESSAGE,
          };
          console.info(error);
        }
      }
      return aqiColorIndex;
    } else {
      if (allAqis && !CommonUtil.isEmpty(allAqis)) {
        try {
          if (allAqis) {
            aqiColorIndex.limits =
              allAqis['1001']?.index.range !== undefined &&
              allAqis['1001']?.index.range.length > 0
                ? allAqis['1001'].index.range
                : [];
            aqiColorIndex.color =
              allAqis['1001']?.index.colors !== undefined &&
              allAqis['1001']?.index.colors.length > 0
                ? allAqis['1001'].index.colors
                : [];
            aqiColorIndex.labels =
              allAqis['1001']?.index.names !== undefined &&
              allAqis['1001']?.index.names.length > 0
                ? allAqis['1001'].index.names
                : [];
            aqiColorIndex.outOfRangeColor =
              allAqis['1001'].index.colors !== undefined &&
              allAqis['1001'].index.colors.length > 0
                ? allAqis['1001'].index.colors[
                    allAqis['1001'].index.colors.length - 1
                  ]
                : '';
            aqiColorIndex.outOfRangeMessage =
              allAqis['1001'].index.names !== undefined &&
              allAqis['1001'].index.names.length > 0
                ? allAqis['1001'].index.names[
                    allAqis['1001'].index.names.length - 1
                  ]
                : '';
          }
        } catch (error) {
          aqiColorIndex = {
            limits: [],
            color: [],
            outOfRangeColor: '',
            labels: [],
            outOfRangeMessage: '',
          };
          console.info(error);
        }
      }
      return aqiColorIndex;
    }
  }

  static getAQIDetails(
    aqiIndex: number | undefined,
    deviceType: string | number,
    deviceTypes: DeviceType[],
    allAqi: Record<string, Record<number, AQIIndex>>,
    allAqis: Record<string, AQIIndex>,
    aqi: AQIIndex
  ) {
    if (deviceType) {
      const deviceTypeId = DeviceUtil.getDeviceTypeId(deviceType, deviceTypes)!;
      if (aqiIndex) {
        if (allAqi[deviceTypeId] && allAqi[deviceTypeId][aqiIndex]) {
          try {
            const aqiData = allAqi[deviceTypeId][aqiIndex];
            if (aqiData) {
              return aqiData.index !== undefined ? aqiData.index : undefined;
            } else {
              this.getAQIDetails(
                undefined,
                deviceType,
                deviceTypes,
                allAqi,
                allAqis,
                aqi
              );
            }
          } catch (error) {
            this.getAQIDetails(
              undefined,
              deviceType,
              deviceTypes,
              allAqi,
              allAqis,
              aqi
            );
            console.info('Error getting color code.', error);
          }
        } else {
          this.getAQIDetails(
            undefined,
            deviceType,
            deviceTypes,
            allAqi,
            allAqis,
            aqi
          );
        }
      } else {
        try {
          if (allAqis && allAqis[deviceTypeId]) {
            return allAqis[deviceTypeId]?.index;
          }
        } catch (error) {
          console.info('Error getting color code.', error);
          return undefined;
        }
      }
    } else {
      return aqi?.index;
    }

    return undefined;
  }

  static getParamColor(
    limitClass: string,
    deviceTypes: DeviceType[],
    allAqi: Record<string, Record<number, AQIIndex>>,
    allAqis: Record<string, AQIIndex>,
    aqiIndex?: number,
    deviceType?: any,
    type?: string
  ) {
    if (deviceType !== undefined && typeof deviceType === 'string') {
      deviceType = this.getDeviceTypeId(deviceType, deviceTypes!);
      if (deviceType === null || typeof deviceType !== 'number') {
        deviceType = undefined;
      }
    }

    if (limitClass === undefined) {
      return;
    }

    try {
      let aqiColorArray = this.aqiColorArray(
        deviceTypes,
        allAqi,
        allAqis,
        aqiIndex,
        deviceType,
        type
      );
      if (deviceType) {
        if (aqiIndex) {
          const no = limitClass.split('l');
          if ((aqiColorArray.color && no[1] !== undefined) || no[1] !== '') {
            if (aqiColorArray.color[parseInt(no[1], 10)] === undefined) {
              return;
            }
            const num = parseInt(no[1], 10);
            return aqiColorArray.color[num];
          }
        } else {
          const no = limitClass.split('l');
          if ((aqiColorArray.color && no[1] !== undefined) || no[1] !== '') {
            if (aqiColorArray.color[parseInt(no[1], 10)] === undefined) {
              return;
            }
            const num = parseInt(no[1], 10);
            return aqiColorArray.color[num];
          }
        }
      } else {
        if (aqiIndex) {
          const no = limitClass.split('l');
          if ((aqiColorArray.color && no[1] !== undefined) || no[1] !== '') {
            if (aqiColorArray.color[parseInt(no[1], 10)] === undefined) {
              return;
            }
            const num = parseInt(no[1], 10);
            return aqiColorArray.color[num];
          }
        } else {
          const no = limitClass.split('l');
          if ((aqiColorArray.color && no[1] !== undefined) || no[1] !== '') {
            if (aqiColorArray.color[parseInt(no[1], 10)] === undefined) {
              return;
            }
            const num = parseInt(no[1], 10);
            return aqiColorArray.color[num];
          }
        }
      }
    } catch (exception) {}
  }

  static getLimitClass(
    fkey: string,
    value: number,
    limits: FieldLimit[],
    units?: Unit[]
  ): string {
    if (units && !CommonUtil.isEmpty(units)) {
      const unit = units?.find((unit) => unit?.key === fkey);
      if (!unit || !unit.limit.length) {
        return '';
      }
      let i: number;
      for (i = 1; i < unit.limit.length; i++) {
        if (Math.min(value, unit.limit[i]) === value) {
          break;
        }
      }
      i--;
      return 'l' + i;
    } else {
      if (!limits) {
        return '';
      }
      const limit = limits?.find((limit) => limit?.fkey === fkey);
      if (!limit || !limit?.range?.length) {
        return '';
      }
      let i: number;
      for (i = 1; i < limit?.range?.length; i++) {
        if (Math.min(value, limit?.range[i]) === value) {
          break;
        }
      }
      i--;
      return 'l' + i;
    }
  }
  static getLimitClass2(
    fkey: string,
    value: number,
    limits: any,
    units?: Unit[]
  ): string {
    if (limits == 1004) {
      return '';
    }

    const limit = limits[fkey];
    if (!limit || limit?.limit?.length === 0) {
      return '';
    }

    let i: number;
    for (i = 1; i < limit?.limit?.length; i++) {
      if (Math.min(value, limit.limit[i]) === value) {
        break;
      }
    }

    i--;
    return 'l' + i;
  }

  private static degrees_to_radians(degrees: number) {
    var pi = Math.PI;
    return degrees * (pi / 180);
  }

  private static degToDirectionFrom(wd: number) {
    if (wd >= 326.25 && wd < 348.75) {
      return 'NNW';
    }
    if (wd >= 303.75 && wd < 326.25) {
      return 'NW';
    }
    if (wd >= 281.25 && wd < 303.75) {
      return 'WNW';
    }
    if (wd >= 258.75 && wd < 281.25) {
      return 'W';
    }
    if (wd >= 236.25 && wd < 258.75) {
      return 'WSW';
    }
    if (wd >= 213.75 && wd < 236.25) {
      return 'SW';
    }
    if (wd >= 191.25 && wd < 213.75) {
      return 'SSW';
    }
    if (wd >= 168.75 && wd < 191.25) {
      return 'S';
    }
    if (wd >= 146.25 && wd < 168.75) {
      return 'SSE';
    }
    if (wd >= 123.75 && wd < 146.25) {
      return 'SE';
    }
    if (wd >= 101.25 && wd < 123.75) {
      return 'ESE';
    }
    if (wd >= 78.75 && wd < 101.25) {
      return 'E';
    }
    if (wd >= 56.25 && wd < 78.75) {
      return 'ENE';
    }
    if (wd >= 33.75 && wd < 56.25) {
      return 'NE';
    }
    if (wd >= 11.25 && wd < 33.75) {
      return 'NNE';
    }
    if (wd >= 348.75) {
      return 'N';
    }
    if (wd < 11.25) {
      return 'N';
    }
    return '';
  }

  private static degToDirectionTo(wd: number) {
    if (wd >= 326.25 && wd < 348.75) {
      return 'SSE';
    }
    if (wd >= 303.75 && wd < 326.25) {
      return 'SE';
    }
    if (wd >= 281.25 && wd < 303.75) {
      return 'ESE';
    }
    if (wd >= 258.75 && wd < 281.25) {
      return 'E';
    }
    if (wd >= 236.25 && wd < 258.75) {
      return 'ENE';
    }
    if (wd >= 213.75 && wd < 236.25) {
      return 'NE';
    }
    if (wd >= 191.25 && wd < 213.75) {
      return 'NNE';
    }
    if (wd >= 168.75 && wd < 191.25) {
      return 'N';
    }
    if (wd >= 146.25 && wd < 168.75) {
      return 'NNW';
    }
    if (wd >= 123.75 && wd < 146.25) {
      return 'NW';
    }
    if (wd >= 101.25 && wd < 123.75) {
      return 'WNW';
    }
    if (wd >= 78.75 && wd < 101.25) {
      return 'W';
    }
    if (wd >= 56.25 && wd < 78.75) {
      return 'WSW';
    }
    if (wd >= 33.75 && wd < 56.25) {
      return 'SW';
    }
    if (wd >= 11.25 && wd < 33.75) {
      return 'SSW';
    }
    if (wd >= 348.75) {
      return 'S';
    }
    if (wd < 11.25) {
      return 'S';
    }
    return '';
  }

  private static degToDirection(wd: number, direction: boolean) {
    if (direction) {
      return this.degToDirectionTo(wd);
    } else {
      return this.degToDirectionFrom(wd);
    }
  }

  public static roseConverter(
    deviceDetails: DeviceDetails[],
    units: any,
    key: string | undefined,
    directionFlag?: boolean
  ): Record<string, Array<any>> {
    const rose: any = { aqi: [], ws: [], resultant: {} };
    if (key && key !== 'ws') {
      rose[key] = [];
    }

    // Calculate resultant vector
    let resultant = {
      ws: {
        x: 0,
        y: 0,
      },
    };

    if (directionFlag === undefined) {
      directionFlag = false;
    }
    let filteredData = [];

    const directions: Record<string, Array<DeviceDetails>> = {
      N: [],
      NNE: [],
      NE: [],
      ENE: [],
      E: [],
      ESE: [],
      SE: [],
      SSE: [],
      S: [],
      SSW: [],
      SW: [],
      WSW: [],
      W: [],
      WNW: [],
      NW: [],
      NNW: [],
    };

    try {
      deviceDetails.forEach((deviceDetail) => {
        if (
          deviceDetail.payload.d?.wd !== undefined &&
          deviceDetail.payload.d.wd !== null
        ) {
          filteredData.push(deviceDetail);
          directions[
            this.degToDirection(
              deviceDetail.payload.d.wd,
              Boolean(directionFlag)
            )
          ].push(deviceDetail);
        }
      });
      const aqiRange = [...units['aqi'].limit].reverse();

      if (
        !Boolean(units['ws']?.limit?.length) ||
        Boolean(units['ws'] && units['ws'].limit?.length === 0)
      ) {
        units['ws'].limit = [0, 2, 4, 6, 8, 10];
      }

      aqiRange.forEach((range, index) => {
        const modifiedAqiRange: any = { name: '', colorIndex: null, data: [] };
        if (index === 0) {
          modifiedAqiRange.name = '> ' + range;
        } else {
          modifiedAqiRange.name = range + ' - ' + aqiRange[index - 1];
        }
        modifiedAqiRange.colorIndex = aqiRange.length - 1 - index;
        modifiedAqiRange.data = [];

        Object.keys(directions).forEach((directionKey) => {
          if (directions.hasOwnProperty(directionKey)) {
            const data: any = [];
            data.push(directionKey);
            data.push(0);
            const deviceDetails = directions[directionKey];
            deviceDetails.forEach((deviceDetail) => {
              if (index === 0) {
                if (deviceDetail.aqi! >= range) {
                  data[1] = data[1] + 1;
                }
              } else {
                if (
                  deviceDetail.aqi! >= range &&
                  deviceDetail.aqi! < aqiRange[index - 1]
                ) {
                  data[1] = data[1] + 1;
                }
              }
            });
            data[1] = parseFloat(
              ((data[1] / filteredData.length) * 100).toFixed(2)
            );
            const temp = isNaN(data[1]);
            if (!temp) {
              data[1] = data[1];
              modifiedAqiRange.data.push(data);
            }
          }
        });
        rose.aqi.push(modifiedAqiRange);
      });

      const wsRange: any[] = JSON.parse(
        JSON.stringify(units['ws'].limit)
      ).reverse();

      wsRange.forEach((range, index) => {
        const modifiedWsRange: any = { name: '', colorIndex: null, data: [] };
        if (index === 0) {
          modifiedWsRange.name = '> ' + range;
        } else {
          modifiedWsRange.name = range + ' - ' + wsRange[index - 1];
        }
        modifiedWsRange.name = modifiedWsRange.name + ' ' + units['ws'].label;
        modifiedWsRange.colorIndex = wsRange.length - 1 - index;
        modifiedWsRange.data = [];

        Object.keys(directions).forEach((directionKey) => {
          if (directions.hasOwnProperty(directionKey)) {
            const data: any = [];
            data.push(directionKey);
            data.push(0);
            const deviceDetails = directions[directionKey];
            deviceDetails.forEach((deviceDetail) => {
              if (index === 0) {
                if (deviceDetail.payload.d['ws'] >= range) {
                  data[1] = data[1] + 1;
                }
              } else {
                if (
                  deviceDetail.payload.d['ws'] >= range &&
                  deviceDetail.payload.d['ws'] < wsRange[index - 1]
                ) {
                  data[1] = data[1] + 1;
                }
              }
            });
            data[1] = parseFloat(
              ((data[1] / filteredData.length) * 100).toFixed(2)
            );
            const temp = isNaN(data[1]);
            if (!temp) {
              data[1] = data[1];
              modifiedWsRange.data.push(data);
            }
          }
        });
        rose.ws.push(modifiedWsRange);
      });

      if (key && key !== 'ws' && key !== 'aqi') {
        if (units[key] && units[key].limit && units[key].limit.length > 0) {
          const keyRange: any[] = units[key].limit.reverse();

          keyRange.forEach((range, index) => {
            const modifiedRange: any = { name: '', colorIndex: null, data: [] };

            if (index === 0) {
              modifiedRange.name = '> ' + range;
            } else {
              modifiedRange.name = range + ' - ' + keyRange[index - 1];
            }

            modifiedRange.name = modifiedRange.name + ' ' + units[key].label;
            modifiedRange.colorIndex = keyRange.length - 1 - index;
            modifiedRange.data = [];

            Object.keys(directions).forEach((directionKey) => {
              if (directions.hasOwnProperty(directionKey)) {
                const data: any[] = [];
                data.push(directionKey);
                data.push(0);
                const deviceDetails = directions[directionKey];
                deviceDetails.forEach((deviceDetail) => {
                  if (index === 0) {
                    if (deviceDetail.payload.d[key] >= range) {
                      data[1] = data[1] + 1;
                    }
                  } else {
                    if (
                      deviceDetail.payload.d[key] >= range &&
                      deviceDetail.payload.d[key] < keyRange[index - 1]
                    ) {
                      data[1] = data[1] + 1;
                    }
                  }
                });
                data[1] = parseFloat(
                  ((data[1] / filteredData.length) * 100).toFixed(2)
                );
                modifiedRange.data.push(data);
              }
            });
            rose[key].push(modifiedRange);
          });
        }
      }
      try {
        let resultants: any = {};
        filteredData = deviceDetails.filter((deviceDetail) => {
          const { wd } = deviceDetail.payload.d ?? {};
          if (wd !== undefined) {
            const angleRad = this.degrees_to_radians(wd);
            let keys = ['ws', 'aqi'];
            if (key) {
              keys.push(key);
            }

            keys.forEach((param) => {
              let value = deviceDetail.payload.d[param];
              if (param === 'aqi') {
                value = deviceDetail.aqi;
              }
              if (value !== undefined) {
                resultants[param] ??= { x: 0, y: 0 };
                resultants[param].x += value * Math.cos(angleRad);
                resultants[param].y += value * Math.sin(angleRad);
              }
            });
            directions[this.degToDirection(wd, Boolean(directionFlag))].push(
              deviceDetail
            );
            return true;
          }
          return false;
        });
        // Calculate magnitude and direction for each resultant
        const calculateDirectionCategory = (
          magnitude: number,
          angle: number
        ) => ({
          directionCategory:
            Object.keys(directions).findIndex(
              (dir) =>
                this.degToDirection(angle, Boolean(directionFlag)) === dir
            ) ?? 0,
          direction: angle,
          magnitude: magnitude,
        });

        Object.keys(resultants).forEach((param) => {
          const { x, y } = resultants[param];
          const magnitude = Math.hypot(x, y);
          let direction = Math.atan2(y, x) * (180 / Math.PI);

          direction = direction % 360;
          if (direction < 0) {
            direction += 360;
          }

          rose.resultant[param] = calculateDirectionCategory(
            magnitude,
            direction
          );
        });
      } catch (err) {
        console.info('Error:', err);
      }
      return rose;
    } catch (error) {
      console.info(error, 'in roseConverter function');
      return rose;
    }
  }

  public static getDeviceTypeIdByDeviceId(
    deviceTypes: DeviceType[],
    devices: DeviceDetails[],
    deviceId: string | string[]
  ): number | undefined {
    if (typeof deviceId === 'string') {
      let selectedDeviceData = devices.find(
        (allUserDevice) => allUserDevice.deviceId === deviceId
      );
      let deviceType = deviceTypes?.find(
        (deviceTypeOfUser: any) =>
          deviceTypeOfUser.key === selectedDeviceData?.deviceType
      );
      return deviceType?.deviceTypeId;
    } else if (typeof deviceId === 'object') {
      deviceId = deviceId[0];
      let selectedDeviceData = devices.find(
        (allUserDevice) => allUserDevice.deviceId === deviceId
      );
      let deviceType = deviceTypes?.find(
        (deviceTypeOfUser: any) =>
          deviceTypeOfUser.key === selectedDeviceData?.deviceType
      );
      return deviceType?.deviceTypeId;
    }
    //returns undefined if devicetype id not found
    return undefined;
  }

  public static flaggedColumnColor(columnKey: string, row: any) {
    const cfKey = `${columnKey}_cf`;
    const cfValue = row.payload.d[cfKey];
    const flag = this.flagCategories.find(
      (category) => category.value === cfValue
    );
    return flag ? flag.color : '';
  }
}
