import { Component, NgZone } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { ConfirmationPopupComponent } from 'src/app/shared/components/confirmation-popup/confirmation-popup/confirmation-popup.component';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { DeviceType } from 'src/app/shared/models/device-type/device-type';
import { DeviceField } from 'src/app/shared/models/device/device-field';
import { ContentUnavailable } from 'src/app/shared/models/internal-use-front-end/content-unavailable';
import { ClusterService } from 'src/app/shared/services/cluster.services';
import { CommonService } from 'src/app/shared/services/common.service';
import { ConfirmationPopupService } from 'src/app/shared/services/confirmation-popup.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { CommonUtil } from 'src/app/shared/utils/common-utils';
import { DeviceUtil } from 'src/app/shared/utils/device-utils';
import { ClusterFormComponent } from '../../componets/cluster-form/cluster-form.component';
import { ClusterReportComponent } from '../../componets/cluster-report/cluster-report.component';
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent {
  loadTable: Subject<boolean> = new BehaviorSubject(false);
  public analyticDetail!: FormGroup;
  clusters: any[] = [];
  deviceTypes!: Array<DeviceType>;
  public displayedColumns: string[] = [];
  subscriptions: Subscription[] = [];
  defaultColumns: any[];
  fields: any;
  gasLevels: any[] = [];
  paramColor: any = {};
  limits: any;
  columns: any[] = [];
  allParameters: any[] = [];
  private userTimeFormat: number;
  public dialogCompInstance!: ConfirmationPopupComponent;
  showNoData: boolean = false;
  public noData: ContentUnavailable = {
    majorText: 'No Cluster Found',
    svgImage: AppConstants.QUERIED_DATA_NOT_FOUND,
    minorText: 'Try adding a cluster for this Device Type',
  };

  public notGenerated: ContentUnavailable = {
    majorText: 'Cluster Data Not Generated',
    svgImage: AppConstants.QUERIED_DATA_NOT_FOUND,
    minorText: 'Please wait for the next hour',
  };

  constructor(
    private clusterService: ClusterService,
    private zone: NgZone,
    private localStorageService: LocalStorageService,
    private deviceService: DeviceService,
    private commonService: CommonService,
    private customMomentService: CustomMomentService,
    private router: Router,
    private confirmationPopupService: ConfirmationPopupService,
    private dialog: MatDialog,
    private formsService: FormsService
  ) {
    this.userTimeFormat =
      this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
        ?.settings?.time_format ?? 24;
    this.defaultColumns = this.generateDefaultColumnsConfig();
  }

  ngOnInit() {
    this.userTimeFormat =
      this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
        ?.settings?.time_format ?? 24;
    this.defaultColumns = this.generateDefaultColumnsConfig();
    this.deviceTypes = this.commonService.getUserDeviceTypes();
    this.displayedColumns = [...this.defaultColumns.map((c) => c.columnDef)];
    this.clusters = this.clusterService.getAllClusterUnitData(
      this.clusterService.clusterData
    );
    this.convertValuesToUserUnits();

    this.fields = this.deviceService.fields;
    // this.limits = this.deviceService.limits;
  }

  ngAfterViewInit(): void {
    this.loadData(this.deviceService.currentDeviceType.key);
    this.addSubscriptions();
  }

  private convertValuesToUserUnits() {
    this.clusters.forEach((cluster: any) => {
      const allUnits = this.commonService.getAllUnits();
      const deviceTypes = this.commonService.getDeviceTypes();

      const deviceTypeId = DeviceUtil.getDeviceTypeByKeyOrId(
        cluster.config.deviceType,
        deviceTypes
      );

      cluster.parameters = {};
      cluster.parameters['aqi'] = this.calculateAndStoreAQI(cluster);
      cluster.data.payload.d['aqi'] = cluster.parameters['aqi']?.value;

      if (deviceTypeId) {
        cluster.payload = DeviceUtil.convertUnits(
          cluster.payload,
          allUnits[deviceTypeId.deviceTypeId]
        );
      }
    });
  }

  private generateDefaultColumnsConfig() {
    return [
      {
        columnDef: 'actions',
        header: '',
        cell: (element: any) => `${element.clusterId}`,
        parameter: false,
        selected: false,
        filter: false,
        icon: 'more_vert',
        options: [
          {
            label: (element: any) =>
              `<span class="material-symbols-outlined">north_east</span>&nbsp Go to Data View`,
            action: (element: any) => this.gotoDashboard(element.clusterId),
          },
          {
            label: (element: any) =>
              `<span class="material-symbols-outlined">download</span>&nbsp Download Report`,
            action: (element: any) => this.downloadClusterReport(element),
          },
          {
            label: (element: any) =>
              `<span class="material-symbols-outlined">edit</span>&nbsp Edit Cluster`,
            action: (element: any) => this.editCluster(element),
          },
          {
            label: (element: any) =>
              `<span class="material-symbols-outlined">delete</span>&nbsp Delete`,
            action: (element: any) => {
              this.openDialog(element);
            },
          },
        ],
      },
      {
        columnDef: 'clusterId',
        header: 'Cluster Id',
        cell: (element: any) => `${element.clusterId}`,
        parameter: false,
        selected: false,
        filter: false,
      },
      {
        columnDef: 'label',
        header: 'Cluster Name',
        cell: (element: any) => `${element.label}`,
        parameter: false,
        selected: false,
        filter: false,
      },
      {
        columnDef: 'time',
        header: 'Time',
        cell: (element: any) =>
          `${
            this.allParameters.length > 1
              ? element.updated_on
                ? this.customMomentService.formatDatetime({
                    epoch: (element as any).data.payload.d.t,
                    format:
                      this.userTimeFormat === 12
                        ? 'hh:mm A, DD/MM/YY'
                        : 'HH:mm, DD/MM/YY',
                  })
                : '-'
              : 'Device Offline'
          }`,
        parameter: false,
        selected: false,
        filter: false,
      },
    ];
  }

  addSubscriptions() {
    const getDeviceType: Subscription =
      this.deviceService.localStorageDeviceUpdate$.subscribe((deviceType) => {
        if (deviceType) {
          this.loadData(deviceType.key);
        }
      });

    const getFields: Subscription = this.deviceService.getFields$.subscribe(
      () => {
        this.fields = this.deviceService.fields;
      }
    );

    const getLimits: Subscription = this.deviceService.getLimits$.subscribe(
      () => {
        this.limits = this.deviceService.limits;
      }
    );
    this.subscriptions.push(getDeviceType);
    this.subscriptions.push(getFields);
  }

  loadData(deviceType: string): void {
    this.loadTable.next(false);
    this.clusters = this.clusterService.clusterData.filter(
      (cluster) => cluster.config.deviceType === deviceType
    );
    this.latestDocumentSnapshot();
  }
  latestDocumentSnapshot() {
    this.allParameters = [];
    this.clusters.forEach((cluster) => {
      const parameters: any[] = [];
      const filteredArray = [];
      const deviceTypes: DeviceType[] = this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_ALL_DEV_TYPE
      );
      // cluster.parameters = {};
      // cluster.parameters['aqi'] = this.calculateAndStoreAQI(cluster);
      // cluster.data.payload.d['aqi'] = cluster.parameters['aqi']?.value;

      // const allAqi: any = this.localStorageService.getParsedValue(
      //   LocalStorageConstants.OZ_ALL_AQI
      // );
      // const allAqis: any = this.commonService.getUserAQI();
      if (cluster.data.payload && cluster.data.payload.d) {
        const allUnits: any = this.commonService.getAllUnits();

        // let convertedValue = DeviceUtil.convertUnits(
        //   cluster.data.payload,
        //   allUnits[cluster.deviceTypeId],
        //   undefined,
        //   false
        // );
        let keys = Object.keys(cluster.data.payload.d);

        let allKeys = [];
        for (let df of keys) {
          let field: DeviceField = this.fields.find(
            (f: DeviceField) => f?.fkey == df
          )!;
          if (field && field.fkey !== 't') {
            allKeys.push(field!);
          }
        }
        allKeys = CommonUtil.setParameterSequence(allKeys);

        allKeys.forEach((key) => {
          const fieldIndex = this.allParameters.findIndex(
            (param) => param.name === key.fkey
          );
          const field = this.fields
            .filter((field: { isVisible: any }) => field.isVisible)
            .find((field: { fkey: any }) => field.fkey === key.fkey);

          // const deviceTypeId = DeviceUtil.getDeviceTypeId(
          //   this.deviceService.currentDeviceType.key,
          //   deviceTypes
          // );
          if (field?.isVisible === true && fieldIndex < 0) {
            this.allParameters.push({
              name: field.fkey,
              label: DeviceUtil.getFieldName(field.fkey, this.fields),
              unit: cluster?.units[field.fkey]?.label,
            });
            filteredArray.push(field);
          }
          cluster.parameters[key.fkey] = {
            key: key.fkey,
            label: DeviceUtil.getFieldName(key.fkey, this.fields),
            units: cluster?.units[key.fkey]?.label,
            // value: cluster.data.payload.d[key.fkey],
            value: Number(cluster.data.payload.d[key.fkey]?.toFixed(2)),
          };
        });
      }
    });

    this.columns = [];
    this.displayedColumns = [];
    this.columns = [...this.defaultColumns];
    this.displayedColumns = [...this.columns.map((c) => c.columnDef)];
    this.generateCustomColumns();

    setTimeout(() => {
      this.loadTable.next(true);
    });
  }

  calculateAndStoreAQI(cluster: any) {
    const devices = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER_DEVICE_TYPE
    );
    const all_aqis = this.commonService.getUserAQI();
    const all_aqi = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_AQI
    );
    const aqi = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_AQI
    );
    let label;
    let description;
    const deviceTypeId: number | undefined = DeviceUtil.getDeviceTypeId(
      cluster.config.deviceType,
      devices
    );

    if (deviceTypeId != undefined && cluster?.aqi_index_id) {
      label = this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_ALL_AQI
      )?.[deviceTypeId]?.[cluster?.aqi_index_id]?.label;
      description = this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_ALL_AQI
      )?.[deviceTypeId]?.[cluster?.aqi_index_id]?.index?.description;
    }

    const clusterId = cluster.clusterId;
    let aqiInfo = DeviceUtil.calculateAQI(
      cluster.data.payload,
      DeviceUtil.getBreakpoints(
        // 2007,
        cluster?.aqi_index_id ?? '',
        cluster.config.deviceType,
        devices,
        all_aqi,
        all_aqis,
        aqi
      )
    );
    let color = '#6ecc58';
    let color_arr;
    if (aqiInfo && aqiInfo.aqi) {
      color_arr = DeviceUtil.aqiColorArray(
        devices,
        all_aqi,
        all_aqis,
        cluster?.aqi_index_id ?? '',
        cluster.config.deviceType,
        'Cluster'
      );

      if (aqiInfo.aqi < color_arr.limits[color_arr.limits.length - 1]) {
        for (let i = 0; i < color_arr.limits.length - 1; i++) {
          if (
            color_arr.limits[i] <= aqiInfo.aqi &&
            aqiInfo.aqi < color_arr.limits[i + 1]
          ) {
            color = color_arr.color[i];
          }
        }
      } else {
        color = color_arr.color[color_arr.color.length - 1];
      }
    }

    const data = {
      key: 'aqi',
      units: label,
      color: color,
      value: aqiInfo.aqi,
      label: DeviceUtil.getFieldName('aqi', this.fields),
    };
    return data;
  }
  generateCustomColumns(): void {
    const allAqi: any = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_AQI
    );
    const allAqis: any = this.commonService.getUserAQI();

    if (this.allParameters.length > 1) {
      this.allParameters.forEach((param) => {
        this.columns.push({
          columnDef: param.name,
          header:
            param.name == 'aqi'
              ? 'AQI'
              : Boolean(param.unit?.replaceAll(' ', ''))
              ? `${param.label} <br><small>${param.unit}</small>`
              : `${param.label}`,
          cell: (element: any) =>
            `${element.parameters[param.name]?.value || '-'}`,
          color: (element: any) => {
            return element.parameters[param.name]?.value
              ? element?.aqi_index_id &&
                  DeviceUtil.getParamColor(
                    DeviceUtil.getLimitClass(
                      param.name,
                      element.parameters[param.name]?.value,
                      this.deviceService.fetchLimits(element?.units, false)
                    ),
                    this.deviceTypes,
                    allAqi,
                    allAqis,
                    element?.aqi_index_id,
                    element.deviceTypeId,
                    'Cluster'
                  )
              : '';
          },
          parameter: true,
          selected: false,
          filter: true,
        });
        this.displayedColumns.push(param.name);
      });
    } else {
      this.allParameters = [];
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  exportTableDataToCSV(): void {
    if (!this.clusters || !this.clusters.length) {
      return;
    }

    const csvRows = [];
    const headers = this.columns.map((field) =>
      field.header.replace(/<[^>]+>/g, '')
    );

    csvRows.push(headers.join(',')); // Join headers with commas to form the first row
    this.clusters.forEach((cluster) => {
      const data = this.columns.map((field) => {
        const columnKey = field.columnDef;
        let cellValue;

        if (columnKey === 'time') {
          cellValue = cluster.updated_on
            ? this.customMomentService.formatDatetime({
                epoch: cluster.updated_on,
                format:
                  this.userTimeFormat === 12
                    ? 'hh:mm A, DD/MM/YY'
                    : 'HH:mm, DD/MM/YY',
              })
            : '-';
        } else if (cluster.parameters && cluster.parameters[columnKey]) {
          cellValue = cluster.parameters[columnKey].value;
        } else {
          cellValue = cluster[columnKey];
        }
        return `"${cellValue !== undefined ? cellValue : ''}"`;
      });

      csvRows.push(data.join(','));
    });

    const fileName = `${this.localStorageService.getValue(
      'u-org-name'
    )}_${this.customMomentService.formatDatetime({
      format: 'DD_MM_YYYY_hh_mm_A',
    })}.csv`;
    CommonUtil.downloadFile(csvRows.join('\n'), fileName);
  }

  deleteCluster(device: any) {
    this.dialogCompInstance.dialogConfig.image = AppConstants.LOADING;
    this.dialogCompInstance.dialogConfig.message = 'Please Wait...';
    this.dialogCompInstance.dialogConfig.hideActionButtons = true;
    this.clusterService.deleteCluster(device.clusterId).subscribe({
      next: (res: any) => {
        if (res) {
          this.dialogCompInstance.dialogConfig.image =
            AppConstants.DELETE_SUCCESSFUL;
          this.dialogCompInstance.dialogConfig.message =
            'Cluster Deleted Successfully';
          setTimeout(() => {
            this.dialog.closeAll();
            this.clusterService.getCluster().subscribe((res) => {
              if (res) {
                this.clusterService.isGetData.next(true);
                this.clusters = this.clusterService.getAllClusterUnitData(
                  this.clusterService.clusterData
                );
              }
            });
          }, AppConstants.POPUP_TIMEOUT);
        }
      },
      error: (err: any) => {
        console.info('Error deleting cluster:', err);
        this.dialogCompInstance.dialogConfig.image =
          AppConstants.QUERIED_DATA_NOT_FOUND;
        this.dialogCompInstance.dialogConfig.message =
          'Error While Deleting Cluster. Try Again';
        setTimeout(() => {
          this.dialog.closeAll();
        }, AppConstants.POPUP_TIMEOUT);
      },
    });
  }
  gotoDashboard(selectedClusterID: any) {
    let path = '/cluster/data/' + selectedClusterID;
    this.router.navigate([path]);
  }

  editCluster(selectedCluster: any) {
    this.formsService.openForm(ClusterFormComponent, selectedCluster, 'lg');
  }

  downloadClusterReport(selectedCluster: any) {
    this.formsService.openForm(ClusterReportComponent, selectedCluster, 'md');
  }

  openDialog(device: any): void {
    const dynamicData = {
      title: 'Delete Cluster',
      message: 'Are you sure you want to delete this Cluster?',
      icon: 'delete',
      buttonconfig: [
        {
          text: 'DELETE',
          onClickCallback: () => {
            this.deleteCluster(device);
          },
          closeDialog: false,
          color: 'primary',
          type: 'mat-raised-button',
        },
        {
          text: 'Cancel',
          onClickCallback: () => null,
          closeDialog: true,
          type: 'mat-button',
        },
      ],
    };

    const dialogConfig = Object.assign(
      {},
      this.confirmationPopupService.getDefaultDialogConfig(),
      { data: dynamicData }
    );
    const dialogRef = this.dialog.open(
      ConfirmationPopupComponent,
      dialogConfig
    );
    this.dialogCompInstance = dialogRef.componentInstance;
    this.confirmationPopupService.getDialogRef(dialogRef);
  }
}
