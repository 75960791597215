import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Subject } from 'rxjs';
import { APIConstants } from '../constants/api-constants';
import { AppConstants } from '../constants/app-constants';
import { LocalStorageConstants } from '../constants/local-storage.constant';
import { LatestVersion } from '../models/latest-version';
import { BaseAPIService } from './base-service';
import { CookieService } from './cookie.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class OverviewService extends BaseAPIService<any> {
  constructor(
    private localStorageService: LocalStorageService,
    http: HttpClient,
    cookieService: CookieService
  ) {
    super(http, cookieService);
  }
  public overviewForm = new BehaviorSubject<any>({});
  overviewForm$ = this.overviewForm.asObservable();

  //observable to emit change in tag selection
  public onHeaderTagChange = new Subject<any>();
  public onHeaderTagChange$ = this.onHeaderTagChange.asObservable();

  public newFeatures = new BehaviorSubject<Array<LatestVersion.Info>>([]);
  public newFeatures$ = this.newFeatures.asObservable();

  getNewFeaturesInformation() {
    const token = this.localStorageService.getValue(
      LocalStorageConstants.TOKEN
    );
    const clientId = this.localStorageService.getValue(
      LocalStorageConstants.CLIENT_ID
    );
    const userId = this.localStorageService.getValue(
      LocalStorageConstants.USER_ID
    );

    const headers: HttpHeaders = new HttpHeaders()
      .append(
        AppConstants.AUTHORIZATION_HEADER,
        `${AppConstants.BEARER} ${token}`
      )
      .append(AppConstants.IBM_CLIENT, clientId);

    return this.get<Array<LatestVersion.Info>>(
      APIConstants.NEW_FEATURES_INFO,
      headers
    ).pipe(
      map((res) => {
        this.newFeatures.next(res);
        return res;
      })
    );
  }
}
