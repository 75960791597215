import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { DeviceService } from 'src/app/shared/services/device.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { UserService } from 'src/app/shared/services/user.service';
import { CommonUtil } from 'src/app/shared/utils/common-utils';
import { DeviceUtil } from 'src/app/shared/utils/device-utils';
import { AlertConstant } from '../../constants/alert-constants';
import { LocalStorageConstants } from '../../constants/local-storage.constant';
import { AlertData } from '../../models/alerts/alert-data';
import { DeviceType } from '../../models/device-type/device-type';
import { DeviceDetails } from '../../models/device/device-details';
import { Notifications } from '../../models/notifications/notification';
import { CommonService } from '../../services/common.service';
import { CustomMomentService } from '../../services/custom-moment.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { sortList } from '../../utils/array-utils';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent {
  public readonly topicType: string =
    AppConstants.NOTIFICATION_TOPICS['header-notification'];
  notificationList: any;
  maxDate: moment.Moment = this.customMomentService.moment();
  minDate: moment.Moment = this.customMomentService.moment().subtract(1, 'day');
  public notificationLastVisited: number = 0;
  devices!: DeviceDetails[];
  alertList: any;
  fetchInfo: any;
  title: string = 'Notifications';
  allUnits: any;
  deviceTypes: DeviceType[] = this.commonService.getUserDeviceTypes();
  public subscriptions: Subscription[] = [];
  public intervalId!: NodeJS.Timer;

  constructor(
    private userService: UserService,
    private formsService: FormsService,
    private deviceService: DeviceService,
    private router: Router,
    private customMomentService: CustomMomentService,
    private commonService: CommonService,
    private _cdr: ChangeDetectorRef,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.notificationLastVisited = this.commonService.getNotifLastVisit(
      this.topicType,
      true
    );
    this.devices = this.deviceService.registeredDevices!;
    this.allUnits = this.commonService.getAllUnits();
    this.notificationList = AppConstants.NOTIFICATION_LIST;
    this.alertList = [];
    this.fetchNotifications();
    this.addSubscriptions();

    //keep updating the last visit time every second if the notification component is kept open
    this.intervalId = setInterval(() => {
      const currentTime = this.customMomentService.moment().unix();
      this.localStorageService.saveValue(
        LocalStorageConstants.OZ_HEADER_NOTIF_LAST_VISIT,
        currentTime
      );
    }, 1000);
  }

  //to update the lastNotificationVisit
  updateNotificationVisit() {
    this.userService
      .updateNotificationLastSeen(this.generatePayload())
      .subscribe({
        next: () => {
          const topics = [this.topicType];
          this.userService.getNotificationLastSeen(topics).subscribe({
            next: (res) => {
              const currentLastSeen = this.localStorageService.getParsedValue(
                LocalStorageConstants.OZ_ALL_LAST_SEEN
              );
              const updatedLastSeen = {
                ...currentLastSeen,
                'header-notification': res[0].ts,
              };
              this.localStorageService.saveValue(
                LocalStorageConstants.OZ_ALL_LAST_SEEN,
                JSON.stringify(updatedLastSeen)
              );
            },
            error: () => {},
          });
        },
        error: () => {
          console.error('Error while updating notification visit');
        },
      });
  }

  generatePayload() {
    let currentTimeInEpoch = this.commonService.getNotifLastVisit(
      this.topicType,
      true
    );

    const payload: Notifications.Put = {
      data: [
        {
          topic: this.topicType,
          ts: currentTimeInEpoch,
        },
      ],
    };

    return payload;
  }

  fetchNotifications() {
    this.fetchInfo = this.userService.fetchNotificationData();
    this.fetchInfo = sortList(this.fetchInfo, 'DESC', 't');
    this.fetchInfo.forEach((alert: AlertData.DataAlert) => {
      let deviceTypeId = DeviceUtil.getDeviceTypeIdByDeviceId(
        this.deviceTypes,
        this.devices,
        alert.deviceId
      )!;

      let fieldsOfCurrentDeviceType = this.deviceService.fetchFields(
        deviceTypeId,
        this.allUnits,
        false
      );

      const message: string =
        alert.operation === '>='
          ? AppConstants.ALERT_GREATER_THAN_EQUAL_TO
          : AppConstants.ALERT_LESS_THAN;

      let obj = {
        deviceId: alert.deviceId,
        type: alert.type,
        label: DeviceUtil.getDeviceLabel(
          this.deviceService.registeredDevices!,
          alert.deviceId
        ),
        message: message,
        key: alert.key,
        keyLabel: DeviceUtil.getFieldName(alert.key, fieldsOfCurrentDeviceType),
        value: DeviceUtil.getCFactoreData(
          alert.key,
          alert.value.toString(),
          fieldsOfCurrentDeviceType
        ),
        epochTime: alert.t,
        timestamp: CommonUtil.getDisplayTime(alert.t),
        unit: this.allUnits[deviceTypeId]?.[alert.key]?.label,
        period: alert.period
          ? AlertConstant.hourForDisplay[
              alert.period as keyof typeof AlertConstant.hourForDisplay
            ]
          : '-',
      };
      this.alertList.push(obj);
      this._cdr.detectChanges();
    });
  }

  closeForm(data: boolean = false) {
    this.formsService.closeForm(data);
  }

  navigateToDashBoard(deviceId: any): void {
    this.router.navigate([`/dashboard/widget/${deviceId}`]);
  }

  hasAlertsForNotification(notification: any): boolean {
    return this.alertList.some(
      (alert: any) => alert.type === notification.reportType
    );
  }

  addSubscriptions() {
    const socketNotif = this.userService.alertNotificationSnapshot$.subscribe(
      (res) => {
        this.alertList = [];
        this.fetchNotifications();
      }
    );

    this.subscriptions.push(socketNotif);
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
    //to update the lastNotificationVisit key in user object when the component is getting closed
    const currentTime = this.customMomentService.moment().unix();
    this.localStorageService.saveValue(
      LocalStorageConstants.OZ_HEADER_NOTIF_LAST_VISIT,
      currentTime
    );
    this.updateNotificationVisit();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
