import { Component, OnInit } from '@angular/core';
import {
  BehaviorSubject,
  bufferTime,
  filter,
  Subject,
  Subscription,
} from 'rxjs';
import { AlertConstant } from 'src/app/shared/constants/alert-constants';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { AlertData } from 'src/app/shared/models/alerts/alert-data';
import { DeviceType } from 'src/app/shared/models/device-type/device-type';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { DevicePayload } from 'src/app/shared/models/device/device-payload';
import { ContentUnavailable } from 'src/app/shared/models/internal-use-front-end/content-unavailable';
import { AlertsService } from 'src/app/shared/services/alerts.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { UserService } from 'src/app/shared/services/user.service';
import { CommonUtil } from 'src/app/shared/utils/common-utils';
import { DeviceUtil } from 'src/app/shared/utils/device-utils';

@Component({
  selector: 'app-data-alerts',
  templateUrl: './data-alerts.component.html',
  styleUrls: ['./data-alerts.component.scss'],
})
export class DataAlertsComponent implements OnInit {
  public displayedColumns: string[] = [];
  public loadTable: Subject<boolean> = new BehaviorSubject(false);
  public dataAlerts!: AlertData.DataAlert[];
  public devices: DeviceDetails[] | undefined =
    this.deviceService.registeredDevices;
  public subscriptions: Subscription[] = [];
  public noData: ContentUnavailable = {
    majorText: 'No Data Found',
    svgImage: AppConstants.NO_DATA,
    minorText: 'No Alerts Received.',
  };
  public startDate: any;
  public endDate: any;
  public defaultColumns: any[] = [
    {
      columnDef: 'deviceId',
      header: 'Device Name',
      cell: (element: AlertData.DataAlert) => `${element.deviceId}`,
      parameter: false,
      selected: false,
    },
    {
      columnDef: 'time',
      header: 'Time',
      cell: (element: AlertData.DataAlert) => `${element.t}`,
      parameter: false,
      selected: false,
    },
    {
      columnDef: 'longTextMessage',
      header: 'Alert',
      cell: (element: any) => `${element.message}`,
      parameter: false,
      selected: false,
      width: '40%',
    },
    {
      columnDef: 'interval',
      header: 'Interval',
      cell: (element: any) => `${element.interval}`,
      parameter: false,
      selected: false,
    },
  ];

  public intervalMapping: Record<string, string> = AlertConstant.hourForDisplay;

  constructor(
    private alertsService: AlertsService,
    private deviceService: DeviceService,
    private customMomentService: CustomMomentService,
    private commonService: CommonService,
    private notificationService: NotificationService,
    private userService: UserService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.displayedColumns = [...this.defaultColumns.map((c) => c.columnDef)];
    this.addSubscriptions();
    this.getDataAlerts();
  }

  appendSocketAlerts(alertsReceivedFromSocket: any) {
    const todayDate = this.customMomentService.moment().startOf('day').unix();

    //if user is seeing alerts for other dates than dont append incoming sockets
    if (
      alertsReceivedFromSocket.length > 0 &&
      (this.endDate === undefined || this.endDate > todayDate)
    ) {
      let deviceTypes: DeviceType[] = this.commonService.getUserDeviceTypes();
      let allUnits = this.commonService.getUser().units;
      this.loadTable.next(false);
      alertsReceivedFromSocket.forEach((alert: any) => {
        this.dataAlerts.unshift(
          this.formatData(
            this.alertsService.allAlerts,
            alert,
            deviceTypes,
            allUnits
          )
        );
      });
      this.loadData();
    }
  }

  getDataAlerts() {
    this.alertsService.getDataAlerts(this.startDate, this.endDate).subscribe({
      next: () => {
        this.alertsService.getAlertList().subscribe({
          next: () => {
            this.alertsService.loadDataAlerts.next(true);
          },
        });
      },
      error: (err) => {
        console.info('Error:', err);
        this.notificationService.showSnackBar(err, 'error');
      },
    });
  }

  addSubscriptions() {
    let da = this.alertsService.datesForDataAlerts$.subscribe((res) => {
      if (res?.start && res?.end) {
        this.startDate = res.start.unix();
        this.endDate = res.end.endOf('day').unix();
      } else {
        this.startDate = undefined;
        this.endDate = undefined;
      }
    });

    this.subscriptions.push(da);

    let lda = this.alertsService.loadDataAlerts$.subscribe((res) => {
      if (res) {
        let deviceTypes: DeviceType[] = this.commonService.getUserDeviceTypes();
        let allUnits = this.commonService.getUser().units;
        this.dataAlerts = this.alertsService.dataAlerts.map(
          (d: AlertData.DataAlert) =>
            this.formatData(
              this.alertsService.allAlerts,
              d,
              deviceTypes,
              allUnits
            )
        );
        this.loadData();
      } else {
        this.loadTable.next(false);
      }
    });

    this.subscriptions.push(lda);

    //to get latest alerts from socket
    let socket = this.userService.alertNotificationSnapshot$
      .pipe(
        bufferTime(5000), // Wait for 5 seconds of inactivity after a burst
        filter((buffer: any) => buffer.length > 0)
      )
      .subscribe((alertsReceivedFromSocket) => {
        this.appendSocketAlerts(alertsReceivedFromSocket);
      });

    this.subscriptions.push(socket);
  }

  loadData(): void {
    setTimeout(() => {
      this.loadTable.next(true);
    });
  }

  getKeyLabel(key: string, deviceId: string): string {
    const units: any = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_USER
    ).units;

    const deviceTypes = this.commonService.getUserDeviceTypes();

    const deviceTypeId = DeviceUtil.getDeviceTypeIdByDeviceId(
      deviceTypes,
      this.devices!,
      deviceId
    );

    if (deviceTypeId) {
      return units[deviceTypeId!][key]?.flabel;
    }
    return '';
  }

  formatData(
    configurationList: AlertData.Get[],
    d: AlertData.DataAlert,
    deviceTypes: DeviceType[],
    allUnits: any
  ) {
    // let deviceTypes: DeviceType[] = this.commonService.getUserDeviceTypes();
    // let allUnits = this.commonService.getUser().units;
    // if (!this.dataAlerts.length) {
    //   return;
    // }

    // this.dataAlerts = this.dataAlerts.map((d: AlertData.DataAlert) => {
    let deviceTypeId = DeviceUtil.getDeviceTypeIdByDeviceId(
      deviceTypes,
      this.devices!,
      d.deviceId
    )!;

    let config = configurationList.find((res) => res.alertId == d.alertId);

    let data: any = JSON.parse(JSON.stringify(d));

    let message: string =
      data.operation === '>='
        ? AppConstants.ALERT_GREATER_THAN_EQUAL_TO
        : AppConstants.ALERT_LESS_THAN;

    let payload: DevicePayload = {
      d: {},
    };

    payload.d[data.key] = data.value;
    let convertedValue = DeviceUtil.convertUnits(
      payload,
      allUnits[deviceTypeId],
      undefined,
      false
    );
    let finalValue = convertedValue.d[data.key].toFixed(2);

    let keyVal = this.getKeyLabel(data.key, data.deviceId);

    data.deviceId = DeviceUtil.getDeviceLabel(this.devices!, data.deviceId);
    data.t = this.customMomentService.formatDate({
      epoch: data.t,
      format: this.commonService.getDateTimeFormat(),
    });
    data.unit = allUnits[deviceTypeId]?.[data.key]?.label;
    data.message = `${keyVal + message + finalValue + ' ' + data.unit}`;
    data.interval = config?.period ? this.intervalMapping[config?.period] : '';
    return data;
    // });
  }

  exportTableDataToCSV(): void {
    const csvRows = [];
    const headers = this.defaultColumns.map((field) => field.header);
    csvRows.push(headers.join(','));
    let data: string[] = [];
    this.dataAlerts.forEach((alert: any) => {
      data = [
        alert.deviceId,
        String(alert.t).replace(',', ' '),
        alert.message,
        alert.interval,
      ];
      csvRows.push(data.join(','));
    });
    const fileName =
      'Data_Alerts' +
      '_' +
      this.customMomentService.formatDatetime({ format: 'DD-MM-YYYY' });
    CommonUtil.downloadFile(csvRows.join('\n'), fileName);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => {
      if (!sub.closed) {
        sub.unsubscribe();
      }
    });
    this.alertsService.loadDataAlerts.next(false);
  }
}
