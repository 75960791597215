<div class="row h-100 justify-content-center loader position-relative">
  <ng-container *ngIf="loadTable | async">
    <div class="col-12 col-md-9 col-lg-8 col-xl-7 my-5 align-self-center">
      <h3>Set New Password</h3>

      <form [formGroup]="resetPasswordForm" class="row mt-4 gap-3">
        <mat-form-field class="col-12" appearance="outline">
          <mat-label>New Password</mat-label>
          <input
            matInput
            placeholder="New Password"
            formControlName="password"
            autocomplete="new-password"
            [type]="hide ? 'text' : 'password'"
          />
          <mat-icon matSuffix (click)="hide = !hide" [style.cursor]="'pointer'">
            {{ hide ? "visibility" : "visibility_off" }}
          </mat-icon>
          <mat-error
            *ngIf="
              resetPasswordForm.get('password')?.touched &&
              !resetPasswordForm.get('password')!.valid
            "
            >Password must be at least 8 characters long and must contain at
            least one uppercase, lowercase, special character and number
          </mat-error>
        </mat-form-field>
        <!-- <div
          class="d-flex flex-column"
          *ngIf="!resetPasswordForm.get('password')?.valid"
        >
          <mat-hint class="custom-material-hint">
            Contain at least one digit
            <mat-icon class="customs-material-icon" *ngIf="contains.digit">
              check
            </mat-icon>
          </mat-hint>
          <mat-hint class="custom-material-hint">
            Contain at least one lowercase letter
            <mat-icon class="customs-material-icon" *ngIf="contains.lowercase">
              check
            </mat-icon>
          </mat-hint>
          <mat-hint class="custom-material-hint">
            Contain at least one uppercase letter
            <mat-icon class="customs-material-icon" *ngIf="contains.uppercase">
              check
            </mat-icon>
          </mat-hint>
          <mat-hint class="custom-material-hint">
            Contain at least one special character
            <mat-icon
              class="customs-material-icon"
              *ngIf="contains.specialchar"
            >
              check
            </mat-icon>
          </mat-hint>
          <mat-hint class="custom-material-hint">
            At least 8 characters long
            <mat-icon class="customs-material-icon" *ngIf="contains.len">
              check
            </mat-icon>
          </mat-hint>
        </div> -->

        <mat-form-field class="col-12 mb-2" appearance="outline">
          <mat-label>Confirm Password</mat-label>
          <input
            autocomplete="new-password"
            matInput
            placeholder="Confirm Password"
            formControlName="confirmPassword"
            type="password"
          />
        </mat-form-field>
        <mat-error
          *ngIf="
            passwordDifferent &&
            resetPasswordForm.get('confirmPassword')?.dirty &&
            resetPasswordForm.get('confirmPassword')?.value?.length > 5
          "
          style="font-size: 12px; font-weight: 400"
        >
          Passwords do not match
        </mat-error>
        <div class="col-12">
          <button
            type="submit"
            class="col-12"
            mat-raised-button
            [color]="'primary'"
            [disabled]="
              !resetPasswordForm.valid || passwordDifferent || disableButton
            "
            (click)="submitForm()"
          >
            Reset Password
          </button>
        </div>
      </form>

      <div
        class="w-100 pt-3"
        style="display: flex !important; justify-content: end"
      >
        <p
          style="cursor: pointer"
          class="text-decoration-underline"
          (click)="navigateToLoginPage()"
        >
          Login
        </p>
      </div>
    </div>
  </ng-container>

  <div class="py-5 align-self-center" *ngIf="(loadTable | async) == false">
    <mat-spinner class="mx-auto" [diameter]="50" color="primary"></mat-spinner>
  </div>
</div>
