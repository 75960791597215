import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import Fuse from 'fuse.js';
import { DeviceService } from '../../services/device.service';

@Component({
  selector: 'app-tag-input',
  templateUrl: './tag-input.component.html',
  styleUrls: ['./tag-input.component.scss'],
})
export class TagInputComponent {
  @Output() getTags: EventEmitter<any> = new EventEmitter();
  @Input() existingTags: string[] = []; // already existing tags
  public readonly seperatorKeysCodes = [ENTER, COMMA] as const;
  public addOnBlur = true;
  public tagInputFormControl = new FormControl();
  public selectedTags: string[] = []; //tags which are entered or selected by user
  private availableTags: string[] = this.deviceService.availableDeviceTags; //all available tags for a user
  public filteredTags: string[] = []; //will be used to show filtered tags after search
  private tempVarStore: any = '';
  public options = {
    threshold: 0.5,
  };
  public fuse = new Fuse(this.availableTags, this.options);

  constructor(private deviceService: DeviceService) {}

  ngOnInit() {
    this.addSubscription();
    if (this.existingTags.length) {
      this.selectedTags = this.existingTags;
    }
    this.filteredTags = this.availableTags;
  }

  addSubscription() {
    this.tagInputFormControl.valueChanges.subscribe((res) => {
      if (res === '') {
        this.filteredTags = this.availableTags;
        return;
      }
      this.filteredTags = this.fuse.search(res).map((result:any) => result.item);
    });
  }

  add(input: MatChipInputEvent): void {
    // when added through onOptionSelected(), add() also triggers, to stop that this check is added
    if (!this.tempVarStore?.length) {
      const selectedTag = input.value;
      if (selectedTag && !this.selectedTags.includes(selectedTag)) {
        this.selectedTags.push(selectedTag);
      }
    }
    this.tempVarStore = '';
    this.getTags.emit(this.selectedTags);
    input.chipInput!.clear();
    this.tagInputFormControl.setValue('');
  }

  remove(currentValue: any, index: number): void {
    this.selectedTags.splice(index, 1);
    this.getTags.emit(this.selectedTags);
  }

  onOptionSelected(event: any): void {
    const selectedTag = event.option.value;
    this.tempVarStore = selectedTag;

    if (selectedTag && !this.selectedTags.includes(selectedTag)) {
      this.selectedTags.push(selectedTag);
    }
    this.getTags.emit(this.selectedTags);
  }

  // selectionMade(event: Event, trigger: MatAutocompleteTrigger) {
  //   event.stopPropagation();
  //   event.preventDefault();
  //   if (trigger.panelOpen) {
  //     trigger.closePanel();
  //   } else {
  //     trigger.openPanel();
  //   }
  // }
}
