import { Injectable } from '@angular/core';
import { BaseAPIService } from './base-service';
import { AppConstants } from '../constants/app-constants';
import { APIConstants } from '../constants/api-constants';
import { HttpClient } from '@angular/common/http';
import { CookieService } from './cookie.service';
import { ResetPassword } from '../models/forgot-password/forgot-password';

@Injectable({
  providedIn: 'root',
})
export class ResetPswdService extends BaseAPIService<any> {
  constructor(http: HttpClient, cookieService: CookieService) {
    super(http, cookieService);
  }

  verifyUserToken(userToken: string) {
    return this.get<any>(APIConstants.VERIFY_USER_TOKEN(userToken));
  }

  resetPassword(payload: ResetPassword, userToken: string) {
    return this.post<string>(
      APIConstants.RESET_USER_PASSWORD(userToken),
      payload
    );
  }
}
