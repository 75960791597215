import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  GoogleMap,
  MapCircle,
  MapInfoWindow,
  MapMarker,
} from '@angular/google-maps';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { NbThemeService } from '@nebular/theme';
import { MTX_DRAWER_DATA } from '@ng-matero/extensions/drawer';
import * as turf from '@turf/turf';
import {
  EMPTY,
  Subject,
  Subscription,
  delay,
  filter,
  map,
  of,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { AnnouncementComponent } from 'src/app/shared/components/announcement/announcement.component';
import { WindMapType } from 'src/app/shared/components/map-components/wind-map-type';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { DeviceConstants } from 'src/app/shared/constants/device-constants';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { Cluster } from 'src/app/shared/models/cluster.ts/cluster';
import { Complain } from 'src/app/shared/models/complain';
import { DeviceType } from 'src/app/shared/models/device-type/device-type';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { DeviceField } from 'src/app/shared/models/device/device-field';
import { DeviceLabelOptions } from 'src/app/shared/models/device/device-lable-options';
import { FieldLimit } from 'src/app/shared/models/device/field-limit';
import { ClusterService } from 'src/app/shared/services/cluster.services';
import { CommonService } from 'src/app/shared/services/common.service';
import { ComplainsService } from 'src/app/shared/services/complains.service';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { GoogleMapsService } from 'src/app/shared/services/google-maps.service';
import { LoadrService } from 'src/app/shared/services/loadr.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { CommonUtil } from 'src/app/shared/utils/common-utils';
import { DeviceUtil } from 'src/app/shared/utils/device-utils';
import { environment } from 'src/environments/environment';

import Map = google.maps.Map;
import MapOptions = google.maps.MapOptions;

type LatLngLiteral = google.maps.LatLngLiteral;

interface Food {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-cluster-form',
  templateUrl: './cluster-form.component.html',
  styleUrls: ['./cluster-form.component.scss'],
})
export class ClusterFormComponent {
  @ViewChild(MatStepper) public stepper!: MatStepper;

  @ViewChild(GoogleMap) public googleMap!: GoogleMap;

  @ViewChild(MapCircle) public mapCircle!: MapCircle;

  @ViewChildren('deviceInfoWindow')
  public deviceInfoWindows!: QueryList<MapInfoWindow>;

  @ViewChildren('deviceInfoWindows2')
  public deviceInfoWindows2!: QueryList<MapInfoWindow>;

  @ViewChildren('complainInfoWindow')
  public complainInfoWindows!: QueryList<MapInfoWindow>;

  @ViewChildren('industryInfoWindow')
  public industryInfoWindows!: QueryList<MapInfoWindow>;

  @ViewChildren(MapMarker) public mapMarkers!: QueryList<MapMarker>;

  @ViewChild(MapInfoWindow, { static: false }) infoWindow!: MapInfoWindow;

  @Input() latitude: number | undefined;
  @Input() longitude: number | undefined;

  public clusterForm!: FormGroup;
  public deviceDetailForm!: FormGroup;
  public mapDetailForm!: FormGroup;
  public deviceTypes!: Array<DeviceType>;
  public DeviceLabel!: Array<DeviceLabelOptions>;
  clusterData: Cluster[] = [];

  public currentDeviceType: string = '';
  public isSelected: boolean = true;
  public parametersList: Array<number> = [1];
  public isEmailValid = true;
  public isNew: boolean = true;
  mapInitialized: boolean = false;
  hasComplainsAccess: boolean = false;
  complainLayerEnabled: boolean = false;
  complains?: Array<Complain.Get>;
  showWhatsNewPopupAccess: boolean = false;
  popupCounter: number = 0;
  iconSize: any = null;
  anchor: any = null;
  selectedIconSize: any = null;
  selectedAnchor: any = null;
  fields: DeviceField[] = [];
  limits: FieldLimit[] = [];
  CronJob = require('cron').CronJob;
  currentTimeStamp: number = this.customMomentService.moment().unix();
  rawAqiLabel: string = AppConstants.RAW_AQI_LABEL;
  ozMapPinColor: string = AppConstants.DEFAULT_APP_COLOR;
  showAqiGauge: boolean = AppConstants.SHOW_RAW_DATA_AQI;
  isMapPinFixed: boolean = false;
  selectedDevice?: DeviceDetails;
  isComplainModuleIncluded: boolean = false;
  isDeviceCentered: boolean = false;
  doc: any;
  options: MapOptions = AppConstants.GOOGLE_MAPS_OPTIONS;
  temperature?: number;
  humidity?: number;
  windSpeed?: number;
  batteryStatus?: number;
  noiseVal: number[] = [0, 0, 0, 0, 0];
  gasLevels: any[] = [];
  @Input() ariaLabel: any;
  paramColor: any = {};
  openPanel: boolean = false;
  industryLayerEnabled: boolean = false;
  industries: any[] = [];
  fullScreenMode: string = 'zoom_out_map';
  currentMapType: string = 'roadmap';
  hasLayer5000: boolean = false;
  hasLayer5001: boolean = false;
  hasLayer5002: boolean = false;
  trafficLayerEnabled: boolean = false;
  trafficLayer?: google.maps.TrafficLayer;
  windLayerEnabled: boolean = false;
  selectedWeatherLayer: any[] = [];
  weatherApiKey: string = environment.openWeatherAPI;
  subscriptions: Subscription[] = [];
  devices?: DeviceDetails[];
  openedForm: any = '';
  geojson: any = {};
  geojsonLatLng!: any;
  selectedAqi: any;
  selectedDevices!: any;
  filteredSelectedDevices!: any;
  clusterLabel: string = '';
  clusterDescription: string = '';
  selectedDevicesLabels: any = [];
  checkboxValue: any;
  allDevices: any[] = [];
  ismapReady: any;
  isMapLoaded: boolean = false;
  markers: Array<GoogleMapsService> = [];
  infoWindowPosition: LatLngLiteral = { lat: 23, lng: 72 };
  zoomGoogleMap: any = 4;
  mapOptions: google.maps.MapOptions = {
    center: { lat: 0, lng: 0 },
    zoom: 10,
  };
  drawingManager: google.maps.drawing.DrawingManager | undefined;
  allAqiList: any;
  circle: any = { lat: null, lng: null, radius: 0 };
  latLngNumber: LatLngLiteral = { lat: 0, lng: 0 };
  drawnPolygon: google.maps.Polygon | null = null;
  radiusNumber: any = 0;
  aqi: any;
  submitBtn: string = 'Next';
  backBtn: string = 'Cancel';
  foods: Food[] = [
    { value: 'steak-0', viewValue: 'Steak' },
    { value: 'pizza-1', viewValue: 'Pizza' },
    { value: 'tacos-2', viewValue: 'Tacos' },
  ];
  selectedDeviceTypeId!: any;
  selectedDevicesControl: any;
  map: any;
  isNextStep: boolean = false;
  selectedIndex: number = -1;
  isStepReadOnly: boolean = true;
  errorMsgForSelectedDev: boolean = false;
  clusterDeviceTypes: any;
  filteredAllDevType: any;
  allAqisList: any;
  filteredAllAqisList: any;
  ozAllDevType: any;
  filteredOZAllDevType: any;
  AqiKeys: any;
  isEdit: any;
  private destroy$: Subject<void> = new Subject<void>();
  public circleOptions: google.maps.CircleOptions = {};
  public polygonOptions: google.maps.PolygonOptions = {};
  public currentTheme: string = '';

  constructor(
    private formsService: FormsService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private deviceService: DeviceService,
    public googleMapsService: GoogleMapsService,
    private localStorageService: LocalStorageService,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private complainsService: ComplainsService,
    private snackBar: MatSnackBar,
    private themeService: NbThemeService,
    private clusterService: ClusterService,
    private customMomentService: CustomMomentService,
    private loadrService: LoadrService,
    private notificationService: NotificationService,
    @Inject(MTX_DRAWER_DATA) public data: any
  ) {}

  ngOnInit() {
    this.deviceTypes = this.commonService.getUserDeviceTypes();
    this.isEdit = !!this.data;
    this.currentTimeStamp = this.customMomentService.moment().unix();
    this.buildForm();
    this.deviceDetailForm.valueChanges.subscribe(() => {
      this.isNextStep =
        this.deviceDetailForm.valid &&
        this.deviceDetailForm.get('clusterName')?.value;
    });
    this.mapDetailForm.valueChanges.subscribe(() => {
      this.isNextStep =
        this.mapDetailForm.valid &&
        this.mapDetailForm.value.selectedDevicesControl;
    });
    this.showWhatsNewPopupAccess = this.localStorageService
      .getParsedValue(LocalStorageConstants.POPUP_TYPES)
      .includes(1002);
    this.devices = this.deviceService.registeredDevices
      ? [...this.deviceService.registeredDevices!]
      : [];
    this.fields = this.deviceService.fields;
    this.limits = this.deviceService.limits;
    this.allAqiList = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_AQI
    );
    this.allAqisList = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_AQIS
    );
    this.ozAllDevType = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_DEV_TYPE
    );
    this.filteredOZAllDevType = this.ozAllDevType.reduce(
      (item: any, value: any) => {
        item[value.deviceTypeId] = {
          deviceTypeId: value.deviceTypeId,
          calibration_config: value.calibration_config,
          config: value.config,
          description: value.description,
          icon: value.icon,
          index: value.index,
          indexLabel: value.indexLabel,
          key: value.key,
          label: value.label,
          updated_on: value.updated_on,
          widgets: value.widgets,
        };
        return item;
      },
      {}
    );
    this.googleMapsService.isApiLoaded
      .pipe(
        filter(Boolean),
        delay(1),
        switchMap(() =>
          this.googleMap?.idle ? of(this.googleMap?.idle) : EMPTY
        ),
        take(1),
        tap(() => this.mapReady(this.googleMap?.googleMap as any as Map)),
        switchMap(() =>
          this.googleMap!.zoomChanged ? of(this.googleMap!.zoomChanged) : EMPTY
        )
      )
      .subscribe(() => {});
    this.intiPopupCounter();
    this.clusterForm?.get('condition')?.valueChanges.subscribe((res) => {
      if (res === 'or') {
        this.parametersList = [1];
      } else {
        this.parametersList = [1, 1];
      }
    });
    if (this.isEdit) {
      this.onDeviceTypeChange(this.data.deviceTypeId);
      this.aqi =
        this.allAqiList?.[this.deviceDetailForm?.get('deviceType')?.value];
      this.selectedAqi = this.aqi
        ? this.aqi?.[this.data.aqi_index_id].aqiIndexId
        : '';
      this.deviceDetailForm.get('selectAqi')?.setValue(this.selectedAqi);

      this.deviceDetailForm.get('deviceType')?.disable();
      // this.deviceDetailForm.get('clusterName')?.disable();
      this.mapDetailForm.get('deviceOption')?.disable();
      this.mapDetailForm.get('selectedDevicesControl')?.disable();
      this.mapDetailForm.get('description')?.disable();
    }
  }

  ngAfterViewInit(): void {
    this.addSubscriptions();
    let firstTimeLoaded = false;
    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => {
        let options = this.options;
        if (themeName == 'material-dark') {
          this.currentTheme = themeName;
          options.styles = [...AppConstants.DARK_GOOGLE_MAPS_STYLES];
          this.circleOptions =
            AppConstants.DARK_MODE_GOOGLE_MAPS_CIRCLE_OPTIONS;
          this.polygonOptions =
            AppConstants.DARK_MODE_GOOGLE_MAPS_POLYGON_OPTIONS;
        } else {
          this.currentTheme = themeName;
          options.styles = [...AppConstants.LIGHT_GOOGLE_MAPS_STYLES];
          this.circleOptions = {}; //pass empty so that it take default values
          this.polygonOptions = {};
        }
        this.options = { ...options };
        if (this.selectedDevice) {
          setTimeout(() => {
            this.focusSelectedDevice();
          });
        } else if (firstTimeLoaded) {
          setTimeout(() => {
            this.fitBounds(this.googleMap.googleMap!);
          });
        }
        firstTimeLoaded = true;
      });
  }

  onStepperSelectionChange(event: any) {
    if (event.selectedIndex === 0) {
      this.submitBtn = 'Next';
      this.backBtn = 'Cancel';
    } else if (event.selectedIndex === 1) {
      this.submitBtn = 'Submit';
      this.backBtn = 'Back';
    }
    if (this.isEdit) {
      if (this.data?.config?.geojson) {
        this.filteredAllDevType = [];
        this.filteredAllDevType = this.localStorageService
          .getParsedValue(LocalStorageConstants.OZ_ALL_DEV_TYPE)
          ?.filter(
            (type: any) =>
              type.deviceTypeId ===
              this.deviceDetailForm?.get('deviceType')?.value
          )[0];
        this.formTypeChange('2');
      } else {
        this.filteredAllDevType = [];
        this.filteredAllDevType = this.localStorageService
          .getParsedValue(LocalStorageConstants.OZ_ALL_DEV_TYPE)
          ?.filter(
            (type: any) =>
              type.deviceTypeId ===
              this.deviceDetailForm?.get('deviceType')?.value
          )[0];
        this.formTypeChange('1');
        this.selectedDevices = [];
        for (let deviceId of this.data?.config?.devices) {
          if (this.allDevices?.some((device) => device.deviceId === deviceId)) {
            this.selectedDevices.push(deviceId);
          }
        }
        this.addDevice(this.selectedDevices);
      }
    }
  }

  submitAndNext() {
    if (this.stepper.selectedIndex == 0) {
      this.stepper.next();
      this.submitBtn = 'Submit';
      this.backBtn = 'Back';
    }
    if (this.isEdit) {
      if (this.data?.config?.geojson) {
        this.filteredAllDevType = [];
        this.filteredAllDevType = this.localStorageService
          .getParsedValue(LocalStorageConstants.OZ_ALL_DEV_TYPE)
          ?.filter(
            (type: any) =>
              type.deviceTypeId ===
              this.deviceDetailForm?.get('deviceType')?.value
          )[0];
        this.formTypeChange('2');
      } else {
        this.filteredAllDevType = [];
        this.filteredAllDevType = this.localStorageService
          .getParsedValue(LocalStorageConstants.OZ_ALL_DEV_TYPE)
          ?.filter(
            (type: any) =>
              type.deviceTypeId ===
              this.deviceDetailForm?.get('deviceType')?.value
          )[0];
        this.formTypeChange('1');
        this.selectedDevices = [];
        for (let deviceId of this.data?.config?.devices) {
          if (this.allDevices?.some((device) => device.deviceId === deviceId)) {
            this.selectedDevices.push(deviceId);
          }
        }
        this.addDevice(this.selectedDevices);
      }
    }
  }
  previous() {
    this.stepper.previous();
    if (this.stepper.selectedIndex === 0) {
      this.isNextStep = this.deviceDetailForm.valid;
      this.submitBtn = 'Next';
      this.backBtn = 'Cancel';
    }
  }

  buildForm() {
    this.deviceDetailForm = this.formBuilder.group({
      deviceType: [
        this.isEdit
          ? DeviceUtil.getDeviceTypeByKeyOrId(
              this.data.config.deviceType,
              this.deviceTypes
            )?.deviceTypeId
          : '',
        Validators.required,
      ],
      clusterName: [this.isEdit ? this.data.label : '', Validators.required],
      selectAqi: [''],
    });
    this.mapDetailForm = this.formBuilder.group({
      deviceOption: [
        this.isEdit ? (this.data?.config?.geojson ? '2' : '1') : '',
        Validators.required,
      ],
      selectedDevicesControl: [
        this.isEdit ? this.data.config.devices : [],
        [
          Validators.required,
          Validators.minLength(2),
        ],
      ],
      selectAll: [false],
      description: [this.isEdit ? this.data?.description : ''],
    });
  }

  addParameterField() {
    this.parametersList.push(1);
  }

  removeParameterField(index: any) {
    const halfBeforeTheUnwantedElement = this.parametersList.slice(0, index);
    const halfAfterTheUnwantedElement = this.parametersList.slice(index + 1);
    const copyWithoutUnwantedElement = halfBeforeTheUnwantedElement.concat(
      halfAfterTheUnwantedElement
    );
    // Update the original array with the modified one
    this.parametersList = copyWithoutUnwantedElement;
  }

  checkEmail(event: any) {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (validRegex.test(event.target.value)) {
      this.isEmailValid = true;
    } else {
      this.isEmailValid = false;
    }
  }

  closeForm(data: boolean = false) {
    this.formsService.closeForm(data);
  }

  initializeData(): void {
    this.initJobs();
    this.latestDocumentSnapshot();
    this.cd.detectChanges();
  }

  initJobs(): void {
    const device_Types: DeviceType[] = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_DEV_TYPE
    );
    const allAqi: any = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_AQI
    );
    const allAqis: any = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_AQIS
    );
    const aqi: any = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_AQI
    );

    let that = this;
    if (this.devices) {
      new this.CronJob(
        '0/5 * * * * *',
        () => {
          that.currentTimeStamp = this.customMomentService.moment().unix();
          that.devices?.forEach((device) => {
            const deviceType = DeviceUtil.findDeviceType(
              device.deviceType,
              that.commonService.getUserDeviceTypes()
            );
          });
        },
        null,
        true
      );
    }
  }

  mapReady(map: Map): void {
    this.mapInitialized = true;

    this.initializeData();
    if (this.devices && this.devices.length > 0) {
      this.fitBounds(map);
    }
    this.setSelectedDevice();

    if (
      this.localStorageService
        .getParsedValue(LocalStorageConstants.MODULE_ACCESS)
        .indexOf(1009) >= 0
    ) {
      this.hasComplainsAccess = true;
      this.complainLayerEnabled = true;
    }

    this.googleMap.controls[google.maps.ControlPosition.RIGHT_TOP].push(
      document.getElementById('map-types-control-cluster')!
    );
    this.googleMap.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
      document.getElementById('zoom-control-cluster')!
    );
    this.googleMap.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
      document.getElementById('fullscreen-control-cluster')!
    );
    this.googleMap.controls[google.maps.ControlPosition.BOTTOM_RIGHT]?.push(
      document.getElementById('map-reset')!
    );

    if (this.showWhatsNewPopupAccess === true && this.popupCounter === 0) {
      this.showWhatsNewPopUp();
    }

    this.iconSize = new google.maps.Size(
      DeviceConstants.MAP_MARKER_SIZE_CLUSTER.width,
      DeviceConstants.MAP_MARKER_SIZE_CLUSTER.height
    );
    this.anchor = new google.maps.Point(
      DeviceConstants.MAP_MARKER_ANCHOR.x,
      DeviceConstants.MAP_MARKER_ANCHOR.y
    );

    this.selectedIconSize = new google.maps.Size(
      DeviceConstants.MAP_SELECTED_MARKER_SIZE.width,
      DeviceConstants.MAP_SELECTED_MARKER_SIZE.height
    );
    this.selectedAnchor = new google.maps.Point(
      DeviceConstants.MAP_SELECTED_MARKER_ANCHOR.x,
      DeviceConstants.MAP_SELECTED_MARKER_ANCHOR.y
    );
  }

  initDrawingManager(map: any, coordinates?: any) {
    if (!this.googleMap) {
      console.info('Map instance not available');
      return;
    }
    const options = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: [google.maps.drawing.OverlayType.POLYGON],
      },
      polygonOptions: {
        draggable: false,
        editable: false,
        ...this.polygonOptions,
      },
      drawingMode: coordinates?.length
        ? null
        : google.maps.drawing.OverlayType.POLYGON,
    };
    this.drawingManager = new google.maps.drawing.DrawingManager(options);
    this.drawingManager.setMap(map);

    if (coordinates?.length) {
      this.drawnPolygon = new google.maps.Polygon({
        paths: coordinates,
        map,
        editable: false,
        draggable: false,
        ...this.polygonOptions,
      });

      google.maps.event.addListener(
        this.drawnPolygon.getPath(),
        'set_at',
        () => {
          this.handlePolygonEdit();
        }
      );
      google.maps.event.addListener(
        this.drawnPolygon.getPath(),
        'insert_at',
        () => {
          this.handlePolygonEdit();
        }
      );
      const coordinatesArray = this.drawnPolygon
        .getPath()
        .getArray()
        .map((latLng: google.maps.LatLng) => [latLng.lng(), latLng.lat()]);
      this.checkAllDevices(coordinatesArray);
    }

    google.maps.event.addListener(
      this.drawingManager,
      'overlaycomplete',
      (event: any) => {
        this.drawnPolygon = event.overlay;
        this.drawingManager?.setOptions({ drawingControl: false });
        this.drawingManager?.setDrawingMode(null);
        if (event.type === google.maps.drawing.OverlayType.POLYGON) {
          const coordinates = event.overlay
            .getPath()
            .getArray()
            .map((latLng: google.maps.LatLng) => {
              return [latLng.lng(), latLng.lat()];
            });
          this.checkAllDevices(coordinates);
        }
      }
    );
  }

  handlePolygonEdit() {
    const updatedCoordinates = this.drawnPolygon
      ?.getPath()
      ?.getArray()
      ?.map((latLng: google.maps.LatLng) => [latLng.lng(), latLng.lat()]);
    this.checkAllDevices(updatedCoordinates);
  }

  checkAllDevices(ltLg: any) {
    let latLng = [];
    latLng = ltLg.map((crd: any) => [crd[0], crd[1]]);
    latLng.push(latLng[0]);
    let poly: turf.Feature<turf.Polygon> = {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Polygon',
        coordinates: [latLng],
      },
    };
    let geojson = {
      type: 'FeatureCollection',
      features: [poly],
    };
    let allDevs = [];
    for (let device of this.allDevices) {
      let pnt = turf.point([device.longitude, device.latitude]);
      let isInside = turf.inside(pnt, poly);
      if (isInside === true) {
        allDevs.push(device.deviceId);
      }
    }

    let plgn = turf.polygon([latLng]);
    let geojsonCenter = turf.centroid(plgn);
    this.geojsonLatLng = geojsonCenter.geometry.coordinates;
    if (allDevs.length > 0) {
      this.addDevice(allDevs);
      this.geojson = poly;
    } else {
      this.notificationService.showNotification(
        'No Device found in region.',
        'Close',
        'bottom',
        'center',
        'warning',
        3000
      );
    }
  }

  showWhatsNewPopUp(): void {
    this.dialog
      .open(AnnouncementComponent, { data: {} })
      .afterClosed()
      .subscribe((result) => {
        this.localStorageService.saveValue(
          LocalStorageConstants.POPUP_COUNTER,
          this.localStorageService.getParsedValue(
            LocalStorageConstants.POPUP_COUNTER
          ) + 1
        );
      });
  }

  fitBounds(map: Map, deviceType: string | undefined = undefined): void {
    const latLngBounds = new google.maps.LatLngBounds();
    const markers =
      this.devices
        ?.filter((device) =>
          deviceType ? device.deviceType === deviceType : true
        )!
        .map((device) => {
          if (
            device.latitude &&
            device.longitude &&
            device.latitude !== 0 &&
            device.longitude !== 0
          ) {
            return {
              lat: device.latitude,
              lng: device.longitude,
            };
          } else {
            return {
              lat: device.latitude,
              lng: device.longitude,
            };
          }
        }) ?? [];
    markers.forEach((marker) => {
      latLngBounds.extend(marker);
    });
    map.fitBounds(latLngBounds);
  }

  addSubscriptions(): void {
    const localStorageDeviceUpdate: Subscription =
      this.deviceService.localStorageDeviceUpdate$.subscribe((res) => {
        if (this.deviceService.currentDevice) {
          if (
            this.selectedDevices?.deviceId !==
            this.deviceService.currentDevice?.deviceId
          ) {
            this.setSelectedDevice();
          }
        } else {
          this.selectedDevices = undefined;
          this.latestDocumentSnapshot();
          this.closeInfoWindows();
          this.fitBounds(this.googleMap.googleMap!);
        }
      });
    const captureLatestDocSnapshot: Subscription =
      this.deviceService.mqttDocsSnapshot$.subscribe((doc: any) => {
        if (this.deviceService.mqttDocs) {
          this.latestDocumentSnapshot(doc.deviceId);
        }
      });

    const getDevices: Subscription = this.deviceService.getDevices$.subscribe(
      () => {
        this.devices = [...this.deviceService.registeredDevices!];
        if (
          this.deviceService.mqttDocs &&
          this.devices &&
          this.devices.length !== 0 &&
          this.fields
        ) {
          this.latestDocumentSnapshot();
          this.fitBounds(this.googleMap.googleMap!);
        }
      }
    );

    const getFields: Subscription = this.deviceService.getFields$.subscribe(
      () => {
        this.fields = this.deviceService.fields;
      }
    );

    const getLimits: Subscription = this.deviceService.getLimits$.subscribe(
      () => {
        this.limits = this.deviceService.limits;
      }
    );

    this.subscriptions.push(getDevices);
    this.subscriptions.push(getFields);
    this.subscriptions.push(getLimits);
    this.subscriptions.push(captureLatestDocSnapshot);
    this.subscriptions.push(localStorageDeviceUpdate);
  }

  setSelectedDevice() {
    this.selectedDevices = this.deviceService.currentDevice || undefined;
    if (
      this.selectedDevices?.deviceId &&
      this.deviceService.mqttDocs &&
      this.devices
    ) {
      this.latestDocumentSnapshot();
      this.focusSelectedDevice();
    } else {
      this.closeInfoWindows();
    }
  }

  fetchComplains(): void {
    this.isComplainModuleIncluded = this.localStorageService
      .getParsedValue(LocalStorageConstants.OZ_USER)
      .modules.includes(2015);
    if (
      this.localStorageService
        .getParsedValue(LocalStorageConstants.MODULE_ACCESS)
        .includes(2015) &&
      this.isComplainModuleIncluded === true
    ) {
      const orgId = this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_USER
      ).org;
      this.complainsService
        .getComplainsByOrganization(orgId, undefined, undefined)
        .subscribe((complains) => {
          if (complains.length > 0) {
            complains.forEach((complain) => {
              if (complain.priority === 'HIGH') {
                complain.iconUrl = 'assets/images/pins/complain-red.png';
              }
              if (complain.priority === 'MEDIUM') {
                complain.iconUrl = 'assets/images/pins/complain-yellow.png';
              }
              if (complain.priority === 'LOW') {
                complain.iconUrl =
                  'assets/images/pins/complain-pin-neutral.png';
              }
            });
            this.complains = complains;
          }
        });
    }
  }

  latestDocumentSnapshot(deviceId?: string): void {
    if (
      this.deviceService.mqttDocs &&
      this.devices &&
      this.devices.length > 0 &&
      this.fields
    ) {
      this.isDeviceCentered = false;
      this.doc = this.selectedDevices?.deviceId
        ? this.deviceService.mqttDocs[this.selectedDevices?.deviceId]
        : undefined;
      if (this.doc !== undefined && this.doc.latitude && this.doc.longitude) {
        const latLngBounds = new google.maps.LatLng(
          parseFloat(this.doc.latitude),
          parseFloat(this.doc.longitude)
        );
        if (this.mapInitialized) {
          this.googleMap.center = latLngBounds;
        }
      }

      this.closeInfoWindows();
      const parameters: any[] = [];
      const filteredArray = [];

      if (deviceId) {
        this.setUpMapData(
          this.devices.find((device) => device.deviceId === deviceId)!,
          this.selectedDevices?.deviceId == deviceId
        );
      } else {
        this.devices.forEach((device) =>
          this.setUpMapData(
            device,
            this.selectedDevices?.deviceId == device?.deviceId
          )
        );
      }

      if (this.doc !== undefined) {
        const device_Types: DeviceType[] =
          this.localStorageService.getParsedValue(
            LocalStorageConstants.OZ_ALL_DEV_TYPE
          );
        if (this.doc.payload && this.doc.payload.d) {
          this.temperature = this.doc.payload.d.temp
            ? Math.round(this.doc.payload.d.temp)
            : undefined;
          this.humidity =
            this.doc.payload.d.hum || this.doc.payload.d.hum >= 0
              ? Math.round(this.doc.payload.d.hum)
              : undefined;
          this.windSpeed =
            this.doc.payload.d.ws || this.doc.payload.d.ws >= 0
              ? Math.round(this.doc.payload.d.ws)
              : undefined;
          this.batteryStatus =
            this.doc.payload.d.bs || this.doc.payload.d.bs >= 0
              ? Math.round(this.doc.payload.d.bs)
              : undefined;
          this.noiseVal = this.doc.payload.d.noise
            ? this.doc.payload.d.noise
            : this.doc.payload.d.leq
            ? [
                this.doc.payload.d.leq,
                this.doc.payload.d.lmax,
                this.doc.payload.d.lmin,
                this.doc.payload.d.l90,
                this.doc.payload.d.l10,
              ]
            : undefined;
          const allKeys = Object.keys(this.doc.payload.d);
          const allUnits: any = this.localStorageService.getParsedValue(
            LocalStorageConstants.OZ_ALL_UNITS
          );

          DeviceConstants.MAP_FIELD_ARRAY.forEach((field) => {
            const fieldIndex = parameters.findIndex(
              (param) => param.name === field
            );
            const isValidField = allKeys.find((key) => key === field);
            const availableField = this.fields
              .filter((field) => field.isVisible)
              .find((f) => f.fkey === field);

            if (
              availableField?.isVisible === true &&
              isValidField &&
              fieldIndex < 0
            ) {
              const deviceTypeId = DeviceUtil.getDeviceTypeId(
                this.deviceService.currentDeviceType.key,
                device_Types
              );

              parameters.push({
                name: field,
                value: this.doc.payload.d[field],
                label: DeviceUtil.getFieldName(field, this.fields),
                unit: DeviceUtil.getFieldUnit(
                  field,
                  undefined,
                  allUnits[deviceTypeId!]
                ),
              });
            }
          });

          allKeys.forEach((key) => {
            const fieldIndex = parameters.findIndex(
              (param) => param.name === key
            );
            const field = this.fields
              .filter((field) => field.isVisible)
              .find((field) => field.fkey);

            if (field?.isVisible === true && fieldIndex < 0) {
              const deviceTypeId = DeviceUtil.getDeviceTypeId(
                this.deviceService.currentDeviceType.key,
                device_Types
              );
              parameters.push({
                name: key,
                value: this.doc.payload.d[key],
                label: DeviceUtil.getFieldName(key, this.fields),
                unit: DeviceUtil.getFieldUnit(
                  key,
                  undefined,
                  allUnits[deviceTypeId!]
                ),
              });
              filteredArray.push(key);
            }
          });
        }

        this.gasLevels = [...new Set(parameters)];
        this.paramColor = {};

        const allAqi: any = this.localStorageService.getParsedValue(
          LocalStorageConstants.OZ_ALL_AQI
        );
        const allAqis: any = this.localStorageService.getParsedValue(
          LocalStorageConstants.OZ_ALL_AQIS
        );

        this.gasLevels.forEach((gasLevel) => {
          this.paramColor[gasLevel.name] = DeviceUtil.getParamColor(
            DeviceUtil.getLimitClass(
              gasLevel.name,
              gasLevel.value,
              this.deviceService.limits
            ),
            device_Types,
            allAqi,
            allAqis
          );
        });
      }
    }
  }

  focusSelectedDevice() {
    let map: Map = this.googleMap.googleMap!;
    if (map) {
      const latLngBounds = new google.maps.LatLngBounds();
      const markers = [this.selectedDevices!].map((device) => {
        if (
          device.latitude &&
          device.longitude &&
          device.latitude !== 0 &&
          device.longitude !== 0
        ) {
          return {
            lat: device.latitude,
            lng: device.longitude,
          };
        } else {
          return {
            lat: device.latitude,
            lng: device.longitude,
          };
        }
      });

      markers.forEach((marker) => {
        latLngBounds.extend(marker);
      });

      map.fitBounds(latLngBounds);
      map.setZoom(12);
    }
  }

  closeInfoWindows(): void {
    this.openPanel = false;
    this.deviceInfoWindows2.toArray().forEach((iw) => iw.close());
  }

  setUpMapData(device: DeviceDetails, pinSelected: boolean = false) {
    if (this.deviceService.mqttDocs[device?.deviceId]) {
      const deviceTypes: DeviceType[] = this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_ALL_DEV_TYPE
      );
      const allAqi: any = this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_ALL_AQI
      );
      const allAqis: any = this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_ALL_AQIS
      );
      const aqi: any = this.localStorageService.getParsedValue(
        LocalStorageConstants.OZ_AQI
      );
      device.aqi = this.deviceService.mqttDocs[device?.deviceId].aqi;
      device.payload = this.deviceService.mqttDocs[device?.deviceId].payload;
      device.lastUpdated = this.deviceService.mqttDocs[device?.deviceId].time;

      const deviceType = DeviceUtil.findDeviceType(
        device.deviceType,
        this.commonService.getUserDeviceTypes()
      );
      DeviceUtil.getColorForPinCluster(
        device,
        deviceType,
        deviceType ? deviceType.index : false,
        this.deviceService.mqttDocs,
        this.currentTimeStamp,
        this.showAqiGauge,
        this.ozMapPinColor,
        this.isMapPinFixed,
        deviceTypes,
        allAqi,
        allAqis,
        aqi,
        pinSelected
      );
    }
  }

  toggleIndustryLayer() {
    this.industryLayerEnabled = !this.industryLayerEnabled;

    if (this.industryLayerEnabled) {
      const request = {
        location: { lat: 23.0225, lng: 72.5714 },
        query: 'find industries near me',
        keyword: 'industry',
        types: ['name', 'geometry'],
        radius: 5000000,
      };

      const service = new google.maps.places.PlacesService(
        this.googleMap.googleMap!
      );

      if (this.industries.length <= 0) {
        service.nearbySearch(request, (results, status, pagination) => {
          this.industryLayerEnabled = false;
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            if (pagination?.hasNextPage) {
              pagination.nextPage();
            }
            results?.forEach((result) => {
              const place = {
                name: result.name,
                geometry: result.geometry,
                address: result.vicinity,
                rating: result.rating,
                photo: this.getIndustryImage(result),
              };
              this.industries.push(place);
            });
          } else if (
            status === google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT
          ) {
            console.info('API limit exceeded. Please contact developer.');
          }
        });
      }
    }
  }

  getIndustryImage(result: google.maps.places.PlaceResult): string | undefined {
    return typeof result.photos !== 'undefined'
      ? result.photos[0].getUrl({ maxWidth: 150, maxHeight: 150 })
      : result.icon;
  }

  switchToFullScreen(): void {
    const map = this.googleMap.googleMap?.getDiv().firstChild as HTMLElement;
    if (this.isFullscreen(map)) {
      this.exitFullscreen();
    } else {
      this.requestFullscreen(map);
    }
  }

  isFullscreen(element: HTMLElement): boolean {
    return document.fullscreenElement == element;
  }

  requestFullscreen(element: HTMLElement): void {
    if (element.requestFullscreen) {
      this.fullScreenMode = 'zoom_in_map';
      element.requestFullscreen();
    }
  }

  exitFullscreen(): void {
    if (document.exitFullscreen) {
      this.fullScreenMode = 'zoom_out_map';
      document.exitFullscreen();
    }
  }

  zoomIn(): void {
    this.options = {
      ...this.options,
      center: this.googleMap.getCenter(),
      zoom:
        this.googleMap.getZoom() &&
        this.googleMap.getZoom()! + 1 <= this.options.maxZoom!
          ? this.googleMap.getZoom()! + 1
          : this.options.maxZoom,
    };
  }

  zoomOut(): void {
    this.options = {
      ...this.options,
      center: this.googleMap.getCenter(),
      zoom:
        this.googleMap.getZoom() &&
        this.googleMap.getZoom()! - 1 >= this.options.minZoom!
          ? this.googleMap.getZoom()! - 1
          : this.options.minZoom,
    };
  }

  changeMapType(): void {
    if (this.currentMapType === 'roadmap') {
      this.currentMapType = 'hybrid';
    } else {
      this.currentMapType = 'roadmap';
    }

    this.options = {
      ...this.options,
      mapTypeId: this.currentMapType,
      center: this.googleMap.getCenter(),
      zoom: this.googleMap.getZoom(),
    };
  }

  toggleTrafficLayer(): void {
    this.trafficLayerEnabled = !this.trafficLayerEnabled;

    if (
      this.trafficLayer === undefined ||
      this.trafficLayer.getMap() == undefined ||
      this.trafficLayer.getMap() === null
    ) {
      this.trafficLayer = new google.maps.TrafficLayer();
      this.trafficLayer.setMap(this.googleMap.googleMap!);
    } else {
      this.trafficLayer.setMap(null);
    }
  }

  toggleWindLayer(): void {
    this.windLayerEnabled = !this.windLayerEnabled;

    if (this.windLayerEnabled) {
      this.selectedWeatherLayer.push(AppConstants.MAP_WEATHER_LAYERS[0]);
      this.addWindLayer();
    } else {
      this.removeWindLayer();
    }
  }

  addWindLayer() {
    const weatherMapProvider = `https://tile.openweathermap.org/map/${this.selectedWeatherLayer[0]['layer_option']['layer_value']}/`;
    this.googleMap.overlayMapTypes.clear();
    this.googleMap.overlayMapTypes.insertAt(
      0,
      new WindMapType(
        new google.maps.Size(256, 256, 'px', 'px'),
        weatherMapProvider,
        this.weatherApiKey
      )
    );
  }

  removeWindLayer() {
    this.googleMap.overlayMapTypes.clear();
    this.selectedWeatherLayer = [];
  }
  intiPopupCounter(): void {
    if (
      !this.localStorageService.getParsedValue(
        LocalStorageConstants.POPUP_COUNTER
      )
    ) {
      this.localStorageService.saveValue(
        LocalStorageConstants.POPUP_COUNTER,
        '0'
      );
    }
    this.popupCounter = this.localStorageService.getParsedValue(
      LocalStorageConstants.POPUP_COUNTER
    );
  }

  formTypeChange(value: any) {
    this.openedForm = '';
    this.openedForm = value;
    if (this.openedForm == '2') {
      if (this.isEdit) {
        const savedCoordinates =
          this.data?.config?.geojson?.geometry?.coordinates;
        if (savedCoordinates) {
          const coordinates = savedCoordinates[0].map((point: number[]) => ({
            lat: point[1],
            lng: point[0],
          }));
          this.initDrawingManager(this.googleMap?.googleMap, coordinates);
        }
      } else {
        this.resetForm('');
        this.notificationService.showNotification(
          'Start Drawing on Map.',
          'Close',
          'bottom',
          'center',
          'info',
          3000
        );
        this.initDrawingManager(this.googleMap?.googleMap);
      }
    } else if (this.openedForm == '1') {
      this.resetForm('');
      this.drawingManager?.setOptions({
        drawingControl: false,
        drawingMode: null,
      });
      if (this.drawnPolygon) {
        this.drawnPolygon.setMap(null);
        this.drawnPolygon = null;
      }
    }

    if (this.googleMap?.googleMap) {
      this.fitBounds(
        this.googleMap.googleMap,
        DeviceUtil.getDeviceTypeKeyByDeviceTypeId(
          this.deviceTypes,
          this.deviceDetailForm?.get('deviceType')?.value
        )
      );
    }
  }

  resetForm(value: string) {
    this.geojsonLatLng = [];
    this.geojson = {};
    this.selectedDevices = [];
    this.selectedDevicesLabels = [];
    this.circle = { lat: null, lng: null, radius: null };
    this.latLngNumber = { lat: 0, lng: 0 };
    if (this.drawnPolygon) {
      this.drawnPolygon.setMap(null);
      this.drawnPolygon = null;
    }
    if (value == 'clicked') {
      if (this.openedForm == 2) {
        this.notificationService.showNotification(
          'Start Drawing on Map.',
          'Close',
          'bottom',
          'center',
          'info',
          3000
        );
        this.initDrawingManager(this.googleMap?.googleMap);
      }
    }
    this.addDevice(this.selectedDevices);
  }

  getDevices(deviceTypeId: any) {
    const device_Types: DeviceType[] = this.localStorageService.getParsedValue(
      LocalStorageConstants.OZ_ALL_DEV_TYPE
    );
    this.allDevices = [];
    if (deviceTypeId) {
      this.allDevices =
        this.devices?.filter(
          (device) =>
            DeviceUtil.getDeviceTypeId(device.deviceType, this.deviceTypes) ===
            deviceTypeId
        ) ?? [];
    } else {
      this.allDevices = JSON.parse(JSON.stringify(this.devices));
    }
    for (let device of this.allDevices) {
      device.iconUrl = '/assets/images/pins/3rd_party_device_pin_modal.svg';
      device.labelOptions = {};
      device.labelOptions.color = '#000000';
      device.labelOptions.fontFamily = 'monospace';
      device.labelOptions.fontSize = '25px';
      device.labelOptions.fontWeight = 'bolder';
      device.labelOptions.text = ' ';
    }
    this.allDevices.forEach((device) =>
      this.setUpMapData(
        device,
        this.selectedDevices?.deviceId == device?.deviceId
      )
    );
  }

  handleButtonClick() {}

  addDeviceToCluster(deviceId: any) {
    let filteredDevice = `['${deviceId}']`;
    if (this.selectedDevices.includes(deviceId)) {
      let ind = this.selectedDevices.indexOf(filteredDevice as never);
      this.selectedDevices.splice(ind, 1);
    } else {
      this.selectedDevices.push(deviceId as never);
      this.addDevice(this.selectedDevices);
    }
  }

  markerOptions(device: any): google.maps.MarkerOptions {
    let filteredDeviceID = `['${device.deviceId}']`;
    return {
      zIndex: this.selectedDevices == filteredDeviceID ? 0 : 1,
      icon: {
        url: device.iconUrl,
        anchor:
          this.selectedDevices &&
          this.selectedDevices.deviceId === device.deviceId
            ? this.selectedAnchor
            : this.anchor,
        size:
          this.selectedDevices &&
          this.selectedDevices.deviceId === device.deviceId
            ? this.selectedIconSize
            : this.iconSize,
      },
    };
  }

  openInfoWindow(
    marker: MapMarker,
    index: number,
    type: string,
    device?: DeviceDetails,
    selectDevice: boolean = true
  ): void {
    this.closeInfoWindows();
    switch (type) {
      case 'devices':
        this.openPanel = true;
        const deviceInfoWindow = this.deviceInfoWindows?.toArray()[index];
    }
  }
  openInfo(marker: MapMarker, index: number) {
    this.deviceInfoWindows2.forEach((infoWindow: MapInfoWindow) => {
      infoWindow.close();
    });

    this.deviceInfoWindows2.get(index)?.open(marker);
  }

  removeDeviceFromCluster(removeDeviceId: any) {
    const index = this.selectedDevices.indexOf(removeDeviceId);
    this.selectedDevices.splice(index, 1);
    if (index !== -1) {
      if (this.selectedDevices.length !== this.allDevices) {
        this.mapDetailForm?.get('selectAll')?.setValue(false);
      }
      if (this.selectedDevices.length == 0) {
        this.selectedDevices = [];
        this.selectedDevicesLabels = [];
        this.openedForm = '';
        this.geojsonLatLng = [];
        this.geojson = {};
        this.circle = { lat: null, lng: null, radius: null };
        this.latLngNumber = { lat: 0, lng: 0 };
        if (this.drawnPolygon) {
          this.drawnPolygon.setMap(null);
          this.drawnPolygon = null;
        }
      }
      this.addDevice(this.selectedDevices);
    }
  }

  addDevice(value: any) {
    this.selectedDevices = [];
    let d: any = [];
    let turfPoint: any = [];
    this.selectedDevices = value.length >= 0 ? value : value.value;
    if (this.selectedDevices?.length < 2) {
      this.errorMsgForSelectedDev = true;
    }else{
      this.errorMsgForSelectedDev = false;
    }
    const selectedDevicesControl = this.mapDetailForm.get(
      'selectedDevicesControl'
    );
    if (selectedDevicesControl) {
      selectedDevicesControl.setValue(this.selectedDevices);
    }
    this.allDevices.forEach((device: any) => {
      if (this.selectedDevices.includes(device.deviceId as never)) {
        device.labelOptions = {};
        device.labelOptions.color =
          this.currentTheme === 'material-dark' ? '#ffffff' : '#000000';
        device.labelOptions.fontFamily = 'monospace';
        device.labelOptions.fontSize = '25px';
        device.labelOptions.fontWeight = 'bolder';
        device.labelOptions.text = '✔';
        d.push({ label: device.label, deviceId: device.deviceId, device });
        turfPoint.push(turf.point([device.latitude, device.longitude]));
      } else {
        device.labelOptions = {};
        device.labelOptions.color = '#000000';
        device.labelOptions.fontFamily = 'monospace';
        device.labelOptions.fontSize = '25px';
        device.labelOptions.fontWeight = 'bolder';
        device.labelOptions.text = ' ';
      }
    });
    this.selectedDevicesLabels = d;
    this.selectedDevicesControl = this.selectedDevicesLabels.deviceId;

    if (turfPoint.length > 0 && this.selectedDevices.length > 1) {
      let features = turf.featureCollection(turfPoint);
      let cent = turf.center(features);
      this.circle.lat = cent.geometry.coordinates[0];
      this.circle.lng = cent.geometry.coordinates[1];
      let radius: any = [];
      this.allDevices.forEach((device: any) => {
        if (this.selectedDevices.indexOf(device.deviceId as never) > -1) {
          let from = turf.point([this.circle.lng, this.circle.lat]);
          let to = turf.point([device.longitude, device.latitude]);
          let distance = turf.distance(from, to, { units: 'meters' });
          radius.push(distance);
        }
      });
      if (radius.length > 0) {
        this.circle.radius = Math.max(...radius) * 1.2;
        this.latLngNumber = {
          lat: this.circle.lat,
          lng: this.circle.lng,
        };
      } else {
        this.latLngNumber = { lat: 0, lng: 0 };
        this.circle.radius = null;
      }
    } else {
      this.latLngNumber = { lat: 0, lng: 0 };
      this.circle.lat = null;
      this.circle.lng = null;
      this.circle.radius = null;
    }
  }

  onDeviceTypeChange(event: any) {
    this.geojsonLatLng = [];
    this.geojson = {};
    this.filteredAllDevType = [];
    this.selectedDevices = [];
    this.selectedDevicesLabels = [];
    this.selectedAqi = undefined;
    this.circle = { lat: null, lng: null, radius: null };
    this.latLngNumber = { lat: 0, lng: 0 };
    this.allDevices = [];
    this.aqi =
      this.allAqisList[this.deviceDetailForm?.get('deviceType')?.value];
    this.selectedAqi = this.aqi ? this.aqi.id : '';
    this.clusterDeviceTypes =
      this.filteredOZAllDevType[
        this.deviceDetailForm?.get('deviceType')?.value
      ];
    this.filteredAllDevType = this.localStorageService
      .getParsedValue(LocalStorageConstants.OZ_ALL_DEV_TYPE)
      ?.filter(
        (type: any) =>
          type.deviceTypeId === this.deviceDetailForm?.get('deviceType')?.value
      )[0];
    if (this.filteredAllDevType?.index) {
      this.deviceDetailForm
        .get('selectAqi')
        ?.setValidators(Validators.required);
      this.deviceDetailForm
        .get('selectAqi')
        ?.updateValueAndValidity({ emitEvent: false });
    } else {
      this.deviceDetailForm.get('selectAqi')?.clearValidators();
      this.deviceDetailForm
        .get('selectAqi')
        ?.updateValueAndValidity({ emitEvent: false });
    }
    // this.deviceDetailForm.get('selectAqi')?.setValue(this.selectedAqi);
    if (this.drawnPolygon) {
      this.drawnPolygon.setMap(null);
      this.drawnPolygon = null;
    }
    if (event?.value?.config?.deviceId) {
      this.fitBounds(
        this.googleMap.googleMap!,
        DeviceUtil.getDeviceTypeKeyByDeviceTypeId(
          this.deviceTypes,
          this.deviceDetailForm?.get('deviceType')?.value
        )
      );
    }
    this.getDevices(this.deviceDetailForm?.get('deviceType')?.value);
    this.getAqiKeys();
  }

  // handleTouchStart(event: TouchEvent) {
  //   event.preventDefault();
  //   const touchX = event.touches[0].clientX;
  //   const touchY = event.touches[0].clientY;
  // }

  selectAllDevices(event: any, devices: any) {
    if (event.checked === true) {
      this.selectedDevices = [];
      for (let device of devices) {
        let allselectedDev = JSON.parse(JSON.stringify(this.allDevices));
        const index = allselectedDev.indexOf(device.deviceId);
        if (index >= 0) {
          allselectedDev.splice(index, 1);
        }
        this.selectedDevices.push(device.deviceId);
        let a = this.selectedDevices.indexOf(device.deviceId);
        if (a < 0) {
          this.selectedDevices.push(device.deviceId);
        }
      }
    } else {
      this.selectedDevices = [];
    }
    this.addDevice(this.selectedDevices);
  }

  onSubmit() {
    let deviceType = DeviceUtil.getDeviceTypeByKeyOrId(
      this.deviceDetailForm.get('deviceType')?.value,
      this.deviceTypes
    );
    if (this.mapDetailForm.get('selectedDevicesControl')?.value?.length < 2) {
      this.errorMsgForSelectedDev = true;
      return;
    } else {
      this.loadrService.showLoader();
      if (this.mapDetailForm.valid) {
        const userId = this.localStorageService.getValue(
          LocalStorageConstants.USER_ID
        );
        const org = this.localStorageService.getParsedValue(
          LocalStorageConstants.OZ_USER
        )?.org;
        let timezone = this.localStorageService.getParsedValue(
          LocalStorageConstants.OZ_USER
        ).settings.timezone;
        let timeDiff: any = 19800;
        try {
          timeDiff = this.localStorageService.getParsedValue(
            LocalStorageConstants.OZ_USER
          ).settings.timediff.offset;
        } catch (e) {
          timeDiff = -(new Date().getTimezoneOffset() * 60);
        }
        const payloadName = this.isEdit ? 'update' : 'data';
        const payload = {
          [payloadName]: {
            aqi_index_id: this.selectedAqi ? this.selectedAqi : null,
            label: this.deviceDetailForm.get('clusterName')?.value,
            description: this.mapDetailForm.get('description')?.value,
            config: {
              timezone: timezone.name,
              radius: this.circle.radius,
              latitude: this.circle.lat,
              longitude: this.circle.lng,
              devices: this.mapDetailForm.get('selectedDevicesControl')?.value,
              deviceType: deviceType?.key,
              timeDiff: timeDiff,
              geojson: null,
            },
          },
        };
        if (
          !CommonUtil.isEmpty(this.geojson) &&
          this.geojsonLatLng?.length == 2
        ) {
          payload[payloadName].config['latitude'] = this.geojsonLatLng[1];
          payload[payloadName].config['longitude'] = this.geojsonLatLng[0];
          payload[payloadName].config['geojson'] = this.geojson;
        }
        if (this.isEdit) {
          this.clusterService
            .updateCluster(payload, this.data?.clusterId)
            ?.subscribe(
              (response: any) => {
                this.closeForm();
                this.loadrService.removeLoader();
                this.clusterService.getCluster().subscribe((response) => {
                  this.clusterData = this.clusterService.clusterData;
                  this.clusterService.isGetData.next(true);
                  this.clusterService.getCachedCluster();
                });
                this.notificationService.showNotification(
                  response,
                  'Close',
                  'bottom',
                  'right',
                  'success'
                );
              },
              (error) => {
                this.closeForm();
                this.loadrService.removeLoader();
                this.notificationService.showNotification(
                  error,
                  'Close',
                  'bottom',
                  'right',
                  'error'
                );
              }
            );
        } else {
          this.clusterService.addNewCluster(payload)?.subscribe(
            (response: any) => {
              this.closeForm();
              this.loadrService.removeLoader();
              this.clusterService.getCluster().subscribe((response) => {
                this.clusterData = this.clusterService.clusterData;
                this.clusterService.isGetData.next(true);
                this.clusterService.getCachedCluster();
              });
              // this.closeForm();
              this.notificationService.showNotification(
                response,
                'Close',
                'bottom',
                'right',
                'success'
              );
            },
            (error) => {
              this.closeForm();
              this.loadrService.removeLoader();
              this.notificationService.showNotification(
                error,
                'Close',
                'bottom',
                'right',
                'error'
              );
            }
          );
        }
      } else {
        this.mapDetailForm.markAllAsTouched();
        this.mapDetailForm.updateValueAndValidity();
      }
    }
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

  addAqi(value: any) {
    this.selectedAqi = value;
    this.deviceDetailForm.get('selectAqi')?.setValue(this.selectedAqi);
  }

  getAqiKeys() {
    const deviceTypeId = this.deviceDetailForm?.get('deviceType')?.value;
    if (deviceTypeId && this.allAqiList[deviceTypeId]) {
      this.AqiKeys = Object.keys(this.allAqiList[deviceTypeId]);
    } else {
      this.AqiKeys = [];
    }
  }

  // getAqiKeys(): string[] {
  //   const deviceTypeId = this.selectedDeviceTypeId?.deviceTypeId;
  //   if (deviceTypeId && this.allAqiList[deviceTypeId]) {
  //     return Object.keys(this.allAqiList[deviceTypeId]);
  //   } else {
  //     return [];
  //   }
  // }
}
