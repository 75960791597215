import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MTX_DRAWER_DATA } from '@ng-matero/extensions/drawer';
import { Subscription } from 'rxjs';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { AutomationData } from 'src/app/shared/models/automation/automation-data';
import { DeviceType } from 'src/app/shared/models/device-type/device-type';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { DeviceField } from 'src/app/shared/models/device/device-field';
import { AutomationService } from 'src/app/shared/services/automation.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { DeviceService } from 'src/app/shared/services/device.service';
import { FormsService } from 'src/app/shared/services/forms.service';
import { LoadrService } from 'src/app/shared/services/loadr.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { DeviceUtil } from 'src/app/shared/utils/device-utils';

@Component({
  selector: 'app-automation-form',
  templateUrl: './automation-form.component.html',
  styleUrls: ['./automation-form.component.scss'],
})
export class AutomationFormComponent {
  public selectedDevice: DeviceDetails[] = [];
  public placeHolder: string = '';
  public deviceTypes!: Array<DeviceType>;
  public devices: DeviceDetails[] = [];
  public automationForm!: FormGroup;
  public automationData!: AutomationData.Get;
  public isEditMode: boolean = false;
  public currentDevice!: string;
  public isAQBOT!: boolean;
  public fields: DeviceField[] = this.localStorageService.getParsedValue(
    LocalStorageConstants.OZ_FIELDS
  );
  public intervalLimit: number = 0;
  public isIntervalValid: boolean = false;
  public subscriptions: Subscription[] = [];
  lower_status = AppConstants.LOWER_STATUS;
  upper_status = AppConstants.UPPER_STATUS;

  constructor(
    private formsService: FormsService,
    private deviceService: DeviceService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private automationService: AutomationService,
    private loadrService: LoadrService,
    private localStorageService: LocalStorageService,
    @Inject(MTX_DRAWER_DATA)
    public data: AutomationData.ConfigAutomationAlert | undefined
  ) {}

  ngOnInit(): void {
    this.isEditMode = !!this.data;
    this.devices = this.deviceService.registeredDevices || [];
    this.deviceTypes = this.commonService.getUserDeviceTypes();

    this.automationService.currentDevice$.subscribe((res) => {
      this.currentDevice = res;
    });

    this.automationService.currentDeviceType$.subscribe((res) => {
      this.isAQBOT = res === 1012 ? true : false;
    });

    this.automationService.isAutomationData$.subscribe((res) => {
      if (res) {
        this.automationData = this.automationService.automationData;
        this.getVisibleKeys();
      }
    });

    this.buildForm(this.isEditMode);
    this.addFormSubscriptions();
  }

  closeForm() {
    this.formsService.closeForm();
  }

  getDeviceName(device: DeviceDetails): string {
    return device ? device.label.trim() : '';
  }

  buildForm(isEditMode: boolean = false) {
    this.automationForm = this.formBuilder.group({
      deviceName: [this.currentDevice, Validators.required],
      default: [true, Validators.required],
      channel: [0, Validators.required],
      key: ['', Validators.required],
      interval: ['', Validators.required],
      lowerStatus: [, Validators.required],
      lowerThreshold: ['', Validators.required],
      upperStatus: [, Validators.required],
      upperThreshold: ['', Validators.required],
    });
    this.automationForm.get('deviceName')?.disable();

    if (isEditMode) {
      this.automationForm.patchValue({
        deviceName: this.currentDevice,
        key: Object.keys(this.selectedDevice[0]?.payload?.d).find(
          (data: string) => data === this.data?.key
        ),
        default: this.data?.default ? true : false,
        channel: this.data?.channel,
        interval: this.data?.interval,
        lowerStatus: this.data?.lowerStatus ? true : false,
        lowerThreshold: this.data?.lowerThreshold,
        upperStatus: this.data?.upperStatus ? 1 : 0,
        upperThreshold: this.data?.upperThreshold,
      });
      this.automationForm.get('deviceName')?.disable();
    }
  }

  submitUpdateForm() {
    let payload!: AutomationData.Put;
    this.loadrService.showLoader();
    if (this.isEditMode) {
      this.generateConfigPayload(this.isEditMode);
    } else {
      this.generateConfigPayload();
    }
    payload = this.automationService.generateFinalPayload();
    this.automationService
      .addAutomationData(payload, this.automationData.deviceId)
      .subscribe({
        next: (res) => {
          if (res) {
            this.formsService.closeForm();
            this.loadrService.removeLoader();
            this.automationService.getAutomationData(this.currentDevice);
          }
        },
        error: (err) => {
          console.info('Error:', err);
          this.loadrService.removeLoader();
        },
      });
  }

  onChangeDeviceId() {}

  onChangeParameter() {}

  generateConfigPayload(isEditMode: boolean = false) {
    const formValue = this.automationForm.value;
    const {
      channel,
      default: isDefault,
      interval,
      key,
      lowerStatus,
      lowerThreshold,
      upperStatus,
      upperThreshold,
    } = formValue;

    const alertPayload: AutomationData.ConfigAutomationAlert = {
      channel: formValue.channel === 0 ? 0 : Number(channel),
      default: isDefault ? 1 : 0,
      interval,
      key,
      lowerStatus: lowerStatus ? 1 : 0,
      lowerThreshold,
      upperStatus: upperStatus ? 1 : 0,
      upperThreshold,
    };

    if (!isEditMode) {
      this.automationData.config.automation.alert.push(alertPayload);
    } else {
      const { index } = this.data!;
      if (index !== undefined) {
        this.automationData.config.automation.alert[index] = alertPayload;
      }
    }
  }

  getVisibleKeys() {
    this.selectedDevice = [];
    const visibleKeys: string[] = [];
    const device = this.devices.filter((device) => {
      return device.deviceId === this.currentDevice;
    });

    Object.entries(device[0]?.payload.d).map(([key]) => {
      if (
        this.fields.find(
          (obj: DeviceField) => obj.fkey === key && obj.isVisible
        )?.fkey
      ) {
        visibleKeys.push(key);
      }
    });

    Object.keys(device[0]?.payload.d).map((key) => {
      if (!visibleKeys.includes(key)) {
        delete device[0]?.payload.d[key];
      }
    });
    this.selectedDevice = device;
  }

  addFormSubscriptions() {
    const deviceName = this.automationForm
      .get('deviceName')
      ?.valueChanges.subscribe((res) => {
        this.selectedDevice = [];
        if (res) {
          if (typeof res === 'string') {
            this.selectedDevice.push(this.deviceService.mqttDocs[res]);
          }
        }
      })!;

    const key = this.automationForm
      .get('key')
      ?.valueChanges.subscribe((res) => {
        this.placeHolder = DeviceUtil.getFieldName(
          res,
          this.deviceService.fields
        );
      })!;

    // const channel = this.automationForm
    //   .get('channel')
    //   ?.valueChanges.subscribe((res) => {
    //     if (res === 3) {
    //       this.intervalLimit = 900;
    //     } else {
    //       this.intervalLimit = 0;
    //     }
    //   })!;

    const interval = this.automationForm
      .get('interval')
      ?.valueChanges.subscribe((res) => {
        if (res > this.intervalLimit) {
          this.isIntervalValid = true;
        } else {
          this.isIntervalValid = false;
        }
      })!;

    this.subscriptions.push(deviceName, key, interval);
    // this.subscriptions.push(key);
    // this.subscriptions.push(channel);
    // this.subscriptions.push(interval);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
