import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { ResetPassword } from 'src/app/shared/models/forgot-password/forgot-password';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { LoginService } from 'src/app/shared/services/login.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ResetPswdService } from 'src/app/shared/services/reset-pswd.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
  loadTable: Subject<boolean> = new BehaviorSubject(true);
  hide: boolean = false;
  resetPasswordForm!: FormGroup;
  passwordDifferent: boolean = false;
  public subscriptions: Subscription[] = [];
  private userResetPasswordToken: string | null = null;
  public disableButton: boolean = false;
  // public contains: { [key: string]: boolean } = {
  //   digit: false,
  //   lowercase: false,
  //   uppercase: false,
  //   specialchar: false,
  //   len: false,
  // };

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private resetPasswordService: ResetPswdService,
    private notificationService: NotificationService,
    private loginService: LoginService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit() {
    this.userResetPasswordToken =
      this.activatedRoute.snapshot.queryParamMap.get('token'); //get the token from url
    //if token found than verify token
    if (this.userResetPasswordToken) {
      this.router.navigateByUrl('/reset-password');
      this.verifyToken(this.userResetPasswordToken);
    } else {
      this.navigateToLoginPage();
    }
  }

  buildForm() {
    this.resetPasswordForm = this.formBuilder.group({
      password: [
        '',
        [Validators.required, Validators.pattern(AppConstants.PASSWORD_REGEX)],
      ],
      confirmPassword: [
        '',
        [Validators.required, Validators.pattern(AppConstants.PASSWORD_REGEX)],
      ],
    });
  }

  addSubscriptions() {
    const form = this.resetPasswordForm.valueChanges.subscribe((res) => {
      if (res) {
        this.passwordDifferent = res.confirmPassword !== res.password;
      }
    });

    // const newPswd = this.resetPasswordForm
    //   .get('password')
    //   ?.valueChanges.subscribe((res) => {
    //     // Special characters set
    //     const specialCharacters = '!@#$%^&*()_+-=[]{}|;:\'",.<>?/`~';

    //     Object.keys(this.contains).forEach(
    //       (res) => (this.contains[res] = false)
    //     );

    //     for (let char of res) {
    //       if (char >= '0' && char <= '9') {
    //         this.contains.digit = true;
    //       } else if (char >= 'a' && char <= 'z') {
    //         this.contains.lowercase = true;
    //       } else if (char >= 'A' && char <= 'Z') {
    //         this.contains.uppercase = true;
    //       } else if (specialCharacters.includes(char)) {
    //         this.contains.specialchar = true;
    //       }
    //     }

    //     if (res.length >= 8) {
    //       this.contains.len = true;
    //     }
    //   });

    this.subscriptions.push(form);
  }

  navigateToLoginPage() {
    this.router.navigate(['/login']);
  }

  verifyToken(userResetPswdToken: string) {
    this.loadTable.next(false);
    this.resetPasswordService.verifyUserToken(userResetPswdToken).subscribe({
      next: (res) => {
        if (res) {
          //if token verified than allow user to enter new password
          this.buildForm();
          this.addSubscriptions();
          this.loadTable.next(true);
        }
      },
      error: (res) => {
        //if not able to verify token send user to login page
        this.notificationService.showSnackBar('Invalid Link Entered', 'error');
        setTimeout(() => {
          this.navigateToLoginPage();
        }, 1000);
      },
    });
  }

  submitForm() {
    this.disableButton = true;
    let payload = this.generatePayload(this.resetPasswordForm.value);

    if (this.userResetPasswordToken) {
      this.resetPasswordService
        .resetPassword(payload, this.userResetPasswordToken)
        .subscribe({
          next: () => {
            //if password reset successful
            this.notificationService.showSnackBar(
              'Password Reset Successful',
              'success'
            );
            setTimeout(() => {
              this.navigateToLoginPage();
            }, 1000);
          },
          error: () => {
            //if password reset unsuccessful
            this.notificationService.showSnackBar(
              'Password Reset Failed',
              'error'
            );
            this.disableButton = false;
          },
        });
    }
  }

  generatePayload(value: any): ResetPassword {
    //encrypt the password before sending
    let pswd: string = this.loginService.getEncryptedPassword(
      value.password,
      this.localStorageService.getValue(LocalStorageConstants.ENCRYPTION_KEY)
    );
    let cnfrmPswd: string = this.loginService.getEncryptedPassword(
      value.confirmPassword,
      this.localStorageService.getValue(LocalStorageConstants.ENCRYPTION_KEY)
    );

    let payload: ResetPassword = {
      newPassword: pswd,
      confirmPassword: cnfrmPswd,
    };

    return payload;
  }
}
