import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AqiIndexColorArray } from 'src/app/shared/models/aqi-index/aqi-index-color-array';
import { DeviceDetails } from 'src/app/shared/models/device/device-details';
import { DeviceField } from 'src/app/shared/models/device/device-field';
import { FieldLimit } from 'src/app/shared/models/device/field-limit';

import * as Highcharts from 'highcharts';
import { LocalStorageConstants } from 'src/app/shared/constants/local-storage.constant';
import { Unit } from 'src/app/shared/models/unit';
import { CustomMomentService } from 'src/app/shared/services/custom-moment.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/offline-exporting')(Highcharts);

@Component({
  selector: 'app-heatmap-chart-widget',
  templateUrl: './heatmap-chart-widget.component.html',
  styleUrls: ['./heatmap-chart-widget.component.scss'],
})
export class HeatmapChartComponent implements OnInit {
  @Input() deviceInfo: DeviceDetails | undefined;

  private _deviceData: DeviceDetails[] = [];
  public get deviceData(): DeviceDetails[] {
    return this._deviceData;
  }
  @Input() public set deviceData(v: DeviceDetails[]) {
    if (v) {
      this._deviceData = v;
      this.setupDataPoints();
    }
  }

  @Input() fields: DeviceField[] = [];

  private _limits: FieldLimit[] = [];
  public limitsObj: { [key: string]: FieldLimit } = {};
  public get limits(): FieldLimit[] {
    return this._limits;
  }
  @Input() public set limits(v: FieldLimit[]) {
    this._limits = v;
    this.limitsObj = Object.fromEntries([
      ...v.map((limit) => [limit.fkey, limit]),
    ]);
  }

  @Input() aqiIndexColor: AqiIndexColorArray | undefined;
  @Input() autoRange: boolean = true;
  @Input() chartType: string = 'column';
  @Input() show = true;

  @Output() parameterSelectionChange: EventEmitter<string>;

  highcharts: typeof Highcharts = Highcharts;

  defaultColor: string = '#00c4b4';
  deviceFields: DeviceField[] = [];

  private _selectedTimestamp: number | undefined;
  public get selectedTimestamp(): number | undefined {
    return this._selectedTimestamp;
  }
  @Input() public set selectedTimestamp(v: number | undefined) {
    this._selectedTimestamp = v;
    if (v) {
      this.selectPointByXValue(v * 1000);
    }
  }

  private _selectedField: Unit | undefined;
  public get selectedField(): Unit | undefined {
    return this._selectedField;
  }
  @Input() public set selectedField(v: Unit | undefined) {
    if (v) {
      this._selectedField = v;
      this.parameterSelectionChange.emit(v.label);
    }
  }

  chartOptions: Highcharts.Options = {};
  chartObj!: Highcharts.Chart;

  userTimeFormat: number;

  constructor(
    private customMomentService: CustomMomentService,
    private localStorageService: LocalStorageService
  ) {
    this.parameterSelectionChange = new EventEmitter<string>();
    this.userTimeFormat =
      this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
        ?.settings?.time_format ?? 24;
  }

  ngOnInit(): void {
    this.userTimeFormat =
      this.localStorageService.getParsedValue(LocalStorageConstants.OZ_USER)
        ?.settings?.time_format ?? 24;
  }

  setupDataPoints() {
    this.show = false;

    let dataPoints = [];

    let rangeIndex = this.limits.findIndex(
      (x: any) => this.selectedField?.key === x.fkey
    );

    const that = this;

    let xAxisOptions: Highcharts.XAxisOptions = {
      type: 'datetime',
      labels: {
        formatter: function () {
          return that.customMomentService.formatTime({
            epochMS: parseInt('' + this.value),
            format: that.userTimeFormat === 12 ? 'hh:mm A' : 'HH:mm',
          });
        },
      },
    };
    let yAxisOptions: Highcharts.AxisOptions = {
      title: { text: null },
    };

    if (!this.autoRange) {
      yAxisOptions['tickPositions'] =
        this.limits[rangeIndex].range?.length > 0
          ? this.limits[rangeIndex].range!
          : [
              this.limits[rangeIndex].lowestLimit,
              this.limits[rangeIndex].highestLimit,
            ]!;
    }

    for (let data of this.deviceData) {
      let value: number = parseFloat(
        data?.payload?.d[this.selectedField?.key ?? 'aqi']
      );
      let dataPoint = {
        x: +this.customMomentService.moment.unix(data?.payload?.d?.t),
        y: value,
        color: '',
      };

      if (this.limits.length && this.limits[rangeIndex]) {
        let i: number;
        for (i = 0; i < this.limits[rangeIndex].range?.length; i++) {
          if (Math.min(value, this.limits[rangeIndex].range[i]) === value) {
            break;
          }
        }

        if ((this.aqiIndexColor?.limits?.length ?? 0) > --i) {
          dataPoint.color = this.aqiIndexColor?.color[i] ?? this.defaultColor;
        } else {
          dataPoint.color =
            this.aqiIndexColor?.outOfRangeColor ?? this.defaultColor;
        }
      } else {
        dataPoint.color = this.defaultColor;
      }

      dataPoints.push(dataPoint);
    }

    this.chartOptions.title = null!;
    this.chartOptions.subtitle = null!;
    this.chartOptions.credits = { enabled: false };
    this.chartOptions.legend = { enabled: false };

    this.chartOptions.chart = {
      type: this.chartType,
      events: {
        load: function () {
          that.chartObj = this;

          that.selectPointByXValue((that.selectedTimestamp ?? 0) * 1000);
        },
      },
    };

    this.chartOptions.plotOptions = {
      series: {
        allowPointSelect: true,
        states: {
          select: {
            color: 'red',
          },
        },
      },
    };

    this.chartOptions.exporting = {
      filename: `${
        this.deviceInfo?.label ? `${this.deviceInfo?.label}_` : ''
      }hourly_data`,
      chartOptions: {
        title: {
          text: `${
            this.deviceInfo?.label ? `${this.deviceInfo?.label} device's ` : ''
          }Last 24 Hours Data`,
        },
        legend: {
          enabled: true,
        } as Highcharts.LegendOptions,
        yAxis: {
          title: {
            text: `${this.selectedField?.flabel}${
              this.selectedField?.label?.length
                ? ` (${this.selectedField.label})`
                : ''
            }`,
          },
        },
        xAxis: {
          title: {
            text: 'Time',
          },
        },
      },
    };

    this.chartOptions.tooltip = {
      shared: true,
      formatter: function () {
        return (
          that.customMomentService.formatDatetime({
            epochMS: parseInt('' + this.x),
            format:
              that.userTimeFormat === 12
                ? 'dddd, MMM DD, hh:mm A'
                : 'dddd, MMM DD, HH:mm',
          }) +
          '</br>' +
          `<span style="color: ${this.point.color}">\u25CF</span>` +
          '<b>' +
          this.series.name +
          '</b>' +
          ' : ' +
          this.y
        );
      },
    };

    this.chartOptions.xAxis = xAxisOptions;
    this.chartOptions.yAxis = yAxisOptions;

    let zones = [
      {
        color: this.defaultColor,
        value: this.limits?.[rangeIndex]?.lowestLimit ?? 0,
      },
    ];

    let fieldLimits = this.limits?.[rangeIndex];
    if (fieldLimits?.range?.length) {
      for (let i = 0; i < fieldLimits.range.length; i++) {
        let color = this.aqiIndexColor?.color?.[i] ?? this.defaultColor;
        let value = fieldLimits.range[i + 1];
        if (value && color?.length) {
          zones.push({
            value,
            color,
          });
        }
      }
      zones.push({
        color: this.aqiIndexColor?.outOfRangeColor ?? this.defaultColor,
        value: Number.POSITIVE_INFINITY,
      });
    }

    this.chartOptions.series = [];
    let fieldName = this.selectedField?.flabel ?? 'AQI';
    let fieldUnit = this.selectedField?.label ?? '';
    fieldUnit = fieldUnit.replaceAll(' ', '');
    this.chartOptions.series[0] = {
      name: fieldName + (fieldUnit ? ' (' + fieldUnit + ')' : ''),
      data: dataPoints,
      zones,
    } as Highcharts.SeriesOptionsType;

    setTimeout(() => {
      this.show = true;
    });
  }

  // Function to select a point based on x value
  selectPointByXValue(xValue: any) {
    const chart = this.chartObj; // Get the chart instance
    const series = chart?.series?.[0]; // Get the first series

    // Loop through the data points
    series?.data?.forEach?.((point) => {
      if (point.category === xValue) {
        // Compare with the x value
        point.setState('hover', true); // Select the point
      } else {
        point.setState('normal', true);
      }
    });
  }
}
